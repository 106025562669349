var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "approve-alert",
      attrs: {
        title: _vm.isEdit ? "编辑审批授权" : "新增审批授权",
        visible: _vm.dialogVisible,
        width: "900px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "lock-scroll": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "myForm",
          attrs: {
            model: _vm.myForm,
            rules: _vm.formRules,
            "label-width": "80px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "授权业务", prop: "businessType" } },
            [
              _c("en-select", {
                attrs: { placeholder: "请选择", data: _vm.options },
                model: {
                  value: _vm.myForm.businessType,
                  callback: function ($$v) {
                    _vm.$set(_vm.myForm, "businessType", $$v)
                  },
                  expression: "myForm.businessType",
                },
              }),
            ],
            1
          ),
          _vm.myForm.businessType === 2
            ? _c(
                "el-form-item",
                { attrs: { label: "授权内容", prop: "formId" } },
                [
                  _c("en-select", {
                    attrs: {
                      mode: "tree",
                      placeholder: "请选择",
                      props: { value: "id", label: "name", children: "nodes" },
                      data: _vm.formData,
                      "check-limit": 1,
                      "expand-on-click-node": false,
                      "check-on-click-node": true,
                      "check-mode": "siblings",
                      multiple: "",
                    },
                    model: {
                      value: _vm.myForm.formId,
                      callback: function ($$v) {
                        _vm.$set(_vm.myForm, "formId", $$v)
                      },
                      expression: "myForm.formId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "代理人员", prop: "proxyUser" } },
            [
              _c("en-select", {
                attrs: {
                  mode: "tree",
                  "value-key": "id",
                  "data-mode": "id-name",
                  placeholder: "请选择",
                  props: { value: "id", label: "name", isLeaf: _vm.isLeaf },
                  data: _vm.treeData,
                  "check-limit": 1,
                  "expand-on-click-node": false,
                  "check-on-click-node": true,
                  load: _vm.loadNode,
                  "check-mode": "siblings",
                  lazy: "",
                  multiple: "",
                },
                on: { change: _vm.authUserChange },
                model: {
                  value: _vm.myForm.proxyUser,
                  callback: function ($$v) {
                    _vm.$set(_vm.myForm, "proxyUser", $$v)
                  },
                  expression: "myForm.proxyUser",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "生效时间", prop: "date" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "range-separator": "—",
                  "start-placeholder": "",
                  "end-placeholder": "",
                  size: "small",
                },
                model: {
                  value: _vm.myForm.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.myForm, "date", $$v)
                  },
                  expression: "myForm.date",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "en-button",
            {
              attrs: { type: "primary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.submitForm.apply(null, arguments)
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }