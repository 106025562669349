<template>
  <div class="tddd is-edit reset-input" :class="classStr" :style="stylefun" @click="handleFocus">
    <div class="a" :style="styleAfun"></div>
    <span>
      {{ valuecurrency }}
    </span>
    <span v-show="!flg">
      {{ value }}
    </span>
    <el-input ref="dom" v-show="flg" v-model="value" @change="change" @focus="handleFocus" @blur="handleBlur"> </el-input>
  </div>
</template>

<script>
// 此类报表应该是通用的
import { thousandFormat } from "en-js";

export default {
  name: "ReportContent",
  props: {
    data: Object, // 数据
    isDeclare: Number // 报表状态
  },
  data() {
    return {
      text: undefined,
      num0Format: { 1: "", 2: "0.00", 3: "--" },
      currency: {
        0: "",
        1: "¥",
        2: "$",
        3: "€",
        4: "£"
      },
      flg: false, // 是否是编辑
      isEdit: true, // 是否可以修改
      valuecurrency: "", // 前缀
      value: "", // 显示值
      classStr: ""
    };
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.text = this.showText;
      }
    }
  },
  mounted() {
    // 判断是否可以修改
    this.isEdit = this.isEditfun();
    this.showText();
    this.classStr = this.classfun();
    let str = "";
    if (this.classStr.indexOf("black") > -1) {
      str = "#f7f7f7";
    } else if (this.classStr.indexOf("isEdited") > -1) {
      str = "#eaf9f4";
    } else if (this.classStr.indexOf("isLinkEdit") > -1) {
      str = "#e8f5fb";
    } else if (this.data.showFormat.fillColor === "#ffffff") {
      str = "#ffffff";
    } else if (!(this.value === "") && this.data.showFormat) {
      str = this.data.showFormat.fillColor;
    }
    this.$emit("getStyle", str);
  },
  computed: {
    // 行内
    stylefun() {
      // { color: data.showFormat.color, background: data.showFormat.fillColor }
      const str = [];
      str.push(`color:${this.data.showFormat.color};`);
      if (!(this.value === "") && this.data.showFormat) {
        str.push(`background:${this.data.showFormat.fillColor};`);
      }
      return str.join(" ");
    },
    // class a 的 行内
    styleAfun() {
      const str = [];
      if (this.data.showFormat.fillColor === "#ffffff") {
        //
      } else if (!(this.value === "") && this.data.showFormat) {
        str.push(`border: 1px solid ${this.data.showFormat.fillColor};`);
      }
      return str.join(" ");
    }
  },
  methods: {
    // 是否可以编辑
    isEditfun() {
      // 预览不可以;
      if (this.isDeclare === 1) {
        // 未申报
        if (this.data.isCanEdit === 0) {
          // 可以修改
          return true;
        }
        return false;
      }
      return false;
    },
    // 显示文本
    showText() {
      // TODO: 文本格式化 没去写, 所有报表都是通用的方法(至少iOS是如此, 无非是字段名字有些差别,转换下就行了),  百分比,小数点,金额符号,千分符,时间格式等等
      // TODO: 此处还有个数字显示逻辑, 以前抄的张家衡的, 可以拷贝过来就行了 (//2020.06.03 bug 32651 刘瑛确认与PC逻辑一致)
      let value = "";
      if (typeof this.data.value === "object") {
        value = this.data.value[0].name;
      } else {
        value = this.data.value;
      }
      const showFormat = typeof this.data.showFormat === "string" ? JSON.parse(this.data.showFormat) : this.data.showFormat;
      // this.data.type == 3 || this.data.type == 4
      // 只要是数字 并且不是 1 类型
      let flg = false;
      let flg1 = false; // flg1 为true 不加2位小数
      if (String(this.data.approach) === "2") {
        // 汇总
        flg = true;
      } else if (String(this.data.valueType) === "4") {
        // 不是汇总
        // 公式计算的结果 如果是 数字
        if (!isNaN(Number(this.data.value))) {
          flg = true;
        }
      } else if (String(this.data.valueType) === "5") {
        // 直接取值
        // 不管
      } else {
        flg1 = true;
        // 4.6 文本类型 按照最终值 进行处理
        if (String(this.data.controlType) === "1") {
          // 文本类型
          if (!isNaN(Number(this.data.value))) {
            flg = true;
          }
        } else if (String(this.data.controlType) !== "93" && String(this.data.controlType) !== "0") {
          // 也不是 计算公式的 结果
          flg = true;
        }
      }

      if (flg) {
        let reserverDecimal = Number((showFormat && showFormat.reserverDecimal) || 2);
        if (flg1) {
          reserverDecimal = 0;
        }
        if (String(showFormat.numberFormat) === "1") {
          // 数值
          if (String(showFormat.permillage) === "0") {
            // 千分符显示
            value = thousandFormat(Number(value).toFixed(reserverDecimal));
          } else {
            value = Number(value).toFixed(reserverDecimal);
          }
        } else {
          // 百分比
          value = `${(Number(value) * 100).toFixed(reserverDecimal)}%`;
        }
      }

      const num0Format = this.num0Format[showFormat.num0Format || "1"];
      const currency = this.currency[showFormat.currency || "0"];

      let valuecurrency = ""; // 货币
      if (Number(value) === 0) {
        if (String(showFormat.num0Format) === "2") {
          // 为 0.00 的时候 应该 加货币符号
          // value = currency + num0Format;
          value = num0Format;
          valuecurrency = currency;
        } else {
          value = num0Format;
        }
      } else if (String(showFormat.numberFormat) === "1") {
        // value = currency+value;
        valuecurrency = currency;
      }
      // this.text = value;
      this.valuecurrency = valuecurrency;
      this.value = value;
      this.data.showText = valuecurrency + value;
    },
    // 生成class 包含
    classfun() {
      const str = [];
      // center: dataFormat === 2,
      // right: dataFormat === 3,
      // isLinkEdit: data.isLinkEdit == '1',
      // isEdited: data.isEdited == '1'
      if (String(this.data.showFormat.dataFormat) === "2") {
        str.push("center");
      } else if (String(this.data.showFormat.dataFormat) === "3") {
        str.push("right");
      }
      // 可以 修改
      if (this.isEdit) {
        if (String(this.data.isEdited) === "1") {
          // 自己被修改了 0否 1是
          str.push("isEdited");
        } else if (String(this.data.isLinkEdit) === "1") {
          // 联动修改了 0否 1是
          str.push("isLinkEdit");
        }
      } else if (String(this.data.isEdited) === "1") {
        // 自己被修改了 0否 1是
        str.push("isEdited");
      } else if (String(this.data.isLinkEdit) === "1") {
        // 联动修改了 0否 1是
        str.push("isLinkEdit");
      } else {
        // 不可以修改
        str.push("black");
      }
      return str.join(" ");
    },

    // 输入框焦点
    handleFocus() {
      if (this.isEdit) {
        this.flg = true;
        this.$nextTick(() => {
          this.$refs.dom.focus();
        });
      }
    },
    // 焦点移出
    handleBlur() {
      this.flg = false;
    },
    // 文本发送变动
    change() {
      this.$emit("change", { data: this.data, text: this.value });
    }
  }
};
</script>

<style scoped lang="scss">
.tddd {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  // background: #ffffff;
  padding: 5px;
  .a {
    position: absolute;
    z-index: 0;
    inset:-1px;
  }
}
.center {
  text-align: center;
  justify-content: center;
  /deep/ .el-input__inner {
    text-align: center;
  }
}
.right {
  text-align: right;
  justify-content: flex-end;
  /deep/ .el-input__inner {
    text-align: right;
  }
}
.reset-input {
  /deep/ .el-input__inner {
    height: 40px !important;
  }
}
// 不能修改
.black {
  background: #f7f7f7 !important;
  cursor: not-allowed;
  color: rgb(96, 96, 96) !important;
  .a {
    border: 1px solid rgb(215, 215, 215) !important;
  }
}
// 联动修改
.tddd.isLinkEdit {
  background: #e8f5fb !important;
  color: rgb(96, 96, 96) !important;
  .a {
    border: 1px solid #86c9e6 !important;
  }
}
// 自己修改
.tddd.isEdited {
  background: #eaf9f4 !important;
  color: rgb(96, 96, 96) !important;
  .a {
    border: 1px solid #8ddbc3 !important;
  }
}
</style>
