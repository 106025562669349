var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "oranization-main" }, [
    _c(
      "div",
      { staticClass: "equal-side" },
      [
        _c("en-title-card", {
          attrs: { "need-back": _vm.needBack, name: _vm.defaultName },
          on: { back: _vm.backUrl },
        }),
        _c("div", { staticClass: "equal-sidecentbar" }, [
          _c("div", { staticClass: "row-title" }, [
            _vm._v(" " + _vm._s(_vm.defaultName) + " "),
          ]),
          _c(
            "div",
            { staticClass: "row-main" },
            [
              _c("en-tree", {
                attrs: {
                  "current-node-key": "",
                  data: _vm.treeData,
                  "show-checkbox": false,
                  "default-expand-all": true,
                  "node-key": "label",
                  "highlight-current": "",
                  props: _vm.defaultProps,
                },
                on: { "node-click": _vm.nodeClick },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "equal-cent" },
      [
        _c(
          "en-title-card",
          { attrs: { name: _vm.checkData.name || _vm.defaultName } },
          [
            _vm.checkData.count
              ? _c(
                  "div",
                  {
                    staticClass: "count-num",
                    attrs: { slot: "left" },
                    slot: "left",
                  },
                  [_vm._v("(" + _vm._s(_vm.checkData.count) + "人)")]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { staticClass: "table-main" },
          [
            _c(
              "en-table",
              {
                attrs: {
                  data: _vm.tableData,
                  "height-config": { bottomHeight: 15 },
                  "page-config": {
                    pageModel: _vm.pageModel,
                    changeMethod: _vm.handlePageChanged,
                  },
                },
              },
              [
                _c("en-table-column", {
                  attrs: { type: "index", width: "60" },
                }),
                _c("en-table-column", {
                  attrs: { title: "头像", field: "userLogo", width: "110px" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.userLogo === ""
                            ? _c("i", { staticClass: "user-logotxt" }, [
                                _vm._v(_vm._s(row.name)),
                              ])
                            : _c("img", {
                                staticClass: "user-logo",
                                attrs: { src: row.userLogo },
                              }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("en-table-column", {
                  attrs: { title: "姓名", field: "name" },
                }),
                _c("en-table-column", {
                  attrs: { title: "工号", field: "no" },
                }),
                _c("en-table-column", {
                  attrs: { title: "岗位", field: "postName" },
                }),
                _c("en-table-column", {
                  attrs: { title: "是否兼岗", field: "" },
                }),
                _c("en-table-column", {
                  attrs: { title: "手机号码", field: "mobileNo" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }