<!--
 * @Author: pengyu
 * @Date: 2019-11-19 14:10:20
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-01 14:20:05
 * @Description: 我的授权-列表
 -->
<template>
  <div class="container">
    <en-table :data="list" border :hover-row-config="{ dataMethod: buttonData, changeMethod: buttonClick }" height="100%">
      <en-table-column type="index" width="80"> </en-table-column>
      <en-table-column prop="authObj" label="授权对象" :formatter="formatAuthObj"> </en-table-column>
      <en-table-column prop="authUserName" width="450" label="授权人员">
        <template slot-scope="scope">
          <div class="user-mod" v-if="scope.row.authUserName">
            <span class="user-item">
              {{ scope.row.authUserName }}
            </span>
          </div>
        </template>
      </en-table-column>
      <en-table-column prop="authPassword" label="授权码">
        <template slot-scope="scope">
          <span v-if="scope.row.authObj === 1">{{ scope.row.authPassword }}</span>
        </template>
      </en-table-column>
      <en-table-column prop="authRange" label="授权范围">
        <template slot-scope="scope">
          <span v-if="scope.row.authRange === '1'">用户权限</span>
          <span v-if="scope.row.authRange === '2'">管理员权限</span>
        </template>
      </en-table-column>
      <en-table-column prop="startTime" label="生效日期"> </en-table-column>
      <en-table-column prop="endTime" label="失效日期"> </en-table-column>
      <en-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <div class="status-mod" :style="formatStyle(scope.row.status)">
            {{ formatStatus(scope.row.status) }}
          </div>
        </template>
      </en-table-column>
      <!-- 悬浮操作列 -->
      <!-- <template v-slot:hoverRowRight="scope">
        <en-expand-buttons :data="buttonData(scope.row)" @button-click="buttonClick(scope.row, scope.rowIndex, arguments[1], arguments[0])" />
      </template> -->
    </en-table>
  </div>
</template>

<script>
import { authorizeService } from "@/api";

export default {
  name: "MyAuth",
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableData: []
    };
  },
  mounted() {
    // this.tableData = this.list;
  },
  methods: {
    /**
     * @description
     * @param 表格操作列按钮
     */
    buttonData({ row }) {
      let btnList = [
        {
          icon: "zhongzhi-liebiao",
          id: "btn-zhongzhi",
          name: "终止",
          iconColor: "#f66a69"
        },
        {
          icon: "fuzhi-kuaijiecaozuo",
          id: "btn-fuzhi",
          name: "复制",
          iconColor: "#a9b5c6"
        },
        {
          icon: "rizhi-liebiao",
          id: "btn-rizhi",
          name: "日志",
          iconColor: "#56ca7f"
        }
      ];
      if (parseInt(row.status, 10) === 4) {
        btnList = [
          {
            icon: "rizhi-liebiao",
            id: "btn-rizhi",
            name: "日志",
            iconColor: "#56ca7f"
          }
        ];
      }

      return btnList;
    },
    /**
     * @description
     * @param 表格操作列按钮事件
     */
    buttonClick({ row, index, btnData }) {
      switch (btnData.id) {
        case "btn-zhongzhi":
          // 终止
          this.stopAuth(row, index);
          break;
        case "btn-fuzhi":
          // 复制
          this.copy(row, index);
          break;
        case "btn-rizhi":
          // 日志
          this.log(row, index);
          break;
        default:
          break;
      }
    },
    /**
     * @description: 状态字段转化
     */
    formatStatus(status) {
      switch (status) {
        case 2:
          return "生效中";
        case 3:
          return "未生效";
        case 4:
          return "已失效";
        default:
          return "";
      }
    },
    /**
     * @description: 状态块块样式
     */
    formatStyle(status) {
      switch (status) {
        case 2:
          return { background: "#FFF7EA", color: "#FFAD2B" };
        case 3:
          return { background: "#EDF3F9", color: "#3e90fe" };
        case 4:
          return { background: "#EFF0F2", color: "#636C7B" };
        default:
          return "";
      }
    },
    /**
     * @description: 授权对象转化
     */
    formatAuthObj(row) {
      if (row.cellValue === 2) {
        return "企业员工";
      }
      return "外部人员";
    },
    /**
     * @description: 提交确认
     */
    async stopAuthPassword(pa) {
      await authorizeService.stopAuthPassword(pa);
    },
    /**
     * @description: 终止授权
     * @param {type}
     */
    stopAuth(row) {
      this.$confirm("确定终止登录授权?", "", {
        confirmButtonText: "确定",
        showCancelButton: false,
        type: "error"
      }).then(() => {
        this.stopAuthPassword({ id: row.id });
        this.$parent.queryMyAuthList();
        // this.$message({
        //   type: "success",
        //   message: "终止成功!"
        // });
      });
    },
    /**
     * @description: 日志
     */
    log(row) {
      this.$parent.logData = row.authPassword;
      this.$parent.popLogAlert();
    },
    /**
     * @description: 复制
     */
    copy(data) {
      const aux = document.createElement("textarea");
      const user = "";
      const { authPassword } = data;
      const { startTime } = data;
      const { endTime } = data;

      aux.value = `授权登录 河洛\n授权账号：${user}\n授权码（密码）:${authPassword}\n生效时间：${startTime} 至 ${endTime}\n请对此保密`;
      document.body.appendChild(aux);
      aux.select();
      window.getSelection().toString();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$message("授权信息已成功复制");
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  .user-mod {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    .user-item {
      line-height: 24px;
      border: 1px solid #dbe6ec;
      padding: 3px 10px;
      border-radius: 3px;
      margin: 5px 5px 0 0;
    }
  }
  .status-mod {
    width: 62px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    border-radius: 4px;
  }
  .en-icon {
    margin: 0 5px;
    cursor: pointer;
    &:last-child {
      margin-right: 20px;
    }
  }
}
</style>
