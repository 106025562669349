var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "login-alert",
          attrs: {
            title: "新增登录授权",
            visible: _vm.dialogVisible,
            width: "900px",
            "append-to-body": true,
            "close-on-click-modal": false,
            "lock-scroll": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "myForm",
              attrs: {
                model: _vm.myForm,
                rules: _vm.formRules,
                "label-width": "80px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "授权对象", prop: "authObj" } },
                [
                  _c("en-select", {
                    attrs: { placeholder: "请选择", data: _vm.options },
                    on: { change: _vm.objChange },
                    model: {
                      value: _vm.myForm.authObj,
                      callback: function ($$v) {
                        _vm.$set(_vm.myForm, "authObj", $$v)
                      },
                      expression: "myForm.authObj",
                    },
                  }),
                ],
                1
              ),
              _vm.myForm.authObj === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "授权人员", prop: "authUser" } },
                    [
                      _c("en-select", {
                        attrs: {
                          mode: "tree",
                          "data-mode": "data",
                          placeholder: "请选择",
                          props: {
                            value: "id",
                            label: "name",
                            isLeaf: _vm.isLeaf,
                          },
                          data: _vm.treeData,
                          "check-limit": 1,
                          "expand-on-click-node": false,
                          "check-on-click-node": true,
                          load: _vm.loadNode,
                          lazy: "",
                          multiple: "",
                        },
                        on: { change: _vm.authUserChange },
                        model: {
                          value: _vm.myForm.authPerson,
                          callback: function ($$v) {
                            _vm.$set(_vm.myForm, "authPerson", $$v)
                          },
                          expression: "myForm.authPerson",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "授权范围", prop: "authRange" } },
                [
                  _c("en-select", {
                    attrs: { placeholder: "请选择", data: _vm.optionsRange },
                    model: {
                      value: _vm.myForm.authRange,
                      callback: function ($$v) {
                        _vm.$set(_vm.myForm, "authRange", $$v)
                      },
                      expression: "myForm.authRange",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "生效时间", prop: "time" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "range-separator": "—",
                      "start-placeholder": "",
                      "end-placeholder": "",
                      align: "right",
                      size: "small",
                    },
                    model: {
                      value: _vm.myForm.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.myForm, "time", $$v)
                      },
                      expression: "myForm.time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "en-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }