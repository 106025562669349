<!--
 * @Author: zjh
 * @Date: 2020-03-17 12:22:15
 * @LastEditTime: 2020-11-16 15:05:52
 * @LastEditors: Please set LastEditors
 * @Description: 税务报表使用的时间控件
 * @FilePath: \user-intelligent-accounting\src\views\accountBook\dateInterval\index.vue
 -->
<template>
  <div class="date-interval-container" :class="{active:isActive}">
    <!-- head+body -->
    <div class="date-bar">
      <!-- head -->
      <div
        class="date-choose"
      >
        <div class="date-sel">{{y}}</div>
      </div>
      <!-- body -->
      <div class="line">
        <div class="top-arrow" @click="topArrow">
          <en-icon name="zhankai" size="small" color="#A9B5C6"></en-icon>
        </div>
        <div class="line-list" ref="DOM" v-if="timeformat==3">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="date-item"
          >
            <div class="date-year">{{ item.name }}</div>
            <div
              v-for="(month, cindex) in item.nodes"
              :key="cindex"
              class="date-month"
              :class="{'has-declare': (declare.indexOf(item.name+'-'+month)) > -1, 'cur' : (month == m && item.name == y)}"
              @click="itemClick(month,item.name)"
              :title="month"
            >
              {{ month }}
            </div>
          </div>
        </div>
        <!-- 季度 -->
        <div class="line-list" ref="DOM" v-if="timeformat==4">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="date-item"
          >
            <div class="date-year">{{ item.name }}</div>
            <div
              v-for="(month, cindex) in item.nodes"
              :key="cindex"
              class="date-month"
              :class="{'has-declare': (declare.indexOf(item.name+''+month.id)) > -1, 'cur' : (month.id == m && item.name == y)}"
              @click="itemClick(month.id,item.name)"
              :title="month.title"
            >
              {{ month.name }}
            </div>
          </div>
        </div>
        <!-- 年份 -->
        <div class="line-list" ref="DOM" v-if="timeformat==5">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="date-item"
          >
            <div
              class="date-month"
              :class="{'has-declare': (declare.indexOf(item.name+'')) > -1, 'cur' : (item.name == y)}"
              @click="itemClick('',item.name)"
              :title="item.name"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="bottom-arrow" @click="bottomArrow">
          <en-icon name="zhankai" size="small" color="#A9B5C6"></en-icon>
        </div>
      </div>
    </div>
    <!-- 切换按钮 -->
    <div class="open-icon" @click="collapseBtn">
      <en-icon name="shouqi-huijiqijian" size="12px" color="#fff"></en-icon>
    </div>
  </div>
</template>

<script>

export default {
  name: "DateInterval",
  props: {
    // 默认选中的
    data: {
      type: String,
      default: ""
      // default: "2020-04"
      // default: "20204"
      // default: "2020"
    },
    // 3月 4季 5年 eg 2020-04 20201 2020
    timeformat: {
      type: Number,
      default: 3
      // default: "3"
      // default: "4"
      // default: "5"
    },
    // 已经申报过的日期，eg["2020-02","2023-02","2017-02"]
    declare: {
      type: Array,
      default: () => []
      // default: () => ["2020-02", "2023-02", "2017-02"]
      // default: () => ["20202", "20232", "20172"]
      // default: () => ["2020", "2023", "2017"]
    }
  },
  data() {
    return {
      dateRangeList: this.data || [],
      styles: {},
      rangeStep: 0,
      startDate: "", // 开始日期
      endDate: "", // 结束日期
      isActive: false,
      arrM: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"], // 月份
      arrMback: ["12", "11", "10", "09", "08", "07", "06", "05", "04", "03", "02", "01"], // 月份
      // 季度
      arrquarter: [
        { id: "1", title: "第一季度", name: "一" },
        { id: "2", title: "第二季度", name: "二" },
        { id: "3", title: "第三季度", name: "三" },
        { id: "4", title: "第四季度", name: "四" }
      ],
      arrquarterback: [
        { id: "4", title: "第四季度", name: "四" },
        { id: "3", title: "第三季度", name: "三" },
        { id: "2", title: "第二季度", name: "二" },
        { id: "1", title: "第一季度", name: "一" }
      ],
      list: [],
      y: "",
      m: ""
    };
  },
  mounted() {
    this.makeData();
  },
  methods: {
    // 生成默认显示的 list 点击上下按钮 在list中添加数据
    makeData() {
      if (this.timeformat === 3) {
        // 按月
        // 取出默认条件的 年份和月份
        this.y = Number(this.data.slice(0, 4));
        this.m = Number(this.data.slice(5, 7));

        this.list = [
          {
            name: this.y,
            nodes: this.arrM
          },
          {
            name: this.y + 1,
            nodes: this.arrM
          }
        ];
      } else if (this.timeformat === 4) {
        // 按季
        this.y = Number(this.data.slice(0, 4));
        this.m = Number(this.data.slice(4, 5));
        this.list = [
          {
            name: this.y - 1,
            nodes: this.arrquarter
          },
          {
            name: this.y,
            nodes: this.arrquarter
          },
          {
            name: this.y + 1,
            nodes: this.arrquarter
          },
          {
            name: this.y + 2,
            nodes: this.arrquarter
          }
        ];
      } else {
        // 按年
        this.y = Number(this.data);
        this.list = [
          { name: this.y - 4 },
          { name: this.y - 3 },
          { name: this.y - 2 },
          { name: this.y - 1 },
          { name: this.y },
          { name: this.y + 1 },
          { name: this.y + 2 },
          { name: this.y + 4 },
          { name: this.y + 5 },
          { name: this.y + 6 },
          { name: this.y + 8 },
          { name: this.y + 9 },
          { name: this.y + 10 },
          { name: this.y + 11 },
          { name: this.y + 12 }
        ];
      }
    },
    /**
     * @description: 展开隐藏日期区间
     */
    collapseBtn() {
      this.isActive = !this.isActive;
      this.$emit("collapseBtn");
    },
    /**
     * @description: 控件向上滑动
     */
    topArrow() {
      const h = this.$refs.DOM.scrollTop;
      if (h < 10) {
        if (this.timeformat === 3) {
          // 最小值
          const miny = Number(this.list[0].name) - 1;
          const data = {
            name: miny,
            nodes: this.arrM
          };
          this.list.unshift(data);
        } else if (this.timeformat === 4) {
          // 最小值
          const miny = Number(this.list[0].name) - 1;
          this.list.unshift({ name: miny, nodes: this.arrquarter });
          this.list.unshift({ name: miny - 1, nodes: this.arrquarter });
        } else {
          // 最小值
          const miny = Number(this.list[0].name) - 1;
          for (let i = 0; i < 5; i++) {
            this.list.unshift({ name: miny - i });
          }
        }
      } else {
        this.$refs.DOM.scrollTo({
          top: h - 200,
          behavior: "smooth"
        });
      }
    },
    /**
     * @description: 控件向下滑动
     */
    bottomArrow() {
      if (this.timeformat === 3) {
        // 最大值
        const maxy = Number(this.list[this.list.length - 1].name) + 1;
        const data = {
          name: maxy,
          nodes: this.arrM
        };
        this.list.push(data);
      } else if (this.timeformat === 4) {
        // 最大值
        const maxy = Number(this.list[this.list.length - 1].name) + 1;
        this.list.push({ name: maxy, nodes: this.arrquarter });
        this.list.push({ name: maxy + 1, nodes: this.arrquarter });
        this.list.push({ name: maxy + 2, nodes: this.arrquarter });
      } else {
        // 最大值
        const maxy = Number(this.list[this.list.length - 1].name) + 1;
        for (let i = 0; i < 10; i++) {
          this.list.push({ name: maxy + i });
        }
      }
      const h = this.$refs.DOM.scrollTop;
      this.$refs.DOM.scrollTo({
        top: h + 200,
        behavior: "smooth"
      });
    },
    /**
     * @description: 会计期间点击
     */
    itemClick(data, data1) {
      this.m = data;// 点击的 月份或者季度
      this.y = data1;// 点击的年份
      this.$forceUpdate();
      let val;
      if (this.timeformat === 3) {
        val = `${this.y}-${this.m}`;
      } else if (this.timeformat === 4) {
        val = `${this.y}${this.m}`;
      } else {
        val = `${this.y}`;
      }
      this.$emit("handleAccChange", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.date-interval-container {
  position: relative;
  .date-bar {
    width: 62px;
    background: #fff;
    border-radius: 5px;
    font-size: 12px;
    .date-choose {
      position: relative;
      text-align: center;
      cursor: pointer;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      // height: 50px;
      border-bottom: 1px solid #f3f3f3;
    }
    .date-sel {
      line-height: 25px;
      cursor: pointer;
    }
    .top-arrow {
      height: 24px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      background: #f2f2f6;
      border-radius: 50%;
      margin: 0 auto 10px auto;
      cursor: pointer;
      .en-icon {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        margin-top: 2px;
      }
    }
    .bottom-arrow {
      height: 24px;
      line-height: 24px;
      width: 24px;
      text-align: center;
      background: #f2f2f6;
      border-radius: 50%;
      margin: 10px auto;
      cursor: pointer;
      .en-icon {
        margin-top: 4px;
      }
    }
    .line-list {
      height: 420px;
      padding-top: 10px;
      overflow: hidden;
    }
    .line {
      height: 510px;
      padding-top: 10px;
      text-align: center;
      .date-item {
        position: relative;
      }
      .date-year {
        height: 12px;
        line-height: 12px;
        background: #edf4ff;
        color: #3e90fe;
        // border-top: 1px solid #ffffff;
        // border-bottom: 1px solid #ffffff;
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        z-index: 9;
      }
      .date-month {
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        position: relative;
        .en-icon{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
        }
      }
      .date-month.cur {
        background: #edf4ff;
        color: #3e90fe;
        font-weight: bold;
      }
      .date-month.disabled {
        color: #b9c4d2;
      }
    }
  }
  .open-icon {
    position: absolute;
    top: 225px;
    right: -4px;
    width: 16px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: rgba(25, 48, 70, 0.5);
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    vertical-align: middle;
    z-index: 9;
  }
}
.active {
  .date-bar,
  .date-panel {
    display: none;
  }
  .open-icon {
    .en-icon {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      margin-top: 2px;
    }
  }
}
.has-declare:after {
  background: #51cfc1;
  position: absolute;
  right: 6px;
  top: 13px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  font-size: 0;
  line-height: 0;
  content: ' ';
  z-index: 99;
  display: block;
}
</style>

<!--
 * 使用案例
      <dateInterval
        :type="1" //期间类型 1数据有层级 2数据无层级
        :hideHead="false" //是否隐藏头部
        :list="dateLineList"  //会计期间数据
        @collapseBtn="isActive=!isActive"  //此回调用于设置外部 样式 展开|隐藏
        @handleAccChange="accChange"   //回调 返回所选会计期间id 多个逗号隔开
      ></dateInterval>
-->
