<!--
 * @Author: pengyu
 * @Date: 2019-10-08 14:52:10
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-02-28 14:07:45
 * @Description: 个人设置-基本信息
 -->
<template>
  <div class="basic" v-en-loading="loadingData">
    <en-title-card name="基本信息"></en-title-card>
    <div class="container" v-bind:class="{ editCont: isEditBasic }">
      <div class="box">
        <div class="user-info">
          <div class="info-list" v-if="!isEditBasic">
            <div class="top">
              <div class="header">
                <img v-if="param.userLogo" :src="param.userLogo" alt="" width="100%" height="100%" />
                <en-user-logo v-else :user-name="param.name" size="90"></en-user-logo>
              </div>
              <div class="name">
                <div class="name-row">
                  <span class="main-txts">{{ param.name }}</span>
                  <en-icon v-if="param.gender" :name="param.gender === 'M' ? 'iconnansheng' : 'iconnvsheng'" size="18" :class="param.gender === 'M' ? 'nan' : 'nv'"></en-icon>
                </div>
                <div class="name-row">
                  <span class="txts">{{ param.tenantName }}</span>
                  <span class="txts">{{ param.post }}</span>
                  <span class="organization-txts" @click="clickOrganization">组织架构</span>
                </div>
              </div>
              <div class="edit">
                <div class="edit-btn" @click="editBasic">
                  <en-icon name="bianji1" size="small"></en-icon>
                  <span>编辑资料</span>
                </div>
              </div>
            </div>
            <div class="grid">
              <dl class="items" v-if="param.no">
                <dt class="tit">工号</dt>
                <dd class="cents">{{ param.no }}</dd>
              </dl>
              <dl class="items">
                <dt class="tit">部门</dt>
                <dd class="cents">
                  <div class="dept-td">
                    <div class="dept-top" style="margin-top:-1px;">
                      <span class="txt">{{ defaulPost[0] ? defaulPost[0].deptText : "" }}</span>
                      <!-- <span class="txt">{{ param.depatementName }}</span> -->
                      <en-icon name="jiangang-gongsiquan" size="small" color="#3e90fe" style="margin-top: -3px;"></en-icon>
                    </div>
                    <div class="jiangang" v-if="activePostData.length">
                      <div class="jiangang-card" v-for="i in activePostData" :key="i.id">
                        <span class="icons"><en-icon name="renyuan-gongsiquan" size="18px"></en-icon></span>
                        <span class="txt1">{{ i.defaultDept === "001" ? "兼岗" : "借调" }}</span>
                        <span class="txt2">{{ i.postText }}</span>
                        <span class="txt3">{{ i.deptText }}</span>
                        <span class="txt4">{{ param.name }}</span>
                      </div>
                    </div>
                  </div>
                </dd>
              </dl>
              <dl class="items">
                <dt class="tit">手机</dt>
                <dd class="cents">{{ hideCardNo(param.mobileNo, 3) }}</dd>
              </dl>
              <dl class="items">
                <dt class="tit">邮箱</dt>
                <dd class="cents">{{ hideCardNo(param.email, 4) }}</dd>
              </dl>
              <dl class="items">
                <dt class="tit">证件</dt>
                <dd class="cents">
                  <div class="zhengjian">
                    <span v-if="!isEditBasic">{{ param.idcardType | getCardType }} {{ hideCardNo(param.idcardNo, param.idcardType) }}</span>
                  </div>
                </dd>
              </dl>
              <dl class="items" v-if="param.birthDay">
                <dt class="tit">生日</dt>
                <dd class="cents">
                  <div class="zhengjian">
                    {{ param.birthDay }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="edit-list" v-else>
            <div class="top">
              <div class="header">
                <img v-if="param.userLogo" :src="param.userLogo" alt="" width="100%" height="100%" />
                <en-user-logo v-else :user-name="param.name" size="90"></en-user-logo>
                <div class="logo-btn" @click="editHeadLogo">
                  修改头像
                </div>
              </div>
              <div class="name">
                {{ param.name }}
              </div>
            </div>
            <div class="grid">
              <el-form ref="myForm" :model="param" :rules="formRules" label-width="60px" label-position="right">
                <el-form-item label="性别" prop="gender">
                  <en-select v-model="param.gender" placeholder="请选择" :data="genderOptions" :default-props="defaultProps" style="width:100%"> </en-select>
                </el-form-item>
                <el-form-item label="工号" prop="no">
                  <el-input v-model="param.no" type="text" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="公司" prop="no">
                  <div class="gongsi">
                    <el-input v-model="param.tenantName" type="text" :disabled="true"></el-input>
                    <span class="txts" @click="clickOrganization">组织架构</span>
                  </div>
                </el-form-item>
                <el-form-item label="部门" prop="jiangang">
                  <div class="dept-td">
                    <div class="dept-top">
                      <el-input v-model="defaulPost[0].deptText" type="text" :disabled="true"></el-input>
                      <!-- <el-input v-model="param.tenantName" type="text" :disabled="true"></el-input> -->
                      <en-icon name="jiangang-gongsiquan" size="small" color="#3e90fe" @click.native="showJiangang"></en-icon>
                    </div>
                    <div class="jiangang"  v-if="activePostData.length && jiangangAcive === 1">
                      <div class="jiangang-card" v-for="i in activePostData" :key="i.id">
                        <span class="icons"><en-icon name="renyuan-gongsiquan" size="18px"></en-icon></span>
                        <span class="txt1">{{ i.defaultDept === "001" ? "兼岗" : "借调" }}</span>
                        <span class="txt2">{{ i.postText }}</span>
                        <span class="txt3">{{ i.deptText }}</span>
                        <span class="txt4">{{ param.name }}</span>
                      </div>
                      <!-- <en-card
                        v-for="i in activePostData"
                        :key="i.id"
                        :title="i.defaultDept === '001' ? '兼岗' : '借调'"
                        :sub-title="i.deptText"
                        icon-name="renyuan-gongsiquan"
                        icon-bg="#3e90fe"
                        icon-color="#ffffff"
                      >
                        <div slot="menu">
                          销售部
                        </div>
                      </en-card> -->
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="岗位" prop="post">
                  <el-input v-model="param.post" type="text" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="mobileNo">
                  <el-input v-model="param.mobileNo" type="text" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="mobileNo">
                  <el-input v-model="param.email" type="text" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="证件" prop="zhengjian">
                  <div class="zhengjian">
                    <en-select v-model="param.idcardType" placeholder="请选择" :data="cardTypeOptions" :default-props="defaultProps"> </en-select>
                    <el-input v-model="param.idcardNo" type="text"></el-input>
                  </div>
                </el-form-item>
                <el-form-item label="生日" prop="birthDay">
                  <el-date-picker v-model="param.birthDay" type="date" placeholder="选择日期"> </el-date-picker>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="account-info">
          <div class="title">
            <span class="txts">账户信息</span>
            <en-icon name="icontianjia-danchuang" size="16" @click.native="addAccount" color="#4793de"></en-icon>
          </div>
          <div class="account-list">
            <!-- 银行卡卡片 -->
            <accountCard
              v-for="(item, index) in param.userBankData"
              :key="index"
              :index="index"
              :bank-id="item.id"
              :bank-addr="item.bankAddr"
              :bank-default="item.isDefault"
              :bank-account-no="item.bankAccountNo"
              :bank-icon="item.bankIcon"
              @editCard="editCard"
              @deleteCard="deleteCard(item.id)"
            />
          </div>
        </div>
      </div>
      <!-- 保存按钮 -->
      <div v-show="isEditBasic" class="save-area">
        <en-button @click="cancelBasic()" type="white">
          取消
        </en-button>
        <en-button type="primary" @click="submitBasic">
          保存
        </en-button>
      </div>
      <!-- 新增编辑银行卡 -->
      <addCard ref="bankForm" :is-edit-bank="isEditBank" />
      <!-- 上传头像控件 -->
      <avatarUpload ref="headAlert" />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { hideCardNo } from "en-js";
import { mapGetters } from "vuex";
import { settingService } from "@/api";
import { base } from "@/assets/js/bank";

import accountCard from "./components/accountCard"; // 银行卡卡片
import addCard from "./components/addCard";
import avatarUpload from "./components/avatarUpload";
// 头像上传
export default {
  name: "",
  components: {
    accountCard,
    addCard,
    avatarUpload
  },
  filters: {
    /**
     * @description: 获取证件类型
     * @param {cardType}
     * @return:
     */
    getCardType(cardType) {
      switch (cardType) {
        case "000":
          return "身份证";
        case "001":
          return "护照";
        case "002":
          return "港澳通行证";
        case "003":
          return "台胞证";
        default:
          return "";
      }
    }
  },
  data() {
    return {
      loadingData: false,
      param: {
        userDeptPostData: []
      },
      isEditBasic: false, // 编辑基本信息是否为编辑状态
      isEditBank: false, // 是否为编辑银行卡
      defaultProps: { value: "id", label: "name" },
      activePost: [],
      genderOptions: [
        // 性别
        { label: "男", value: "M" },
        { label: "女", value: "F" }
      ],
      cardTypeOptions: [
        // 证件集合
        { label: "身份证", value: "000" },
        { label: "护照", value: "001" },
        { label: "港澳通行证", value: "002" },
        { label: "台胞证", value: "003" }
      ],
      showUpload: false, // 显示上传头像按钮
      jiangangAcive: 0,
      formRules: {
        gender: [
          {
            required: true,
            message: "请选择性别",
            trigger: "blur"
          }
        ],
        idcardNo: [
          {
            required: true,
            message: "请输入证件",
            trigger: "blur"
          }
        ]
      },
      imgData: {}
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    activePostData() {
      return this.param.userDeptPostData.filter((item) => item.defaultDept !== "000");
    },
    defaulPost() {
      return this.param.userDeptPostData.filter((item) => item.defaultDept === "000");
    }
  },
  mounted() {
    // 查询个人信息
    this.queryInfo();
  },
  methods: {
    /**
     * @description: 查询个人信息
     */
    async queryInfo() {
      try {
        this.loadingData = true;
        const rsp = await settingService.queryInfoSet();
        if (rsp.userBankData) {
          const bd = rsp.userBankData;
          bd.forEach((item) => {
            const icons = base.bank.find((it) => it.id === item.bankId);
            item.bankIcon = icons.icon;
          });
        }
        this.param = rsp;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * @description: 证件信息加密
     * @param {value} 证件号
     * @param {type}  证件类型
     */
    hideCardNo(value, type) {
      return hideCardNo(value, type);
    },
    /**
     * @description: 显示兼岗卡片
     * @return:
     */
    showJiangang() {
      this.jiangangAcive = this.jiangangAcive === 1 ? 0 : 1;
    },
    /**
     * @description: 跳转组织机构
     * @return:
     */
    clickOrganization() {
      this.$router.push({ path: "organization" });
    },
    /**
     * @description: 编辑基本信息
     * @param {type}
     * @return:
     */
    editBasic() {
      this.isEditBasic = true;
    },
    /**
     * @description: 编辑银行卡
     * @param {index}
     * @return:
     */
    editCard(index) {
      this.isEditBank = true;
      this.$refs.bankForm.visible = true;
      const pa = this.param.userBankData[index];
      this.$refs.bankForm.bankForm = { ...pa };
    },
    /**
     * @description: 删除银行卡
     * @param {index}
     * @return:
     */
    deleteCard(id) {
      this.$confirm("确认删除吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.delBankInfo(id);
          this.queryInfo();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    /**
     * @description: 删除银行卡
     * @param {}
     * @return:
     */
    async delBankInfo(data) {
      await settingService.delBankInfo({ id: data });
    },
    /**
     * @description: 新增银行卡
     * @return:
     */
    addAccount() {
      this.isEditBank = false;
      this.$refs.bankForm.visible = true;
      this.$refs.bankForm.bankForm = { accountName: this.param.name };
    },
    /**
     * @description: 保存
     * @return:
     */
    async submitBasic() {
      const imgD = this.imgData;
      const subData = {
        gender: this.param.gender,
        idcardType: this.param.idcardType,
        email: this.param.email,
        idcardNo: this.param.idcardNo,
        birthDay: dayjs(this.param.birthDay).format("YYYY-MM-DD") || "",
        salary: "salary"
      };
      try {
        this.loadingData = true;
        await settingService.setUserInfo({
          imgData: JSON.stringify(imgD),
          fixedData: JSON.stringify(subData)
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.$message({
          type: "success",
          message: "编辑成功"
        });
        this.isEditBasic = false;
        this.loadingData = false;
        this.queryInfo();
      }
    },
    /**
     * @description: 取消
     * @return:
     */
    cancelBasic() {
      this.isEditBasic = false;
    },
    /**
     * @description: 上传头像
     * @return:
     */
    editHeadLogo() {
      this.$refs.headAlert.dialogVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
// /deep/.el-input.el-input--prefix .el-input__inner{
//   padding: 0 25px;
// }
/deep/.el-form .el-form-item__content, .el-form .el-form-item__label{
  position: relative;
}
/deep/.el-form .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:after{
  position: absolute;
}
/deep/.el-input .el-input__prefix{
  left: -15px;
}
.basic {
  .nan {
    color: #3e90fe;
  }
  .nv {
    color: #f29c9f;
  }
  .container {
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
  }
  .title {
    text-align: left;
    font-size: 14px;
    padding: 20px 0 0 20px;
    font-weight: bold;
    .txts {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }
  }
  .user-info {
    .info-list {
      .top {
        background: url(https://images.softfuxi.com/static/personal_center_bg.jpg) no-repeat;
        background-size: cover;
      }
    }
    .top {
      padding: 20px;
      height: 140px;
    }
    .header {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      overflow: hidden;
      background: #eee;
      float: left;
      position: relative;
      margin-left: 10px;
      text-align: center;
    }
    .logo-btn {
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      height: 30px;
      background-color: #000000;
      opacity: 0.6;
      color: #ffffff;
      line-height: 30px;
      font-size: 12px;
      cursor: pointer;
    }
    .name {
      font-size: 12px;
      //line-height: 90px;
      float: left;
      margin-left: 20px;
      font-weight: bold;
      text-align: left;
      padding-top: 10px;
      .name-row {
        margin-top: 10px;
        color: #333;
        .organization-txts {
          color: #589ce1;
          cursor: pointer;
          font-weight: normal;
        }
        .main-txts {
          font-size: 16px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
        .en-icon {
          display: inline-block;
          vertical-align: middle;
        }
        .txts {
          margin-right: 10px;
        }
      }
    }
    .edit {
      line-height: 90px;
      float: right;
      color: #65a5e3;
      font-size: 12px;
      cursor: pointer;
      margin-top: 20px;
      .en-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .grid {
      text-align: left;
      .items {
        display: flex;
        font-size: 12px;
        line-height: 24px;
        .tit {
          width: 45px;
          line-height: 24px;
          text-align: right;
        }
        .cents {
          margin-left: 20px;
        }
      }
      .el-form {
        display: flex;
        flex-wrap: wrap;
        .el-form-item {
          min-width: 50%;
          max-width: 50%;
          margin-bottom: 10px;
          padding-right: 60px;
        }
      }
    }
    .edit-list {
      .name {
        margin-top: 20px;
        font-size: 16px;
      }
      .zhengjian {
        display: flex;
        .en-select {
          margin-right: 10px;
        }
      }
      .dept-top {
        position: relative;
        .en-icon {
          position: absolute;
          right: 10px;
          top: 6px;
          cursor: pointer;
        }
      }
      .gongsi {
        position: relative;
        .txts {
          position: absolute;
          right: 10px;
          top: 0;
          line-height: 32px;
          color: #589ce1;
          cursor: pointer;
        }
      }
    }
    .head-td {
      padding-top: 0;
      padding-bottom: 0;
    }
    .dept-td {
      // padding: 20px;
      .dept-top {
        line-height: 24px;
        .txt {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .en-icon {
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
      }
      .jiangang {
        margin-top: 10px;
        display: flex;
        .jiangang-card {
          margin-right: 10px;
          width: 350px;
          height: 80px;
          border: 1px solid #f3f3f3;
          position: relative;
          padding:18px 0 0 70px;
          line-height:22px;
          .icons {
            background: rgb(70, 148, 223);
            color: #fff;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            display: block;
            position: absolute;
            left: 20px;
            top: 20px;
            .en-icon {
              margin: 0;
            }
          }
          .txt1,
          .txt3 {
            text-align: left;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis
          }
          .txt2,
          .txt4 {
            text-align: right;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis
          }
          .txt1,
          .txt2 {
            width:120px;
            display: inline-block;
          }
          .txt3,
          .txt4 {
            color: #91a1b7;
            width:120px;
            display: inline-block;
          }
          &:hover{
            background-color: #f5f8fd;
          }
        }
        .en-card {
          margin-right: 10px;
          width: 350px;
          height: 80px;
        }
      }
    }
  }
  .account-info {
    .account-list {
      display: flex;
      flex-flow: row wrap;
      padding: 20px;
    }
    .add-btn {
      width: 460px;
      height: 96px;
      border: 1px solid #e8ebf2;
      border-radius: 3px;
      line-height: 96px;
      color: #4793de;
      cursor: pointer;
      span {
        margin-left: 5px;
      }
    }
  }
  .save-area {
    padding: 20px;
    text-align: right;
  }
  .editCont {
    position: relative;
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .box {
      height: calc(100% - 70px);
      overflow-y: auto;
    }
    .save-area {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #e8ebf2;
      background: #fff;
    }
  }
}
</style>
