var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "en-table",
        {
          attrs: {
            data: _vm.list,
            border: "",
            "hover-row-config": {
              dataMethod: _vm.buttonData,
              changeMethod: _vm.buttonClick,
            },
            height: "100%",
          },
        },
        [
          _c("en-table-column", {
            attrs: { label: "序号", type: "index", width: "80" },
          }),
          _c("en-table-column", {
            attrs: {
              prop: "proxyList",
              label: "代理人",
              formatter: _vm.formatProxy,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "user-mod" },
                      _vm._l(scope.row.proxyList, function (item, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "user-item" },
                          [_vm._v(" " + _vm._s(item.proxyName) + " ")]
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("en-table-column", {
            attrs: { prop: "businessTypeName", label: "授权业务" },
          }),
          _c("en-table-column", {
            attrs: { prop: "formList", width: "240", label: "授权内容" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "user-mod" },
                      _vm._l(scope.row.formList, function (item, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "user-item" },
                          [_vm._v(" " + _vm._s(item.formName) + " ")]
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("en-table-column", {
            attrs: { prop: "createTime", label: "创建日期" },
          }),
          _c("en-table-column", {
            attrs: { prop: "startDate", label: "生效日期" },
          }),
          _c("en-table-column", {
            attrs: { prop: "endDate", label: "失效日期" },
          }),
          _c("en-table-column", {
            attrs: { prop: "enableDate", label: "终止时间" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }