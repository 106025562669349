<!--
 * @Author: pengyu
 * @Date: 2019-10-19 15:55:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-24 13:04:28
 * @Description: 个人设置-安全设置
 -->
<template>
  <div class="security">
    <en-title-card name="系统设置"></en-title-card>
    <div class="container" v-en-loading="enLoading">
      <div class="user-info">
        <div class="grid">
          <table
            border="1"
            cellpadding="14px"
            frame="hsides"
            width="100%"
          >
            <tr>
              <td>绑定号码</td>
              <td class="second">
                <span>{{ hideCardNo(param.mobileNo, 3) }}</span>
                <en-icon
                  name="bianji-liebiao"
                  size="small"
                  color="#27C092"
                  @click.native.stop="editBasicInfo('1')"
                ></en-icon>
              </td>
            </tr>
            <tr>
              <td>绑定邮箱</td>
              <td class="second">
                <span>{{ hideCardNo(param.email, 4) }}</span>
                <en-icon
                  name="bianji-liebiao"
                  size="small"
                  color="#27C092"
                  title="编辑"
                  @click.native.stop="editBasicInfo('2')"
                ></en-icon>
              </td>
            </tr>
            <tr>
              <td>登陆密码</td>
              <td class="second">
                <span>******</span>
                <en-icon
                  name="bianji-liebiao"
                  size="small"
                  color="#27C092"
                  @click.native.stop="editBasicInfo('3')"
                ></en-icon>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!--'修改手机号'-->
    <updatePhone ref="updatePhoneAlert" :value="editValue"></updatePhone>
    <!--'修改邮箱'-->
    <updateEmail ref="updateEmailAlert" :oldEmail="oldEmail"></updateEmail>
    <!--'修改密码'-->
    <updatePwd ref="updatePwdAlert" :info="info"></updatePwd>
    <!-- 修改手机号，邮箱，密码弹框 -->
    <!-- <editInfo
      ref="editAlert"
      :type="editType"
      :value="editValue"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { request, hideCardNo } from "en-js"; // 证件号加密显示
// import editInfo from "./components/editInfo";
import updatePhone from "./alert/updatePhone";// 修改手机号
import updateEmail from "./alert/updateEmail";// 修改邮箱
import updatePwd from "./alert/updatePwd";// 修改密码
// 修改弹窗
export default {
  name: "SecuritySetting",
  components: {
    updatePhone, updateEmail, updatePwd
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  data() {
    return {
      enLoading: null,
      param: {},
      editType: "",
      editValue: "",
      oldEmail: "",
      info: {}
    };
  },
  mounted() {
    // 查询个人信息
    this.queryInfo();
  },
  methods: {
    /**
     * @description: 初始化
     */
    @request(true, "enLoading", {
      subTitle: "数据加载中"
    })
    /**
     * @description: 查询基础设置
     * @param {value}
     * @param {type}
     */
    async queryInfo() {
      this.param = this.userInfo;
    },
    /**
     * @description: 隐藏基本信息
     * @param {value}
     * @param {type}
     */
    hideCardNo(value, type) {
      return hideCardNo(value, type);
    },
    /**
   * @description: 修改手机号邮箱密码
   * @param {type} 1-手机号 2-邮箱 3-密码
   */
    editBasicInfo(type) {
      if (type === "1") {
        this.editValue = this.param.mobileNo;
        this.$refs.updatePhoneAlert.dialogVisible = true;
      }
      if (type === "2") {
        this.oldEmail = this.param.email;
        this.$refs.updateEmailAlert.dialogVisible = true;
      }
      if (type === "3") {
        this.info = { phone: this.param.mobileNo, email: this.param.email };

        this.$refs.updatePwdAlert.dialogVisible = true;
      }
    }
  }
};
</script>
<style lang='scss' scoped>
.security {
  .container {
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
  }
  .user-info {
    .top {
      padding: 20px;
      float: left;
      width: 100%;
      .header {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
        // border: 1px solid;
        float: left;
        position: relative;
      }
      .name {
        font-size: 12px;
        line-height: 90px;
        float: left;
        margin-left: 20px;
        font-weight: bold;
      }
    }
    .grid {
      clear: both;
      table {
        font-size: 12px;
        border-collapse: collapse;
        border-color: #dce5ec;
        border-top-color: #fff;
        tr:nth-child(odd) {
          //background-color: #f7f9f8;
          td:nth-child(1) {
            width: 210px;
          }
        }
        tr:hover{
          background:#f5f8fc;
        }
        td {
          text-align: left;
          border-color: #dce5ec; //兼容 firefox
          &.second {
            .en-icon {
              float: right;
              opacity: 0;
              cursor: pointer;
            }
          }
          &:hover {
            .en-icon {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
/deep/ .el-dialog .el-dialog__footer{
    padding: 20px;
  }
</style>
