<!--
 * @Author: pengyu
 * @Date: 2019-10-11 17:17:41
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-29 19:26:07
 * @Description: 个人设置-偏好设置
 -->
<template>
  <div class="preference" v-en-loading="loadingData">
    <en-tabs v-model="activeTab" :list="tabList" type="white" @change="changeTabs">
      <div slot="right">
        <en-button v-show="activeTab === 'first'" icon="tianjia-anniutoubu" type="primary" @click="addNotice">
          添加
        </en-button>
      </div>
    </en-tabs>
    <!-- 提醒设置 -->
    <div v-if="activeTab === 'first'" class="panel panel1">
      <!-- 待办任务 -->
      <remindSection v-if="taskList.length > 0" :list="taskList" :task-type="0" @deleteNotice="deleteNotice" @editNotice="editNotice" />
      <!-- 订单行程 -->
      <remindSection v-if="travelList.length > 0" :list="travelList" :task-type="1" @deleteNotice="deleteNotice" @editNotice="editNotice" />
      <!-- 行程报销 -->
      <remindSection v-if="reimbursementList.length > 0" :list="reimbursementList" :task-type="2" @deleteNotice="deleteNotice" @editNotice="editNotice" />
      <!-- 考勤提醒 -->
      <remindSection v-if="attList.length > 0" :list="attList" :task-type="3" @deleteNotice="deleteNotice" @editNotice="editNotice" />
      <!-- 任务提醒 -->
      <remindSection v-if="mdTaskList.length > 0" :list="mdTaskList" :task-type="4" @deleteNotice="deleteNotice" @editNotice="editNotice" />
      <en-result type="NoData" v-if="taskList.length == 0 && travelList.length == 0 && reimbursementList.length == 0 && attList.length == 0 && mdTaskList.length == 0"></en-result>
    </div>
    <!-- 按钮设置 -->
    <div v-else-if="activeTab === 'second'" name="second" class="panel panel2">
      <div class="wrap" v-if="ifMainBatchOpera">
        <div>首页批量处理按钮</div>
        <el-switch v-model="homeSwitch" active-color="#4594e1" @change="homeSwitchChange"> </el-switch>
      </div>
      <div class="wrap" v-if="ifSmsSet">
        <div>接收审批通知短信</div>
        <el-switch v-model="msgSwitch" active-color="#4594e1" @change="msgSwitchChange('接收审批通知短信设置')"> </el-switch>
      </div>
      <div class="wrap" v-if="ifShowBusBtn">
        <div>固定显示业务建模列表按钮</div>
        <el-switch v-model="btnSwitch" active-color="#4594e1" @change="btnSwitchhChange"> </el-switch>
      </div>
      <!-- 4.9.12 短信需求 -->
      <div class="wrap" v-if="ifSmsSet">
        <div>其它业务通知短信</div>
        <el-switch v-model="ifNotifyModel" active-color="#4594e1" @change="msgSwitchChange('接收其它业务通知短信设置')"> </el-switch>
      </div>
    </div>
    <!-- 主题设置 -->
    <!-- <div v-else name="third" class="panel panel3">
      <div class="theme cus-theme">
        <div class="title">
          默认主题
        </div>
        <div class="default-color">
          <div
            v-for="(item, index) in defaultColorList"
            :key="index"
            class="default-color-item"
            :style="{ backgroundColor: item.color, borderColor: item.color === '#ffffff' ? '#ababab' : item.color }"
            @click="chooseDefaultColor(item.color, item.name)"
          >
            <en-icon v-show="defaultColor === item.color" name="yixuan" size="small" :color="item.color === '#ffffff' ? '#3e90fe' : '#ffffff'"></en-icon>
          </div>
        </div>
      </div>
      <div class="theme user-theme">
        <div class="title">
          自定义主题
        </div>
        <div>
          <div class="self-color">
            <div class="self-mod">
              <div class="name-color">
                导航区颜色
              </div>
              <en-color-setting v-model="color1" class="en-color-setting" />
            </div>
            <div class="self-mod">
              <div class="name-color">背景颜色</div>
              <en-color-setting v-model="color2" class="en-color-setting" />
            </div>
            <div class="self-mod">
              <div class="name-color">顶部栏颜色</div>
              <en-color-setting v-model="color3" class="en-color-setting" />
            </div>
          </div>
        </div>
      </div>
      <div class="save-btn">
        <en-button type="primary" size="small" @click="submitTheme">
          保存
        </en-button>
      </div>
    </div> -->
    <!-- 新增提醒 -->
    <addNotice ref="noticeForm" :is-edit="isEditNotice" :show="showNotice" :notice-data="noticeData" @toShow="toShow" />
  </div>
</template>

<script>
import { preferenceService } from "@/api";

import remindSection from "./components/remindSection";
import addNotice from "./components/addNotice";

export default {
  name: "PreferenceSetting",
  components: { remindSection, addNotice },
  data() {
    return {
      loadingData: false,
      activeName: "first",
      isEditNotice: false, // 是否为编辑提醒
      attList: [], // 考勤提醒
      mdTaskList: [], // 任务提醒
      reimbursementList: [], // 行程报销
      taskList: [], // 待办任务
      travelList: [], // 订单行程
      // 页签
      activeTab: "first",
      noticeData: {},
      tabList: [
        {
          id: "first",
          name: "提醒设置"
        },
        {
          id: "second",
          name: "按钮设置"
        }
        // {
        //   id: "third",
        //   name: "主题设置"
        // }
      ],
      msgNotice: "",
      homeSet: {},
      homeSwitch: true,
      // 是否接收 审批短信
      msgSwitch: true,
      //  是否显示 接收审批短信
      ifSmsSet: false,
      // 是否接收 其它业务通知短信
      ifNotifyModel: false,
      btnSwitch: true,
      // 是否显示 批量按钮设置
      ifMainBatchOpera: false,
      // 允许固定显示业务建模列表按钮
      ifShowBusBtn: false,
      showNotice: false,
      defaultColorList: [
        {
          name: "default",
          color: "#232c3d"
        },
        {
          name: "blue",
          color: "#599ccd"
        },
        {
          name: "green",
          color: "#3eb9a9"
        },
        {
          name: "brown",
          color: "#9a8185"
        },
        {
          name: "white",
          color: "#ffffff"
        }
      ],
      defaultColor: "#232c3d",
      defaultName: "default",
      color1: "#3eb9a9",
      color2: "#232c3d",
      color3: "#232c3d"
    };
  },
  mounted() {
    // 查询个人信息
    this.queryList();
  },
  methods: {
    /**
     * @description: 查询偏好设置列表
     */
    async queryList() {
      try {
        this.loadingData = true;
        const rsp = await preferenceService.queryRemindList();
        this.attList = rsp.attList; // 考勤提醒
        this.mdTaskList = rsp.mdTaskList; // 任务提醒
        this.reimbursementList = rsp.reimbursementList; // 行程报销
        this.taskList = rsp.taskList; // 待办任务
        this.travelList = rsp.travelList; // 订单行程
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * @description: 查询偏好设置列表
     */
    changeTabs(res) {
      if (res.id === "second" || res.id === "third") {
        this.queryPreferences();
        this.querySmsSet();
      }
    },
    /**
     * @description: 首页批量按钮查询
     */
    async queryPreferences() {
      try {
        this.loadingData = true;
        this.homeSet = await preferenceService.queryPreferences();
        // 允许首页批量审批
        if ("ifMainBatchOpera" in this.homeSet) {
          this.ifMainBatchOpera = this.homeSet.ifMainBatchOpera === 0;
        }
        if ("mainBatchOpera" in this.homeSet) {
          this.homeSwitch = this.homeSet.mainBatchOpera === "000";
        }
        // 允许固定显示业务建模列表按钮
        if ("ifShowBusBtn" in this.homeSet) {
          this.ifShowBusBtn = this.homeSet.ifShowBusBtn === 0;
        }
        this.btnSwitch = this.homeSet.showBusBtn === "000";
        if ("key_topic" in this.homeSet) {
          const topic = JSON.parse(this.homeSet.key_topic);
          const theme = this.defaultColorList.find((item) => item.name === topic.theme);

          this.defaultColor = theme.color;
          this.color1 = topic.menuColor;
          this.color2 = topic.contentColor;
          this.color3 = topic.topMenuColor;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * @description: 接收审批短信按钮设置
     */
    async querySmsSet() {
      const rsp = await preferenceService.querySmsSet();
      this.msgNotice = rsp.smsSet;
      this.msgSwitch = !Number(rsp.smsSet);
      if (rsp.ifSmsSet) {
        this.ifSmsSet = Number(rsp.ifSmsSet) === 0;
      }
      if (rsp.ifNotifyModel) {
        this.ifNotifyModel = Number(rsp.ifNotifyModel) === 0;
      }
    },
    /**
     * @description: 首页批量处理按钮
     */
    homeSwitchChange() {
      this.homeSet.mainBatchOpera = this.homeSwitch ? "000" : "001";
      const msg = this.homeSwitch ? "首页批量处理按钮开启" : "首页批量处理按钮关闭";
      this.savePreferences();
      this.$message({
        type: "warning",
        message: msg
      });
    },
    /**
     * @description: 固定显示业务建模列表按钮
     */
    btnSwitchhChange() {
      this.homeSet.showBusBtn = this.btnSwitch ? "000" : "001";
      const message = this.btnSwitch ? "固定显示业务建模列表按钮开启" : "固定显示业务建模列表按钮关闭";
      this.savePreferences();
      this.$message({
        type: "warning",
        message
      });
    },
    /**
     * @description: 保存偏好设置
     */
    async savePreferences() {
      const params = [];
      Object.keys(this.homeSet).forEach((k) => {
        params.push({ key: k, value: this.homeSet[k] });
      });
      await preferenceService.savePreferences({ data: JSON.stringify(params) });
    },
    /**
     * @description: 接收审批短信按钮
     */
    async msgSwitchChange(str) {
      const pa = {
        smsSet: this.msgSwitch ? "0" : "1",
        ifNotifyModel: this.ifNotifyModel ? "0" : "1"
        };
      await preferenceService.saveSmsSet(pa);
      const msg = this.msgSwitch ? `${str}开启` : `${str}关闭`;
      this.$message({
        message: msg,
        type: "warning"
      });
    },
    /**
     * @description: 新增提醒弹框
     */
    addNotice() {
      this.isEditNotice = false;
      this.noticeData = {
        taskType: 0,
        remindTime: "", // 提醒时间
        remindUnit: "",
        reimbursementType: "", // 报销类型
        businessType: "1", // 考勤提醒类型
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0,
        travelType: []
      };
      this.showNotice = true;
    },
    /**
     * @description: 关闭弹窗
     */
    toShow() {
      this.showNotice = false;
    },
    /**
     * @description: 编辑提醒
     * @param {id} 数据id
     */
    editNotice(data) {
      this.isEditNotice = true;
      this.noticeData = data;
      this.showNotice = true;
    },
    /**
     * @description: 删除提醒
     * @param {id} 数据id
     */
    deleteNotice() {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    /**
     * @description: 选择默认主题颜色
     * @param {color} 色值
     */
    chooseDefaultColor(color, name) {
      this.defaultColor = color;
      this.defaultName = name;
    },
    /**
     * @description: 保存主题设置
     */
    submitTheme() {
      const pa = {
        theme: this.defaultName, // blue/green/brown/white/default/define(自定义)
        menuColor: this.color1,
        contentColor: this.color2,
        topMenuColor: this.color3
      };
      this.homeSet.key_topic = JSON.stringify(pa);
      this.savePreferences();
      this.$message("保存成功");
      // this.$store.commit("_MxChangeVuexState", {
      //   _themeInfo: {
      //     theme: "themeType", // blue/green/brown/white/default/define(自定义)
      //     menuColor: "menuColor", // 颜色值用十六进制，不要用RGB
      //     contentColor: "contentColor", // 颜色值用十六进制，不要用RGB
      //     topMenuColor: "topMenuColor" // 颜色值用十六进制，不要用RGB
      //   }
      // });
    }
  }
};
</script>
<style lang="scss" scoped>
.preference {
  position: relative;
  .panel {
    height: calc(100% - 50px);
    padding: 20px;
    overflow-y: auto;
  }
  .panel2 {
    .wrap {
      height: 30px;
      text-align: left;
      font-size: 12px;
      margin-bottom: 15px;
      > div {
        display: inline-block;
        margin-right: 20px;
        vertical-align: middle;
      }
      /deep/ .el-switch {
        line-height: 0;
      }
    }
  }
  .panel3 {
    position: relative;
    padding:0;
    .theme {
      &.cus-theme{
        padding:20px 20px 0;
        border-bottom: 1px solid #e8ebf2;
      }
      &.user-theme{
        padding:20px;
      }
      .title {
        text-align: left;
        font-weight: bold;
        font-size:14px;
      }
      .default-color {
        padding:20px 0;
        text-align: left;
        .default-color-item {
          width: 30px;
          height: 30px;
          border: 1px solid;
          border-radius: 50%;
          display: inline-block;
          margin-right: 20px;
          // text-align: center;
          // line-height: 30px;
          position: relative;
          .en-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .self-color {
        .self-mod {
          text-align: left;
          margin-top: 20px;
          font-size: 12px;
          > div {
            display: inline-block;
            vertical-align: middle;
          }
          .name-color{
            width:60px;
            margin-right:10px;
            text-align: justify;
            text-align-last: justify;
          }
        }
      }
    }
    .save-btn {
      width: 100%;
      padding: 20px;
      text-align: right;
      position: absolute;
      border-top: 1px solid #e8ebf2;
      left: 0;
      bottom: 0;
    }
  }
}
</style>
