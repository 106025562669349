/**
 * project: user
 * fileName: data
 * Created by 徐俊 on 2020/09/16
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import { request } from "en-js";
import { base64Encode } from "@/tools/util";
import { taxReport } from "@/api/tax";

export default {
  computed: {
    // 默认请求参数
    requestParams() {
      return {
        classifyRefId: this.classifyRefId,
        taxClassifyRefId: this.taxClassifyRefId,
        taxId: this.taxId,
        taxSchemeId: this.taxSchemeId,
        version: this.version
      };
    }
  },

  methods: {
    // 请求
    @request(true, "loading")
    async requestData() {
      // this.loading = true;
      this.$showLoading();
      this.loading1 = false;
      if (!this.areaList) {
        const res = await taxReport.queryTaxReportList(this.requestParams);
        this.areaList = res.areaListData || [];
        this.conditions = res.queryListData || [];
        this.taxName = res.reportName || "";
        this.area = this.areaList[0];
        this.printConfig = res.printConfig;
      }

      if (this.declareTime.length === 0) {
        const declareTimePrecision = this.conditions[0].cycle; // 精度
        const requestParams = { declareTimePrecision, ...this.requestParams };
        const res = (await taxReport.queryTaxReportDeclareTime(requestParams)) || {};
        // console.log(res);
        this.declareTime = res.declareTime || [];
      }
      this.flgloading = true;
      if (!this.area) {
        return;
      }

      if (!this.area.rowList) {
        const areaId = this.area.id;
        const conditions = JSON.stringify(this.conditions);
        const requestParams = { areaId, conditions, ...this.requestParams };
        const res = await taxReport.queryTaxReportControlList(requestParams);
        // isDeclare: 0
        // totalsCols: 11
        // totalsRows: 53
        // values: [[{approach: 0, areaId: "316836483600359424", baseDataTableType: "", baseDataType: "",…}],…]
        this.area.isDeclare = res.isDeclare; // 是否申报
        this.isDeclare = res.isDeclare;
        this.area.rowList = this.processRowData(res);
        this.loading1 = true;
        // 已申报 如果没在 申报完成的数组里面 把它压入到数组
        if (res.isDeclare === 0 && this.declareTime.indexOf(this.conditions[0].valueText) === -1) {
          this.declareTime.push(this.conditions[0].valueText);
        }
      } else {
        // 刷新数据
        this.area.rowList.splice(this.area.rowList.length);
      }

      this.createDateInterval();
      // this.loading = false;
      this.$hideLoading();
    },
    // 处理报表数据
    processRowData(res) {
      // 补位对齐二维数据
      const rowList = [];
      // totalsCols 列宽数量
      const { totalsCols = 0, values = [] } = res;
      this.totalsColsList.length = totalsCols;
      values.forEach((item) => {
        const oneRow = []; // 行数据
        for (let colIndex = 0; colIndex < totalsCols; colIndex++) {
          const row = item.find((row) => row.rCol === colIndex);
          if (!row || row.rCol !== colIndex) {
            // oneRow.push({
            //   value: "", rRow: rowIndex, rCol: colIndex, rowSpan: 0, colSpan: 0
            // });// 空数据占位
          } else {
            oneRow.push(row);
          }
        }
        rowList.push(oneRow);
        // 整行合并后 需要添加一行空tr
        if (item.length) {
          if (item.length === 1 && item[0].colSpan === totalsCols && item[0].rowSpan > 1) {
            for (let trIndex = 0; trIndex < item[0].rowSpan - 1; trIndex++) {
              rowList.push([]);
            }
          } else if (item[0].rowSpan > 1) {
            // 上下合并 每一个格子 都有一样的rowSpan
            const rowSpan = item[0].rowSpan;
            let flg = true;
            item.forEach((v) => {
              if (v.rowSpan !== rowSpan) {
                flg = false;
              }
            });
            if (flg) {
              for (let trIndex = 0; trIndex < item[0].rowSpan - 1; trIndex++) {
                rowList.push([]);
              }
            }
          }
        }
      });
      // console.log(rowList);
      return rowList;
    },
    // 创建会计区间
    createDateInterval() {
      if (this.dateAccList) {
        return;
      }
      const dateAccList = [];
      const date = new Date();
      const curYear = date.getFullYear(); // 获取当前年份
      const curMonth = date.getMonth() + 1;
      for (let year = 1980; year <= 2050; ++year) {
        const nodes = [];
        for (let month = 1; month <= 12; ++month) {
          const item = { yearNum: year, num: month, name: `${year}-${month < 10 ? "0" : ""}${month}` };
          nodes.push(item);

          if (curYear === year && curMonth === month) {
            item.isSelect = true;
            this.dateAccSelect = item;
          }
        }

        dateAccList.push({ name: `${year}`, nodes });
      }

      this.dateAccList = dateAccList;
    },
    // 区域切换
    handleTabChanged(tabData) {
      this.area.rowList = undefined;
      this.$forceUpdate();
      this.area = tabData;
      this.requestData();
    },
    // 会计期间展开收起
    handleShowDateInterval() {
      this.isAccActive = !this.isAccActive;
      this.$nextTick(this.$refs.table.reLayout);
    },
    // 会计期间切换
    handleAccChange(data) {
      this.area.rowList = undefined;
      this.$forceUpdate();
      this.dateAccSelect = data;
      this.conditions[0].value = data;
      this.conditions[0].valueText = data;
      this.area.rowList = undefined;
      this.requestData();
    },
    // 申报
    @request(true)
    handleShenBaoBtn() {
      // this.$message("没找到入口,此处应该调一下接口就行了");
      this.$confirm("确认申报吗？申报之后报表不可修改！", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        const obj = {
          classifyRefId: this.classifyRefId, // 税种ID
          taxClassifyRefId: this.taxClassifyRefId,
          taxId: this.taxId,
          taxSchemeId: this.taxSchemeId,
          declareTime: this.conditions[0].valueText,
          version: this.version,
          declareTimePrecision: this.conditions[0].cycle
        };
        await taxReport.declareTaxReport(obj);
        this.areaList.forEach((item) => {
          item.rowList = undefined; // 清空数据
        });
        this.requestData();
      });
    },
    // 重置
    @request(true)
    async handleResetBtn() {
      const conditions = JSON.stringify(this.conditions);
      const requestParams = {
        conditions,
        declareTimePrecision: this.conditions[0].cycle, // 精度
        declareTime: this.conditions[0].valueText, // 会计期间
        ...this.requestParams
      };
      await taxReport.resetTaxReport(requestParams);

      this.areaList.forEach((item) => {
        item.rowList = undefined; // 清空数据
      });

      this.requestData();
    },
    // 导出
    @request(true)
    async handleOutputBtn() {
      const conditions = JSON.stringify(this.conditions);
      const requestParams = { conditions, ...this.requestParams };
      requestParams.conditions = base64Encode(requestParams.conditions);

      const res = await taxReport.exportTaxUserDataInfo({ ...requestParams, requestType: "stream" });
      console.log(res);
      const { headers } = res;
      const cd = headers["content-disposition"] || "";
      const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/vnd.ms-excel" }));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = decodeURIComponent(cd.split("=")[1]);
      // document.body.appendChild(link);
      link.click();
      // document.body.removeChild(link);
    },
    // 打印
    handlePrintBtn() {
      this.$refs.print.print();
    },
    // 文本改变了
    @request(true, "loading")
    async handleChangeText({ data, text }) {
      // areaId: 316836483600359424
      // controlId: R_5f39d643-d161-4ee9-af82-cf0631872e66
      // controlNameVariable: R_5f39d643-d161-4ee9-af82-cf0631872e66
      // updateTimePrecision: 3
      // updateTime: 2020-09

      const requestParams = {
        value: text,
        areaId: this.area.id,
        controlId: data.controlNameVariable,
        controlNameVariable: data.controlNameVariable,
        updateTimePrecision: this.conditions[0].cycle, // 精度
        updateTime: this.conditions[0].valueText, // 会计期间
        ...this.requestParams
      };
      await taxReport.modifyTaxReportControlValue(requestParams);
      this.area.rowList = undefined;
      this.$forceUpdate();
      // 个人觉得此步骤完全没必要,直接变更掉数据, 后台执行就行了, 体验会好很多,毕竟无比的慢
      // 这样做的原因是 有的地方会联动修改 不重新获取取不到最新的数据
      await this.requestData();
    }
  }
};
