<template>
  <div>
    <div
      class="left-menu"
      v-if="
        this.showLeftMenu != '/setting/organization' &&
        this.showLeftMenu != '/setting/organization/organizationDetail'
      "
    >
      <div
        v-for="(item, index) in menuList"
        :key="index"
        class="item"
        :class="$route.path === item.path ? 'cur' : ''"
        @click="clickMenu(item.path)"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      class="full-box"
      :class="{
        'cent-box':
          this.showLeftMenu != '/setting/organization' &&
          this.showLeftMenu != '/setting/organization/organizationDetail',
      }"
    >
      <transition>
        <router-view class="full-page" />
      </transition>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState: mapStateTheme } = createNamespacedHelpers("main");
export default {
  name: "App",
  data() {
    return {
      menuList: [
        {
          index: 1,
          name: "基本信息",
          path: "/setting/basic"
        },
        {
          index: 2,
          name: "偏好管理",
          path: "/setting/preference"
        },
        {
          index: 3,
          name: "授权管理",
          path: "/setting/authorize"
        },
        {
          index: 4,
          name: "安全设置",
          path: "/setting/security"
        }
      ],
      showLeftMenu: ""
    };
  },
  computed: {
    ...mapStateTheme(["themeInfo"])
  },
  watch: {
    $route: {
      handler(newRouter) {
        this.showLeftMenu = newRouter.path;
        if (newRouter.path.includes("/setting/")) {
          this.themeInfo.openRouterTab = false;
        } else {
          this.themeInfo.openRouterTab = true;
        }
        console.log(newRouter);
      }
    }
  },
  mounted() {
    this.showLeftMenu = this.$route.path;
  },
  methods: {
    /**
     * @description: 左侧菜单切换
     * @param {path} 路由url
     * @return:
     */
    clickMenu(path) {
      const curPath = this.$route.path;
      if (path !== curPath) {
        this.$router.push(path);
      }
      return false;
    }
  }
};
</script>
<style lang='scss'>
@include cssReset;
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  // background: #1d2532;
}
.left-menu {
    width: 196px;
    height: 100%;
    border-radius: 5px;
    float: left;
    background: #ffffff;
    .item {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 12px;
      text-align: left;
      padding-left: 45px;
      cursor: pointer;
      position: relative;
      color: #333;
      &:before {
        display: block;
        width: 5px;
        height: 5px;
        background-color: #a9b5c6;
        border-radius: 50%;
        content: "";
        position: absolute;
        top: 24px;
        left: 28px;
      }
      &.cur {
        background-color: #3e90fe;
        color: #ffffff;
        &:before {
          background-color: #ffffff;
        }
      }
      &:nth-child(1) {
        border-top-right-radius: 3px;
      }
    }
  }
  .full-box {
    background: #fff;
    width: 100%;
    height: 100%;
  }
  .cent-box {
    background: #fff;
    width: calc(100% - 204px);
    height: 100%;
    border-radius: 5px;
    float: right;
  }

  .full-page {
    height: 100%;
    width: 100%;
  }
</style>
