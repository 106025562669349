<!--
 * @Author: Zhouql
 * @Date: 2020-07-20 11:25:55
 * @LastEditTime: 2024-02-29 19:27:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\setting\securitySetting\alert\updatePwd.vue
-->
<template>
  <el-dialog title="修改密码" :visible.sync="dialogVisible" width="700px" :append-to-body="true" :close-on-click-modal="false" class="pwd-alert">
    <div class="step-wrap">
      <en-step-list :step-options="stepOptions" :current-step="step" />
    </div>
    <div class="step-one" v-if="step === 1">
      <el-form ref="pwdForm" class="edit-form">
        <el-form-item prop="checkType">
          <en-select v-model="checkValue" placeholder="请选择" :data="verifyList" :props="defaultProps" align="left" data-mode="id-name"> </en-select>
        </el-form-item>
        <el-form-item label="" prop="validateCode" class="item-code">
          <el-input maxlength="20" placeholder="请输入验证码" v-model="pwdForm.validateCode"></el-input>
          <el-button  size="small" :disabled="buttonDisabled" @click="getAuthCode(checkValue)">
            {{ buttonStatus }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="step-one" v-if="step === 2">
      <el-form ref="passwordForm" :rules="passwordRules" :model="passwordForm" class="edit-form">
        <el-form-item prop="newVal" class="suffix-icon">
          <el-input maxlength="12" placeholder="请输入密码" v-model="passwordForm.newVal" type="password" autocomplete="off"></el-input>
          <en-icon name="bi-denglu" size="small" color="#a9b5c6"></en-icon>
        </el-form-item>
        <el-form-item prop="confirmVal" class="suffix-icon">
          <el-input maxlength="12" placeholder="请输入验证码" v-model="passwordForm.confirmVal" type="password" autocomplete="off"></el-input>
          <en-icon name="bi-denglu" size="small" color="#a9b5c6"></en-icon>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <en-button  @click="submitForm('pwdForm')" v-if="step === 1">下一步</en-button>
      <en-button @click="submitNewForm('passwordForm')" v-if="step === 2">确 定</en-button>
    </span>
  </el-dialog>
</template>

<script>
import { hideCardNo } from "en-js";
import { settingService } from "@/api";

export default {
  name: "updatePwd",
  props: {
    info: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    const validatePass = (rule, value, callback) => {
      // 2.15新增的密码校验 输入的密码包含4个连续或重复的字符
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
      const reg1 = /(\w)*(\w)\2{3}(\w)*/g; // 判断4个连续一样的值
      const numArr = ["0123", "1234", "2345", "3456", "4567", "5678", "6789", "7890"];
      const letter = "abcdefghijklmnopqrstuvwxyz";
      const capital = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      function funarr(data) {
        const arr = [];
        for (let i = 0; i < 23; i++) {
          const str = data.substring(0, 4);
          arr.push(str);
          data = data.substring(1);
        }
        return arr;
      }
      const letterArr = funarr(letter);
      const capitalArr = funarr(capital);
      function checkpassw(passw) {
        numArr.forEach((value) => {
          if (passw.indexOf(value) > -1) {
            return false;
          }
          return value;
        });

        letterArr.forEach((value) => {
          if (passw.indexOf(value) > -1) {
            return false;
          }
          return value;
        });

        capitalArr.forEach((value) => {
          if (passw.indexOf(value) > -1) {
            return false;
          }
          return value;
        });
        return true;
      }
      // 2.15 检验是否包含汉子
      const reg2 = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (reg2.test(value)) {
        // 包含汉子
        callback(new Error("输入的密码不能包含中文"));
      }
      const reg3 = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]", "g");
      if (reg3.test(value)) {
        // 包含特殊字符
        callback(new Error("输入的密码不能包含特殊字符"));
      }
      if (value.indexOf(" ") > -1) {
        // 包含空格
        callback(new Error("输入的密码不能包含空格"));
      }
      if (value.length < 8) {
        callback(new Error("输入的密码不足8位"));
      }
      if (value.length > 12) {
        callback(new Error("输入的密码超过12位"));
      }
      if (!reg.test(value)) {
        callback(new Error("输入的密码必须包含字母(大写或小写)和数字的组合"));
      }
      if (reg1.test(value)) {
        callback(new Error("为了保证安全性，输入的密码不能包含4个重复的字符"));
      }
      if (!checkpassw(value)) {
        console.log(checkpassw(value));
        callback(new Error("为了保证安全性，输入的密码不能包含4个连续的字符"));
        return;
      }

      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.passwordForm.confirmVal !== "") {
          this.$refs.passwordForm.validateField("confirmVal");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordForm.newVal) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      stepOptions: ["验证身份", "修改密码"],
      step: 1, // 当前第几步骤
      // 修改登录密码的时候  需要获取登陆人的手机号或者邮箱 接收验证码
      defaultProps: {
        label: "label",
        value: "id"
      },
      verifyList: [], // 选择验证方式
      buttonStatus: "获取验证码", // 按钮状态
      buttonDisabled: false,
      pwdForm: { checkType: "", validateCode: "" }, // step1原有form信息
      passwordForm: { newVal: "", confirmVal: "" }, // step2密码

      passwordRules: {
        newVal: [{ validator: validatePass, trigger: "blur" }],
        confirmVal: [{ validator: validatePass2, trigger: "blur" }]
      },
      checkValue: {
        id: 1,
        label: hideCardNo(this.info.phone, 3)
      }
    };
  },
  watch: {
    dialogVisible(newval) {
      if (newval) {
        const phoneNum = hideCardNo(this.info.phone, 3);
        const emailNum = hideCardNo(this.info.email, 4);
        this.verifyList = [
          { label: phoneNum, id: 1 },
          { label: emailNum, id: 2 }
        ];
      } else if (this.step === 1) {
        this.resetForm("pwdForm");
      } else {
        this.resetForm("passwordForm");
      }
    }
  },
  methods: {
    // 获取验证码
    async getValidateCode(value) {
      try {
        this.loading = true;
        const res = await settingService.sendValidateCode({
          mobileNo: value
        });
        console.log(res);
      } catch (e) {
        console.error(e);
        clearInterval(this.time);
        this.buttonStatus = "获取验证码";
        throw e;
      } finally {
        this.loading = false;
      }
    },
    // 验证验证码
    async checkValidateCode(item) {
      try {
        this.loading = true;
        const res = await settingService.checkValidateCode({
          mobileNo: this.info.phone,
          validateCode: item.validateCode
        });
        console.log(res);
        this.$message("提交成功");
      } catch (e) {
        this.dialogVisible = true;
        throw e;
      } finally {
        this.loading = false;
      }
    },
    // 设置新密码
    async setPassword(item) {
      try {
        this.loading = true;
        const res = await settingService.forgetPassword({
          repsw: item.confirmVal,
          mobileNo: this.info.phone,
          sendMsgMobileNo: this.info.phone,
          validateCode: this.pwdForm.validateCode
        });
        console.log(res);
        this.$message("提交成功");
        this.dialogVisible = false;
        this.resetForm("passwordForm");
      } catch (e) {
        console.error(e);
        this.dialogVisible = true;
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description: 获取验证码
     */
    getAuthCode(value) {
      let tt = 60;
      const _this = this;
      _this.buttonDisabled = true;
      if (value.id === 1) {
        this.getValidateCode(this.info.phone);
      }
      if (value.id === 2) {
        this.getValidateCode(this.info.email);
      }

      const time = setInterval(() => {
        tt--;
        if (tt === 0) {
          _this.buttonStatus = "获取验证码";
          _this.buttonDisabled = false;
          clearInterval(time);
        } else {
          _this.buttonStatus = `重新获取(${tt})`;
        }
      }, 1000);
      _this.time = time;
    },
    /**
     * @description: 提交验证方式
     * @param {type}
     */
    submitForm() {
      const item = { validateCode: this.pwdForm.validateCode, checkType: this.pwdForm.checkType };
      this.checkValidateCode(item).then(() => {
        this.step = 2;
      });
    },
    /**
     * @description: 提交新密码方式
     * @param {type}
     */
    submitNewForm(passwordForm) {
      this.$refs[passwordForm].validate((valid) => {
        if (valid) {
          this.setPassword(this.passwordForm);
        } else {
          console.log("error submit!!");
          return false;
        }
        return valid;
      });
    },
    // 重置表单数据
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
.pwd-alert {
  .el-input /deep/ .el-input__inner {
    height: 40px;
    line-height: 40px;
  }
    .el-form-item__content .el-button {
      color: #fff;
      background: #3e90fe;
      padding: 12px 15px;
      border: none;
    }
    .el-form-item__content .el-button:hover {
      background: #5a9ffc;
    }
    .el-form-item__content .el-button:active {
      background: #2176e8;
    }
    .is-disabled{
      background: rgba(62,144,254,0.5)!important;
    }
    /deep/.en-button--blue.en-btn-normal{
      color: #fff;
      background: #3e90fe;
      padding: 12px 15px;
    }
    /deep/.en-button--blue.en-btn-normal:hover{
      background: #5a9ffc!important;
    }
    /deep/.en-button--blue.en-btn-normal:active {
      background: #2176e8!important;
    }
  .step-wrap {
    /deep/ .en-setp-list {
      margin: 30px auto;
    }
  }
  .edit-form {
    text-align: center;
    /deep/ .el-form-item {
      width: 462px;
      height: 40px;
      margin: 20px auto;
      &.item-code {
        .el-form-item__content {
            width: 462px;
            display: flex;
            justify-content: space-between;
            color: #fff;
            .el-input {
              width: 354px;
              float: left;
            }
            /deep/ .el-button {
              float: right;
            }
          }
          .el-form-item__content::after{
            content: none;
          }
          .el-form-item__content::before{
            content: none;
          }
      }
      .en-select {
        width: 100%;
      }
      /deep/ .el-input__inner {
        height: 40px;
        line-height: 40px;
      }
    }
  }
  .suffix-icon {
    position: relative;
    .en-icon {
      position: absolute;
      top: 12px;
      right: 10px;
    }
  }
}
</style>
