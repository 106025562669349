<!--
 * @Author: pengyu
 * @Date: 2021-06-16 17:51:13
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-08 14:52:37
 * @Description: 法律知识库--列表无目录--需要二级路由
-->
<template>
  <div>
    <router-view v-if="fullScreen" />
    <div class="half-screen" v-else>
      <top-nav></top-nav>
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import topNav from "./components/topNav";

export default {
  name: "App",
  components: { topNav },
  data() {
    return {
      fullScreen: false
    };
  },
  watch: {
    $route(to) {
      const { fullScreen } = to.meta;
      this.fullScreen = fullScreen;
    }
  }
};
</script>

<style lang="scss">
.half-screen {
  width: 100%;
  height: 100%;
  .content {
    height: calc(100% - 60px);
    background: #1d2532;
    padding: 10px;
  }
}
</style>
