var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: {
        title: "修改头像",
        width: "700px",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.dialogOpen,
        close: _vm.destroy,
      },
    },
    [
      _c("div", { staticClass: "avatar" }, [
        _c(
          "div",
          { staticClass: "avatar-cropper", on: { click: _vm.pickImage } },
          [
            _c(
              "en-button",
              { attrs: { icon: "tianjia-anniutoubu", text: "" } },
              [_vm._v(" 上传图片 ")]
            ),
            _vm.dataUrl
              ? _c("div", { staticClass: "avatar-cropper-overlay" }, [
                  _c("div", { staticClass: "avatar-cropper-container" }, [
                    _c(
                      "div",
                      { staticClass: "avatar-cropper-image-container" },
                      [
                        _c("img", {
                          ref: "img",
                          attrs: { src: _vm.dataUrl, alt: "" },
                          on: {
                            load: function ($event) {
                              $event.stopPropagation()
                              return _vm.createCropper.apply(null, arguments)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("input", {
              ref: "imgInput",
              staticClass: "avatar-cropper-img-input",
              attrs: { accept: _vm.mimes, type: "file" },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "avatar-result" }, [
          _c("span", [_vm._v("头像预览")]),
          _c("div", {
            staticClass: "avatar-preview",
            attrs: { id: "avatarPreview" },
          }),
        ]),
      ]),
      _vm.dataUrl
        ? _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "white", size: "mini" },
                  on: { click: _vm.reupload },
                },
                [_vm._v(" 重新上传 ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("span", [_vm._v("仅支持JPG、JPEG、PNG、BMP、GIF格式！")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }