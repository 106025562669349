var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tax-report" }, [
    _c(
      "div",
      { ref: "header", staticClass: "main-box", attrs: { id: "printMe" } },
      [
        _c("div", { staticClass: "top-box1" }, [
          _c("img", {
            style: _vm.stylefun2(),
            attrs: {
              src: require("@/assets/images/enlogo.png"),
              alt: "",
              width: "60px",
            },
          }),
        ]),
        _c("div", { staticClass: "top-box" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "title", style: _vm.stylefun1() }, [
              _vm._v(_vm._s(_vm.taxName)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "area-box" }, [
          _c("div", { staticClass: "left" }, [_vm._v(_vm._s(_vm.name))]),
        ]),
        _c("div", { staticClass: "table-box" }, [
          _vm.flg
            ? _c(
                "table",
                {
                  ref: "table",
                  staticClass: "table",
                  attrs: { cellspacing: "0", cellpadding: "0", border: "0" },
                },
                [
                  _c(
                    "tbody",
                    _vm._l(_vm.rowList, function (item, index) {
                      return _c(
                        "tr",
                        { key: index },
                        _vm._l(item, function (item1, index1) {
                          return _c(
                            "td",
                            {
                              key: index1,
                              style: _vm.stylefun(item1),
                              attrs: {
                                colSpan: item1.colSpan || 1,
                                rowSpan: item1.rowSpan || 1,
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.showText(item1)) + " ")]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }