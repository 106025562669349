<!--
 * @Author: pengyu
 * @Date: 2020-04-24 10:03:40
 * @LastEditTime: 2021-07-19 11:05:01
 * @LastEditors: zhulin
 * @Description: 个人设置-修改头像
 * @FilePath: \user\src\views\setting\basicSetting\components\avatarUpload\index.vue
 -->
<template>
  <en-dialog title="修改头像" width="700px" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false" @open="dialogOpen" @close="destroy">
    <div class="avatar">
      <div class="avatar-cropper" @click="pickImage">
        <en-button icon="tianjia-anniutoubu" text>
          上传图片
        </en-button>
        <div v-if="dataUrl" class="avatar-cropper-overlay">
          <div class="avatar-cropper-container">
            <div class="avatar-cropper-image-container">
              <img ref="img" :src="dataUrl" alt @load.stop="createCropper" />
            </div>
          </div>
        </div>
        <input ref="imgInput" :accept="mimes" class="avatar-cropper-img-input" type="file" />
      </div>
      <div class="avatar-result">
        <span>头像预览</span>
        <div id="avatarPreview" class="avatar-preview"></div>
      </div>
    </div>
    <div v-if="dataUrl" class="btn">
      <el-button type="white" size="mini" @click="reupload">
        重新上传
      </el-button>
    </div>
    <div slot="footer" class="dialog-footer">
      <span>仅支持JPG、JPEG、PNG、BMP、GIF格式！</span>
      <el-button type="primary" size="mini" @click="submit">
        确定
      </el-button>
    </div>
  </en-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs"; // 图片裁剪工具

export default {
  name: "ImgUpload",
  data() {
    return {
      dialogVisible: false,
      cropper: null,
      dataUrl: undefined, // 头像src
      fileObj: null, // 文件对象,
      fileName: "",
      filesuffix: "",
      imgData: {},
      mimes: "image/png, image/gif, image/jpeg, image/bmp, image/jpg" // 图片格式
    };
  },
  computed: {
    ...mapGetters(["urlParam"])
  },
  methods: {
    /**
     * @description: 窗口打开回调 注册inupt change事件
     * @param {type}
     * @return:
     */
    dialogOpen() {
      const _this = this;
      this.$nextTick(() => {
        const fileInput = _this.$refs.imgInput;
        fileInput.removeEventListener("change", _this.uploadChange);
        fileInput.addEventListener("change", _this.uploadChange);
      });
    },
    /**
     * @description: input注册change事件
     * @return:
     */
    uploadChange() {
      const _this = this;
      const fileInput = _this.$refs.imgInput;
      if (fileInput.files != null && fileInput.files[0] != null) {
        _this.fileObj = fileInput.files;
        const reader = new FileReader();
        reader.onload = (e) => {
          _this.dataUrl = e.target.result;
          _this.cropper && _this.cropper.replace(_this.dataUrl, false);
        };
        this.fileName = fileInput.files[0].name;
        const index = this.fileName.lastIndexOf(".");
        const ext = this.fileName.substr(index + 1);
        this.filesuffix = ext;
        reader.readAsDataURL(fileInput.files[0]);
      }
    },
    /**
     * @description: 点击上传图片
     * @param {event}
     * @return:
     */
    pickImage(e) {
      if (this.dataUrl) {
        return;
      }
      this.$refs.imgInput.click();
      e.stopPropagation();
    },
    /**
     * @description: 创建cropper实例
     * @return:
     */
    createCropper() {
      const _this = this;
      if (!_this.cropper) {
        _this.cropper = new Cropper(this.$refs.img, {
          background: true, // 网格背景
          modal: true, // 裁剪时显示模态窗口
          aspectRatio: 1, // 裁剪容器比例
          autoCropArea: 0.5, // 裁剪区域大小0-1
          viewMode: 1,
          movable: true, // 裁剪框是否允许移动
          resizable: true, // 裁剪框是否允许改变大小
          zoomable: false, // 是否允许放大缩小图片
          guides: true, // 裁剪框内虚线
          preview: ".avatar-preview" // 预览视图
        });
      } else {
        _this.cropper.clear();
      }
    },
    /**
     * @description: 重新上传
     * @return:
     */
    reupload() {
      this.$refs.imgInput.click();
    },
    /**
     * @description: 销毁cropper实例，关闭弹窗
     * @return:
     */
    destroy() {
      this.$refs.imgInput.value = "";
      this.dataUrl = undefined;
      this.fileObj = null;
      this.cropper = null;
      document.querySelector("#avatarPreview").innerHTML = "";
      this.dialogVisible = false;
    },
    /**
     * @description: 将base64转换为文件
     * @return:
     */
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    /**
     * @description: 确定按钮 获取裁剪后的图片
     */
    submit() {
      if (this.dataUrl) {
        const cas = this.cropper.getCroppedCanvas(); // 获取被裁剪后的canvas
        const base64 = cas.toDataURL("image/jpeg"); // 转换为base64
        const file = this.dataURLtoFile(base64, this.fileName);
        const formFile = new FormData();
        formFile.append("appKey", this.urlParam.appKey);
        formFile.append("token", this.urlParam.token);
        formFile.append("ts", this.urlParam.ts);
        formFile.append("storage_file", file); // 加入文件对象

        console.log("this.imgData", this.imgData);
        axios
          .post(this.urlParam.server, formFile)
          .then((response) => {
            const fdata = response.data.data;
            this.imgData = {
              url: fdata.fileCode,
              name: file.name,
              suffix: this.filesuffix,
              fileSize: Number(file.size).toString()
            };
            this.$parent.$data.imgData = this.imgData;
            this.$parent.$data.param.userLogo = fdata.fileUrl;
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
      this.destroy();
    }
  }
};
</script>

<style lang="scss" scoped>
.el-dialog {
  .el-dialog__header {
    text-align: left;
  }
  .el-dialog__body {
    padding-top: 0;
  }
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: 12px;
    color: #c0c0c0;
    margin-bottom:6px;
    span{
      line-height:24px;
    }
  }
}
.avatar {
  display: flex;
  .avatar-cropper {
    width: 420px;
    height: 280px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    line-height: 280px;
    text-align: center;
    .el-button {
      color: #d9d9d9;
    }
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
      .el-button {
        color: #409eff;
      }
    }
    .avatar-cropper-overlay {
      width: 420px;
      height: 280px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99999;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .avatar-cropper-img-input {
      display: none;
    }
    .avatar-cropper-container {
      background: #fff;
      z-index: 999;
      box-shadow: 1px 1px 5px rgba(100, 100, 100, 0.14);
      .avatar-cropper-image-container {
        position: relative;
        max-width: 400px;
        height: 300px;
      }
      img {
        max-width: 100%;
        height: 100%;
      }
    }
  }
  .avatar-result {
    margin-left: 40px;
    text-align: left;
    font-size: 12px;
    .avatar-preview {
      border: 1px solid #d9d9d9;
      margin-top: 20px;
      width: 100px;
      height: 100px;
      overflow: hidden;
    }
  }
}
.btn {
  padding: 10px 0;
  text-align: left;
}
</style>
