<!--
 * @Author: zhangjiaheng
 * @Date: 2020-12-24 14:28:18
 * @LastEditTime: 2021-06-21 14:17:36
 * @LastEditors: Please set LastEditors
 * @Description: 税务报表打印
 * @FilePath: \user\src\views\tax\report\print.vue
-->
<template>
  <!--头数据-->
  <div class="tax-report">
    <div class="main-box" id="printMe" ref="header">
      <div class="top-box1">
        <img src="@/assets/images/enlogo.png" :style="stylefun2()" alt="" width="60px">
      </div>
      <div class="top-box">
        <div class="left">
          <div class="title" :style="stylefun1()">{{ taxName }}</div>
        </div>
      </div>

      <!--      区域-->
      <div class="area-box">
        <div class="left">{{name}}</div>
      </div>
      <!--      报表-->
      <div class="table-box">
        <table v-if="flg"  class="table" ref="table" cellspacing="0" cellpadding="0" border="0">
          <tbody>
            <tr v-for="(item,index) in rowList" :key="index">
              <td :style="stylefun(item1)" :colSpan="(item1.colSpan||1)" :rowSpan="(item1.rowSpan||1)" v-for="(item1,index1) in item" :key="index1">
                {{ showText(item1) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { thousandFormat } from "en-js";
import printJS from "print-js";

export default {
  name: "TaxReportPrint",
  data() {
    return {
      num0Format: { 1: "", 2: "0.00", 3: "--" },
      currency: {
        0: "",
        1: "¥",
        2: "$",
        3: "€",
        4: "£"
      },
      flg: true

    };
  },
  props: {
    taxName: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    rowList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 打印设置
    printConfig: {
      type: Object,
      default() {
        return {
          titleColor: "#000", // 标题颜色
          titleFormat: "1", // 0 加粗 1 不加粗
          printLogo: "1", // 0打印//1不打印
          logoFormat: "1"// 1左 2右
        };
      }
    }
  },
  computed: {
    // 行内

  },
  watch: {
    rowList: {
      handler() {
        this.$forceUpdate();
        this.flg = false;
        this.$nextTick(() => {
          this.flg = true;
        });
      }
    }
  },
  mounted() {
  },
  methods: {
    // 标题样式
    stylefun1() {
      const str = [];
      str.push(`color:${this.printConfig.titleColor};`);
      if (String(this.printConfig.titleFormat) === "1") {
        str.push("font-weight: normal;");
      } else {
        str.push("font-weight: bold;");
      }
      return str.join(" ");
    },
    // logo图标
    stylefun2() {
      const str = [];
      if (this.printConfig.printLogo === "1") {
        str.push("dispaly: none;");
      } else {
        str.push("position: absolute;");
        str.push(this.printConfig.logoFormat === "1" ? "left: 0;" : "right: 0;");
      }
      return str.join(" ");
    },
    // 单元格样式
    stylefun(item1) {
      // { color: data.showFormat.color, background: data.showFormat.fillColor }
      const str = [];

      str.push(`color:${item1.showFormat.color};`);
      if (!(item1.value === "") && item1.showFormat) {
        str.push(`background:${item1.showFormat.fillColor};`);
      }
      if (String(item1.showFormat.dataFormat) === "1") {
        str.push("text-align: left;");
      } else if (String(item1.showFormat.dataFormat) === "2") {
        str.push("text-align: center;");
      } else if (String(item1.showFormat.dataFormat) === "3") {
        str.push("text-align: right;");
      }
      return str.join(" ");
    },
    print() {
      printJS({
        printable: "printMe",
        type: "html",
        css: "../../../css/print-table.css",
        // header: "打印测试",
        // documentTitle: "水印|保存名称",
        maxWidth: 900,
        scanStyles: false,
        honorColor: true, // 彩打
        targetStyles: ["*"],
        onPrintDialogClose: (err) => {
          console.log("取消打印", err);
        },
        error: (err) => {
          console.log("打印errrrr", err);
        },
        onLoadingStart: (err) => {
          console.log("onLoadingStart", err);
        },
        onLoadingEnd: (err) => {
          console.log("onLoadingEnd", err);
        }
      });
    },
    // 显示文本
    showText(data) {
      let value = "";
      if (typeof data.value === "object") {
        value = data.value[0].name;
      } else {
        value = data.value;
      }
      const showFormat = typeof data.showFormat === "string" ? JSON.parse(data.showFormat) : data.showFormat;
      // data.type == 3 || data.type == 4
      // 只要是数字 并且不是 1 类型
      let flg = false;
      let flg1 = false; // flg1 为true 不加2位小数
      if (String(data.approach) === "2") {
        // 汇总
        flg = true;
      } else if (String(data.valueType) === "4") {
        // 不是汇总
        // 公式计算的结果 如果是 数字
        if (!isNaN(Number(data.value))) {
          flg = true;
        }
      } else if (String(data.valueType) === "5") {
        // 直接取值
        // 不管
      } else {
        flg1 = true;
        // 4.6 文本类型 按照最终值 进行处理
        if (String(data.controlType) === "1") {
          // 文本类型
          if (!isNaN(Number(data.value))) {
            flg = true;
          }
        } else if (String(data.controlType) !== "93" && String(data.controlType) !== "0") {
          // 也不是 计算公式的 结果
          flg = true;
        }
      }

      if (flg) {
        let reserverDecimal = Number((showFormat && showFormat.reserverDecimal) || 2);
        if (flg1) {
          reserverDecimal = 0;
        }
        if (String(showFormat.numberFormat) === "1") {
          // 数值
          if (String(showFormat.permillage) === "0") {
            // 千分符显示
            value = thousandFormat(Number(value).toFixed(reserverDecimal));
          } else {
            value = Number(value).toFixed(reserverDecimal);
          }
        } else {
          // 百分比
          value = `${(Number(value) * 100).toFixed(reserverDecimal)}%`;
        }
      }

      const num0Format = this.num0Format[showFormat.num0Format || "1"];
      const currency = this.currency[showFormat.currency || "0"];

      let valuecurrency = ""; // 货币
      if (Number(value) === 0) {
        if (String(showFormat.num0Format) === "2") {
          // 为 0.00 的时候 应该 加货币符号
          // value = currency + num0Format;
          value = num0Format;
          valuecurrency = currency;
        } else {
          value = num0Format;
        }
      } else if (String(showFormat.numberFormat) === "1") {
        valuecurrency = currency;
      }
      this.valuecurrency = valuecurrency;
      this.value = value;
      return valuecurrency + value;
    }
  }
};
</script>

<style scoped lang="scss">
$space: 20px;
.tax-report {
  height: 100%;
  .main-box {
    font-family: Microsoft YaHei,Arial,sans-serif;
    background-color: white;
    height: 100%;
  }
  .top-box1 {
    position: relative;
  }
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 0 $space;
    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 14px;
        font-weight: bold;
        color: rgb(0, 0, 0);
      }
    }
  }

  .area-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    font-size: 12px;
    .right {
      font-size: 12px;
      color: #606060;
      min-width: 120px;
      text-align: right;
      margin-right: $space;
    }
  }
  .table-box{
    width: 100%;
    overflow: auto;
    // height: calc(100% - 101px);
    // padding: 10px;
  }
  .table {
    width: 100%;
    color: #606060;
    font-size: 12px;
    font-family: Microsoft YaHei,Arial,sans-serif;
    td{
      height: 40px;
      padding: 0;
      min-width: 140px;
      padding: 2px 10px;
      line-height: 20px;
      border: 1px solid #333;
      word-break: break-all;
    }
  }
}
</style>
