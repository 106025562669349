var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tax-report" },
    [
      _c(
        "div",
        {
          ref: "header",
          class: [
            "main-box",
            { "has-right": _vm.isAccActive && _vm.dateAccList },
          ],
        },
        [
          _c("div", { staticClass: "top-box" }, [
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.taxName)),
              ]),
              _vm.isDeclareStr !== ""
                ? _c(
                    "div",
                    { staticClass: "shenbao", style: _vm.isDeclareStyle },
                    [_vm._v(_vm._s(_vm.isDeclareStr))]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "right" },
              [
                _vm.isDeclareStr === "未申报" && _vm.isDeclareTime
                  ? _c(
                      "en-button",
                      {
                        attrs: {
                          icon: "xuanzefangan1",
                          "icon-color": "green",
                          "icon-size": "18",
                          type: "text",
                        },
                        on: { click: _vm.handleShenBaoBtn },
                      },
                      [_vm._v("申报")]
                    )
                  : _vm._e(),
                _vm.isDeclareStr === "未申报"
                  ? _c(
                      "en-button",
                      {
                        attrs: {
                          icon: "gengxintiaojian",
                          "icon-color": "blue",
                          "icon-size": "18",
                          type: "text",
                        },
                        on: { click: _vm.handleResetBtn },
                      },
                      [_vm._v("重置")]
                    )
                  : _vm._e(),
                _c(
                  "en-button",
                  {
                    attrs: {
                      icon: "daochu-danchuang",
                      "icon-color": "green",
                      "icon-size": "18",
                      type: "text",
                    },
                    on: { click: _vm.handleOutputBtn },
                  },
                  [_vm._v("导出")]
                ),
                _c(
                  "en-button",
                  {
                    attrs: {
                      icon: "dayin-kuaijiecaozuo",
                      "icon-color": "blue",
                      "icon-size": "18",
                      type: "text",
                    },
                    on: { click: _vm.handlePrintBtn },
                  },
                  [_vm._v("打印")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "line" }),
          _c(
            "div",
            { staticClass: "area-box" },
            [
              _vm.areaList && _vm.areaList.length > 0
                ? _c("en-tabs", {
                    attrs: {
                      list: _vm.areaList,
                      value: _vm.area ? _vm.area.id : undefined,
                      type: "white",
                    },
                    on: { change: _vm.handleTabChanged },
                  })
                : _c("div"),
              _c("div", { staticClass: "right" }, [
                _vm._v("金额单位：元至角分"),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "table-box" }, [
            _vm.loading1
              ? _c(
                  "table",
                  {
                    ref: "table",
                    staticClass: "table",
                    attrs: { cellspacing: "0", cellpadding: "0", border: "0" },
                  },
                  [
                    _c(
                      "tbody",
                      _vm._l(_vm.area.rowList, function (item, index) {
                        return _c(
                          "tr",
                          { key: index },
                          _vm._l(item, function (item1, index1) {
                            return _c(
                              "td",
                              {
                                key: index1,
                                ref: "hang" + index + "lie" + index1,
                                refInFor: true,
                                attrs: {
                                  colSpan: item1.colSpan || 1,
                                  rowSpan: item1.rowSpan || 1,
                                },
                              },
                              [
                                _c("report-content", {
                                  attrs: {
                                    isDeclare: _vm.isDeclare,
                                    data: item1,
                                  },
                                  on: {
                                    getStyle: function ($event) {
                                      return _vm.getStyle(index, index1, $event)
                                    },
                                    change: _vm.handleChangeText,
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm.flgloading
        ? _c("dateInterval", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dateAccList,
                expression: "dateAccList",
              },
            ],
            staticClass: "date-interval",
            attrs: {
              data: _vm.conditions[0].value,
              timeformat: _vm.conditions[0].cycle,
              declare: _vm.declareTime,
            },
            on: {
              collapseBtn: _vm.handleShowDateInterval,
              handleAccChange: _vm.handleAccChange,
            },
          })
        : _vm._e(),
      _vm.area
        ? _c("print", {
            ref: "print",
            attrs: {
              name: _vm.area.name,
              printConfig: _vm.printConfig,
              rowList: _vm.area.rowList,
              taxName: _vm.taxName,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }