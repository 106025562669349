<!--
 * @Author: zhangjiaheng
 * @Date: 2020-09-24 11:28:50
 * @LastEditors: zhangjiaheng
 * @LastEditTime: 2020-09-25 16:23:29
 * @Description: 税务报表入口页面 看板加列表
 * @FilePath: \user\src\views\tax\chart\index.vue
-->
<template>
  <div class="tax-box">
    <div class="tax-title">
      <span class="txt">税务报表</span>
      <en-select v-model="curTax" placeholder="请选择纳税主体" :data="mainList" :width="190" style="display: inline-block;width: 140px;margin-left:20px;" @change="changeMain"></en-select>
      <en-select v-if="viewFlg" v-model="view" placeholder="请选择" :data="options" :width="190" style="display: inline-block;width: 140px;margin-left:20px;" @change="changeView"></en-select>
    </div>
    <div v-if="repaint" v-loading="cenloading" class="tax-center">
      <en-result v-if="noData" type="NoData"></en-result>
      <div v-else-if="view == '1'" class="list-wrapper">
        <div v-for="item in tableList" :key="item.id" class="item">
          <div class="item-title">{{ item.name }}<en-icon @click.native="checkScheme(item)" name="xuanzefangan1" size="small" style="color: #3e90fe;margin-left: 10px;"></en-icon></div>
          <en-card
            v-for="item1 in item.taxClassifyList"
            :key="item1.id"
            @click.native="gotoReport(item1, item)"
            class="btn"
            type="normal"
            :title="item1.taxName"
            icon-name="moban-yewujianmo14"
            icon-color="#FFFFFF"
            icon-bg="#3e90fe"
          ></en-card>
        </div>
      </div>
      <!-- 看板视图 -->
      <div v-else-if="view == '2'" class="tax-chart">
        <div v-for="item in pic" :key="item.id" class="item">
          <div class="item-cen">
            <chart :data="item"></chart>
          </div>
        </div>
      </div>
    </div>
    <viewExamination v-if="flg" :show="flg" :content="content" @toShow="toget"></viewExamination>
  </div>
</template>

<script>
import { taxChart } from "@/api/tax/index";
import viewExamination from "@/views/home/myEn/components/readComm/viewExamination.vue";
import chart from "./components/chart";

export default {
  name: "taxChart",
  components: {
    chart,
    viewExamination
  },
  data() {
    return {
      repaint: true, // 重绘
      loading: false,
      curTax: "", // 当前选中的

      picList: [], // 全部有图形的
      mainList: [], // 全部纳税主体
      view: "2", // 默认看板视图
      viewFlg: true, // 是否显示视图切换
      options: [
        { value: "1", label: "列表视图" },
        { value: "2", label: "看板视图" }
      ],
      cenloading: false,
      tableList: [], // 列表视图
      noData: false,
      pic: [],
      flg: false,
      content: {}
    };
  },
  mounted() {
    // 查询图形数据
    this.queryTaxPic();
  },
  methods: {
    // 查询全部的纳税主体
    async queryTaxMainList() {
      this.loading = true;
      let rspData = [];
      try {
        rspData = (await taxChart.queryTaxMainList({ enableFlag: "0" })) || [];
      } catch (error) {
        rspData = [];
      }
      rspData.forEach((element) => {
        element.value = element.id;
        element.label = element.name;
      });
      this.loading = false;
      this.mainList = rspData;
      this.queryUserConfig();
    },
    // 获取全部带有图形的
    async queryTaxPic() {
      this.loading = true;
      let rspData = [];
      try {
        rspData = (await taxChart.queryTaxPic({})) || [];
      } catch (error) {
        rspData = [];
      }
      rspData.forEach((element) => {
        element.value = element.id;
        element.label = element.name;
      });
      this.loading = false;
      this.picList = rspData;
      this.queryTaxMainList();
    },
    // 获取用户习惯
    async queryUserConfig() {
      const cur = (await taxChart.queryUserConfig({ configKey: "key_pic_taxId" })) || {};
      let id = "";
      if (cur.value) {
        id = cur.value;
      } else {
        id = this.mainList[0].id;
      }
      this.curTax = id;
      this.doceData();
    },
    // 保存用户习惯
    changeMain() {
      const p = {
        configKey: "key_pic_taxId",
        configValue: this.curTax
      };
      taxChart.saveUserConfig(p);
      this.doceData();
    },
    doceData() {
      this.$forceUpdate();
      this.pic = [];
      this.picList.forEach((item) => {
        if (item.taxId === this.curTax) {
          this.pic.push(item);
        }
      });
      if (this.pic.length > 0) {
        // 存在有图形数据
        this.viewFlg = true;
        this.view = "2";
        this.noData = false;
      } else {
        // 不存在没有图形数据显示列表
        this.viewFlg = false;
        this.view = "1";
        this.queryMainTaxSchemeClassify();
      }
    },
    // 查询列表
    async queryMainTaxSchemeClassify() {
      this.cenloading = true;
      let rspData = [];
      try {
        rspData = (await taxChart.queryMainTaxSchemeClassify({ taxId: this.curTax })) || [];
      } catch (error) {
        rspData = [];
      }
      if (rspData.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
      this.tableList = rspData;
      this.cenloading = false;
    },
    // 视图更改
    changeView() {
      if (this.view === "1") {
        // 列表视图
        this.queryMainTaxSchemeClassify();
      } else {
        // 看板
      }
    },
    gotoReport(item1, item) {
      const urlObj = {
        path: "/taxReport",
        query: {
          classifyRefId: item1.taxClassifyId,
          taxId: item.taxId,
          taxSchemeId: item1.classifyRefId,
          version: "1"
        }
      };
      this.$router.push(urlObj);
    },
    toget() {
      this.flg = false;
    },
    async checkScheme(item) {
      const p = {
        schemeId: item.id,
        templateId: item.checkTemplateId
      };
      let rspData = {};
      try {
        rspData = (await taxChart.checkScheme(p)) || {};
        if (JSON.stringify(rspData) === "{}") {
          // 无体检数据
        } else {
          this.flg = true;
          this.content = rspData;
        }
      } catch (error) {
        //
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tax-box {
  width: 100%;
  height: 100%;
  .tax-title {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    .txt {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
  }
  .tax-center {
    width: 100%;
    height: calc(100% - 58px);
    .en-result {
      height: 100%;
      width: 100%;
      background: #ffffff;
      border-radius: 4px;
    }
    border-radius: 4px;
    .list-wrapper {
      width: 100%;
      height: 100%;
      background: #ffffff;
      border-radius: 4px;
      padding-bottom: 30px;
      .item {
        padding: 10px 0 0 15px;
        margin-bottom: 0;
        zoom: 1;
        .item-title {
          color: #333333;
          font-size: 12px;
          padding: 10px 0 10px 0;
          font-weight: bold;
          display: flex;
          align-items: center;
          .en-icon {
            cursor: pointer;
          }
        }
        /deep/ .en-card-normal {
          &:hover {
            background: #f5f9fd;
          }
        }
        .btn {
          margin: 0 10px 10px 0;
          float: left;
          &/deep/.en-card-normal-main {
            display: flex;
            align-items: center;
          }
        }
        &::after {
          content: "";
          display: block;
          clear: both;
        }
      }
    }
    .tax-chart {
      overflow: auto;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 448px;
        width: 50%;
        padding-bottom: 8px;
        .item-cen {
          height: 440px;
          width: 100%;
          background: #ffffff;
          border-radius: 4px;
        }
        // 奇数
        &:nth-child(odd) {
          padding-right: 4px;
        }
        // 偶数
        &:nth-child(even) {
          padding-left: 4px;
        }
      }
    }
  }
}
</style>
