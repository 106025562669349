<!--
 * @Author: pengyu
 * @Date: 2019-10-09 17:09:36
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-02-01 15:58:16
 * @Description: 新增编辑银行卡
 -->
<template>
  <en-dialog title="新增银行信息" :visible="visible" width="900px" :append-to-body="true" :close-on-click-modal="false" class="bank-alert" @close="visible = false">
    <el-form ref="bankForm" :model="bankForm" :rules="formRules" label-width="78px" label-position="left">
      <el-form-item label="账户名称" prop="accountName">
        <el-input v-model="bankForm.accountName" maxlength="20" placeholder="请输入" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="开户银行" prop="bankId">
        <el-select v-model="bankForm.bankId" placeholder="请选择" filterable @change="getBankName">
          <el-option v-for="(item, index) in bankdata" :key="index" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户城市" prop="cityCode">
        <el-select v-model="bankForm.cityCode" placeholder="请选择" @change="selectCity" filterable>
          <el-option v-for="(item, index) in citydata" :key="index" :label="item.name" :value="item.code"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户支行" prop="bankCode">
        <el-select v-model="bankForm.bankCode" placeholder="请选择" filterable @change="getbankAddr">
          <el-option v-for="(item, index) in branchdata" :key="index" :label="item.bankName" :value="item.bankCode"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="bankAccountNo">
        <!--bug37093-->
        <div slot="label" style="display:inline">
          <span style="margin-right:24px">账</span>
          <span>号</span>
       </div >
       <el-input v-model="bankForm.bankAccountNo" maxlength="64" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否默认" prop="isDefault">
        <el-switch v-model="bankForm.isDefault" active-color="#66ABF9" active-value="000" inactive-value="001"> </el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click="submitForm('bankForm')">确定</en-button>
    </span>
  </en-dialog>
</template>

<script>
import { settingService } from "@/api";
import basedata from "../../basedata";

export default {
  name: "AddCard",
  components: {},
  props: {
    isEditBank: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bankdata: [], // 银行数据
      citydata: [], // 城市数据
      branchdata: [], // 支行  根据选择城市查询
      visible: false,
      bankForm: {
        id: "",
        bankAddr: "",
        bankName: "",
        accountName: "", // 账户名称 为当前登陆人
        bankCode: "",
        bankId: "",
        bankAccountNo: "",
        city: "",
        cityCode: "",
        isDefault: ""
      },
      formRules: {
        accountName: [
          {
            required: true,
            message: "请输入账户名称",
            trigger: "change"
          }
        ],
        bankId: [
          {
            required: true,
            message: "请选择开户银行",
            trigger: "blur"
          }
        ],
        cityCode: [
          {
            required: true,
            message: "请选择开户城市",
            trigger: "blur"
          }
        ],
        bankCode: [
          {
            required: true,
            message: "请选择开户支行",
            trigger: "blur"
          }
        ],
        bankAccountNo: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {
    visible(newval) {
      if (newval) {
        if (this.isEditBank) {
          // 编辑
          // this.branchdata = [];
          const pa = { bankTypeCode: this.bankForm.bankId, cityCode: this.bankForm.cityCode };
          this.queryInfo(pa);
        } else {
          // 新增
          this.resetForm();
        }
      }
    }
  },
  mounted() {
    // 赋值 银行数据 城市数据
    this.bankdata = basedata.bank;
    this.citydata = basedata.city;
  },
  methods: {
    /**
     * @description: 查询支行
     * @param {type}
     * @return:
     */
    async queryInfo(pa) {
      const rsp = await settingService.queryBankInfoList(pa);
      this.branchdata = rsp;
    },
    /**
     * @description: 根据选择城市 获取当前城市的支行
     * @param {type}
     * @return:
     */
    selectCity() {
      const pa = { bankTypeCode: this.bankForm.bankId, cityCode: this.bankForm.cityCode };
      this.queryInfo(pa);
    },
    /**
     * @description: 提交确定
     * @return:
     */
    async submitForm() {
      this.visible = false;
      this.$refs.bankForm.validate((valid) => {
        if (valid) {
          if (!this.isEditBank) {
            this.bankForm.id = "";
          }
          this.setBankInfo({ userBankMap: JSON.stringify(this.bankForm) });
        }
        return false;
      });
    },
    /**
     * @description: 获取银行名称
     * @return:
     */
    getBankName(val) {
      let obj = {};
      obj = this.bankdata.find((item) => item.id === val);
      this.bankForm.bankName = obj.name;
    },
    /**
     * @description: 获取支行名称
     * @param {}
     * @return:
     */
    getbankAddr(val) {
      let obj = {};
      obj = this.branchdata.find((item) => item.bankCode === val);
      this.bankForm.bankAddr = obj.bankName;
    },
    /**
     * @description: 提交数据
     * @param {}
     * @return:
     */
    async setBankInfo(data) {
      await settingService.setBankInfo(data);
      this.visible = false;
      this.$message({
        type: "success",
        message: this.bankForm.id?.length > 0 ? "保存成功" : "新增银行卡成功"
      });
      this.$parent.queryInfo();
    },
    /**
     * @description: 重置表单数据
     * @param {type}
     * @return:
     */
    resetForm() {
      this.$refs.bankForm.resetFields();
    }
  }
};
</script>
<style lang="scss" scoped>
.el-form-item{
  margin-bottom:16px;
}
.bank-alert {
  /deep/ .el-select {
    width: 100%;
  }
}
// /deep/ .el-form .el-form-item__label{
//   text-align: justify;
//   -moz-text-align-last: justify;
//   text-align-last: justify;
// }
</style>
