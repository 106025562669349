<!--
 * @Author: pengyu
 * @Date: 2019-11-19 14:16:51
 * @LastEditors: zhulin
 * @LastEditTime: 2021-06-11 15:19:37
 * @Description: 授权给我
 -->
<template>
  <div class="container">
    <en-table :data="list" border :hover-row-config="{ dataMethod: buttonData, changeMethod: buttonClick }" height="100%">
      <en-table-column label="序号" type="index" width="80"> </en-table-column>
      <en-table-column prop="userName" label="授权人员"> </en-table-column>
      <en-table-column prop="startTime" label="生效日期"> </en-table-column>
      <en-table-column prop="endTime" label="失效日期"> </en-table-column>
      <en-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <div class="status-mod" :style="formatStyle(scope.row.status)">
            {{ formatStatus(scope.row.status) }}
          </div>
        </template>
      </en-table-column>
      <!-- 悬浮操作列 -->
      <!-- <template v-slot:hoverRowRight="scope" class="oper-row">
        <span>
          <el-tooltip v-if="scope.row.status !== 4" content="登录">
            <en-icon name="denglu-liebiao" size="small" color="#3e90fe" @click.native.stop="loginConfirm(scope.row)"> </en-icon>
          </el-tooltip>
        </span>
      </template> -->
    </en-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { loginService, publicService } from "@/api";
import API from "@/api/homeMenu";

export default {
  name: "AuthForMe",
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return { tableData: [], loginData: {} };
  },
  mounted() {
    this.tableData = this.list;
  },
  computed: {
    ...mapGetters(["loginAccount"])
  },
  methods: {
    ...mapActions(["login", "logout", "updateUserInfo", "saveUrlParam", "saveLoginAccount"]),
    ...mapMutations("main", ["setMenuData"]),

    /**
     * @description
     * @param 表格操作列按钮
     */
    buttonData({ row }) {
      let btnList = [];
      if (row.status === 2) {
        btnList = [
          {
            icon: "iconfuzhi",
            id: "btn-denglu",
            name: "立即登录",
            iconColor: "#49bef2"
          }
        ];
      }
      return btnList;
    },
    /**
     * @description
     * @param 表格操作列按钮事件
     */
    buttonClick({ row, index, btnData }) {
      switch (btnData.id) {
        case "btn-denglu":
          // 立即登录
          this.loginConfirm(row, index);
          break;
        default:
          break;
      }
    },
    /**
     * @description: 状态字段转化
     */
    formatStatus(status) {
      switch (status) {
        case 2:
          return "生效中";
        case 3:
          return "未生效";
        case 4:
          return "已失效";
        default:
          return "";
      }
    },
    /**
     * @description: 状态块块样式
     */
    formatStyle(status) {
      switch (status) {
        case 2:
          return { background: "#FFF7EA", color: "#FFAD2B" };
        case 3:
          return { background: "#EDF3F9", color: "#3e90fe" };
        case 4:
          return { background: "#EFF0F2", color: "#636C7B" };
        default:
          return "";
      }
    },
    async loginService(row) {
      this.loginData = {
        username: row.loginAccount,
        account: row.loginAccount,
        password: row.authPassword,
        oldUserId: row.authUser,
        machineType: "PC"
      };
      const rsp = await loginService.login(this.loginData);
      this.updateUserInfo(rsp);
      const urlConfig = await publicService.getUploadUrl();
      this.saveUrlParam(urlConfig);
      const data = await API.getMenu();
      this.setMenuData(data);
      this.saveLoginAccount(row.loginAccount);
      // 需要调取登录存用户信息
      this.$router.push({ path: "/home" });
      // 顶部右侧个人信息未刷新手动刷新
      window.location.reload();
    },
    /**
     * @description: 登录
     */
    loginConfirm(row) {
      this.$confirm("确定立即登录?", "", {
        confirmButtonText: "确定",
        showCancelButton: false,
        type: "info"
      }).then(() => {
        this.loginService(row);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  .en-icon {
    margin: 0 5px;
    cursor: pointer;
    &:last-child {
      margin-right: 20px;
    }
  }
  .status-mod {
    width: 62px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    border-radius: 4px;
  }
}
</style>
