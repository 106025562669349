<!--
 * @Author: pengyu
 * @Date: 2019-10-12 11:41:04
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-16 16:58:25
 * @Description: 个人设置-偏好设置-新增提醒弹框
 -->
<template>
  <el-dialog title="设置提醒" :visible.sync="show" width="900px" :append-to-body="true" :close-on-click-modal="false" @close="closeDialog" class="notice-alert">
    <el-form ref="nForm" :model="noticeForm" :rules="formRules" label-width="70px" label-position="left">
      <el-form-item label="提醒事项" prop="taskType">
        <en-select v-model="noticeForm.taskType" placeholder="请选择" :props="defaultProps" :data="taskTypeData" @change="onChangeType"> </en-select>
      </el-form-item>
      <!-- 待办任务 -->
      <div v-if="noticeForm.taskType === 0">
        <el-form-item label="提醒时间" prop="remindTime">
          <el-select v-model="noticeForm.remindTime" placeholder="请选择">
            <el-option v-for="item in timeData" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="重复周期" prop="">
          <el-checkbox v-model="noticeForm.mon" label="星期一"></el-checkbox>
          <el-checkbox v-model="noticeForm.tue" label="星期二"></el-checkbox>
          <el-checkbox v-model="noticeForm.wed" label="星期三"></el-checkbox>
          <el-checkbox v-model="noticeForm.thu" label="星期四"></el-checkbox>
          <el-checkbox v-model="noticeForm.fri" label="星期五"></el-checkbox>
          <el-checkbox v-model="noticeForm.sat" label="星期六"></el-checkbox>
          <el-checkbox v-model="noticeForm.sun" label="星期日"></el-checkbox>
        </el-form-item>
      </div>
      <!-- 订单行程 -->
      <div v-else-if="noticeForm.taskType === 1">
        <el-form-item label="资源类型" prop="travelType">
          <el-checkbox-group v-model="noticeForm.travelType">
            <el-checkbox v-for="t in travelTypeOption" :key="t.id" :label="t.id">
              {{ t.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="触发时间" prop="">
          <div class="remind-dingdan">
            <div>订单行程执行前</div>
            <el-input v-model="noticeForm.remindTime" v-popover:popover :readonly="true" class="ipt-time"></el-input>
            <div>小时</div>
            <!-- <el-button v-popover:popover1>小时</el-button> -->
            <el-popover ref="popover" placement="bottom-start" width="395" trigger="click">
              <div class="hour-panel">
                <div v-for="item in hour" :key="item" class="hour-item" :class="{ active: item === noticeForm.remindTime }" @click="clickHour(item)">
                  {{ item }}
                </div>
              </div>
            </el-popover>
          </div>
        </el-form-item>
      </div>
      <!-- 行程报销 -->
      <div v-else-if="noticeForm.taskType === 2">
        <el-form-item label="提醒时间" prop="">
          <div class="remind-time">
            <div>可报销的行程</div>
            <el-select v-model="noticeForm.reimbursementType" placeholder="请选择" class="sel-num">
              <el-option label="天数" value="1"> </el-option>
              <el-option label="金额" value="2"> </el-option>
            </el-select>
            <div>超过</div>
            <el-input placeholder="请输入" class="sel-unit" v-model="noticeForm.remindTime">
              <span slot="suffix">{{ noticeForm.reimbursementType === "1" ? "天" : "元" }}</span>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="重复周期" prop="">
          <el-checkbox v-model="noticeForm.mon" label="每周一"></el-checkbox>
          <el-checkbox v-model="noticeForm.tue" label="每周二"></el-checkbox>
          <el-checkbox v-model="noticeForm.wed" label="每周三"></el-checkbox>
          <el-checkbox v-model="noticeForm.thu" label="每周四"></el-checkbox>
          <el-checkbox v-model="noticeForm.fri" label="每周五"></el-checkbox>
          <el-checkbox v-model="noticeForm.sat" label="每周六"></el-checkbox>
          <el-checkbox v-model="noticeForm.sun" label="每周日"></el-checkbox>
        </el-form-item>
      </div>
      <!-- 考勤提醒 -->
      <div v-else-if="noticeForm.taskType === 3">
        <el-form-item label="提醒类型" prop="businessType">
          <el-select v-model="noticeForm.businessType" placeholder="请选择">
            <el-option label="上班提醒" value="1"> </el-option>
            <el-option label="下班提醒" value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提醒时间" prop="remindTime">
          {{ noticeForm.businessType === "1" ? "上班前" : "下班后" }}
          <el-select v-model="noticeForm.remindTime" placeholder="请选择" class="ipt-time">
            <el-option v-for="item in minData" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
          分钟
        </el-form-item>
      </div>
      <!-- 任务提醒 -->
      <div v-else-if="noticeForm.taskType === 4">
        <el-form-item label="提醒时间" prop="remindTime">
          <div class="remind-task">
            <div>任务截止前</div>
            <el-select v-model="noticeForm.remindTime" placeholder="请选择" class="sel-time">
              <el-option v-for="item in minData2" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
            <el-select v-model="noticeForm.remindUnit" placeholder="请选择" class="sel-unit2">
              <el-option v-for="item in unitData" :key="item.value" :label="item.text" :value="item.value"> </el-option>
            </el-select>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click="submitForm('nform')">确 定</en-button>
    </span>
  </el-dialog>
</template>

<script>
import { preferenceService } from "@/api";

export default {
  name: "AddNotice",
  components: {},
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    noticeData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      defaultProps: {
        label: "name",
        value: "id"
      },
      // 提醒类型
      taskTypeData: [
        { id: 0, name: "待办任务" },
        { id: 1, name: "订单行程" },
        { id: 2, name: "行程报销" },
        { id: 3, name: "考勤提醒" },
        { id: 4, name: "任务提醒" }
      ],
      travelTypeOption: [
        { id: 0, name: "机票" },
        { id: 1, name: "酒店" },
        { id: 2, name: "用车" }
      ],
      // 提醒时间  选项
      timeData: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "24:00"
      ],
      // 上下班时间提醒、
      minData: ["5", "10", "20", "30", "40", "50", "60"],
      minData2: ["5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"],
      hour: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      day: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      unitData: [
        { value: "1", text: "分" },
        { value: "2", text: "小时" },
        { value: "3", text: "天" }
      ],
      editForm: {},
      travelType: {},
      formRules: {
        taskType: [
          {
            required: true,
            message: "请选择提醒事项",
            trigger: "blur"
          }
        ],
        remindTime: [
          {
            required: true,
            message: "请设置提醒时间",
            trigger: "blur"
          }
        ],
        businessType: [
          {
            required: true,
            message: "请设置提醒类型",
            trigger: "blur"
          }
        ],
        travelType: [
          {
            required: true,
            message: "请设置资源类型",
            trigger: "blur"
          }
        ]
      },
      param: {},
      noticeForm: {}
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.noticeForm = JSON.parse(JSON.stringify(this.noticeData));
        if (this.isEdit) {
          this.switchData(this.noticeForm);
        }
      }
    }
  },
  methods: {
    /**
     * @description: 转换数据
     * @param {type}
     * @return:
     */
    switchData(list) {
      list.mon = Number(list.mon) === 1;
      list.tue = Number(list.tue) === 1;
      list.wed = Number(list.wed) === 1;
      list.thu = Number(list.thu) === 1;
      list.fri = Number(list.fri) === 1;
      list.sat = Number(list.sat) === 1;
      list.sun = Number(list.sun) === 1;
      switch (parseInt(list.taskType, 10)) {
        case 0:
          // 待办任务
          list.remindTime = `${list.remindTime}:00`;
          break;
        case 1:
          // 订单行程
          list.travelType = list.travelType.split("_").map(Number);
          break;
        case 2:
          // 行程报销
          list.reimbursementType = Number(list.reimbursementType) === 1 ? "天数" : "金额";
          break;
        case 3:
          // 考勤提醒
          list.businessType = Number(list.businessType) === 1 ? "上班提醒" : "下班提醒";
          break;
        default:
      }
    },
    /**
     * @description: 关闭弹窗回调
     * @param {type}
     * @return:
     */
    closeDialog() {
      this.$emit("toShow", false);
    },
    /**
     * @description: 改变type事件
     * @return:
     */
    onChangeType(val) {
      const type = val;
      this.noticeForm = JSON.parse(JSON.stringify(this.noticeData));
      this.noticeForm.taskType = type;
      if (this.isEdit) {
        this.switchData(this.noticeForm);
      }
    },
    /**
     * @description: 提交确认
     * @param {type}
     * @return:
     */
    async submitForm() {
      const list = this.noticeForm;
      switch (parseInt(list.taskType, 10)) {
        case 0:
          // 待办任务
          this.param = {
            taskType: list.taskType,
            remindTime: list.remindTime.replace(":00", ""),
            mon: list.mon ? "1" : "0",
            tue: list.tue ? "1" : "0",
            wed: list.wed ? "1" : "0",
            thu: list.thu ? "1" : "0",
            fri: list.fri ? "1" : "0",
            sat: list.sat ? "1" : "0",
            sun: list.sun ? "1" : "0"
          };
          break;
        case 1:
          // 订单行程
          this.param = {
            taskType: list.taskType,
            travelType: list.travelType.join("_"),
            remindTime: list.remindTime
          };
          break;
        case 2:
          // 行程报销
          this.param = {
            taskType: list.taskType,
            reimbursementType: list.reimbursementType === "天数" ? 1 : 2,
            remindTime: list.remindTime,
            mon: list.mon ? "1" : "0",
            tue: list.tue ? "1" : "0",
            wed: list.wed ? "1" : "0",
            thu: list.thu ? "1" : "0",
            fri: list.fri ? "1" : "0",
            sat: list.sat ? "1" : "0",
            sun: list.sun ? "1" : "0"
          };
          break;
        case 3:
          // 考勤提醒
          this.param = {
            taskType: list.taskType,
            businessType: list.businessType,
            remindTime: list.remindTime
          };
          break;
        case 4:
          if (list.remindTime.length === 0) {
            this.$message("请选择时间数值");
            return;
          }
          if (list.remindUnit.length === 0) {
            this.$message("请选择时间单位");
            return;
          }
          // 任务提醒
          this.param = {
            taskType: list.taskType,
            remindUnit: list.remindUnit,
            remindTime: list.remindTime
          };
          break;
        default:
      }
      if (this.isEdit) {
        this.param.id = this.noticeForm.id;
      }
      await preferenceService.addRemindConfig(this.param);
      console.log("this.param", this.param);
      this.closeDialog();
      this.$parent.queryList();
    },
    /**
     * @description: 保存偏好设置
     * @param {value}
     * @param {type}
     */
    async addRemindConfig(pa) {
      await preferenceService.addRemindConfig(pa);
    },
    /**
     * @description: 订单行程-触发时间点击
     * @param {type}
     * @return:
     */
    clickHour(item) {
      this.noticeForm.remindTime = item;
      this.$refs.popover.showPopper = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.notice-alert {
  .el-form {
    min-height: 280px;
  }
  /deep/ .el-select {
    width: 100%;
  }
  .remind-dingdan {
    display: flex;
  }

  .ipt-time {
    width: 170px;
    margin: 0 10px;
  }
  .remind-time {
    display: flex;
    .sel-num {
      flex: 1;
      margin: 0 10px;
    }
    .sel-unit {
      flex: 1;
      margin-left: 10px;
      > span {
        margin-right: 5px;
      }
    }
  }
  .remind-task {
    display: flex;
    .sel-time,
    .sel-unit2 {
      flex: 1;
    }
    .sel-time {
      margin: 0 10px;
    }
  }
}

.hour-panel {
  width: 100%;
  height: 190px;
  .hour-item {
    display: inline-block;
    width: 40px;
    height: 30px;
    background-color: #f5f8fd;
    border: 1px solid #e6edf2;
    border-radius: 3px;
    line-height: 30px;
    text-align: center;
    margin: 3px;
    &.active {
      background-color: #3e90fe;
      color: #fff;
    }
    &:nth-child(8n) {
      margin-right: 0;
    }
  }
}
</style>
