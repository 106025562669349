var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fullScreen
        ? _c("router-view")
        : _c(
            "div",
            { staticClass: "half-screen" },
            [
              _c("top-nav"),
              _c("div", { staticClass: "content" }, [_c("router-view")], 1),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }