var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    this.showLeftMenu != "/setting/organization" &&
    this.showLeftMenu != "/setting/organization/organizationDetail"
      ? _c(
          "div",
          { staticClass: "left-menu" },
          _vm._l(_vm.menuList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item",
                class: _vm.$route.path === item.path ? "cur" : "",
                on: {
                  click: function ($event) {
                    return _vm.clickMenu(item.path)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          0
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "full-box",
        class: {
          "cent-box":
            this.showLeftMenu != "/setting/organization" &&
            this.showLeftMenu != "/setting/organization/organizationDetail",
        },
      },
      [_c("transition", [_c("router-view", { staticClass: "full-page" })], 1)],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }