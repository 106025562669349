var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.authorLoading,
          expression: "authorLoading",
        },
      ],
      staticClass: "authorize",
    },
    [
      _c("en-tabs", {
        attrs: { list: _vm.tabList, type: "white" },
        on: { change: _vm.changeTabs },
        model: {
          value: _vm.activeTab,
          callback: function ($$v) {
            _vm.activeTab = $$v
          },
          expression: "activeTab",
        },
      }),
      _vm.activeTab === "first"
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "title-area" },
              [
                _c("en-select", {
                  attrs: { placeholder: "请选择", data: _vm.options },
                  on: { change: _vm.changeOptions },
                  model: {
                    value: _vm.value1,
                    callback: function ($$v) {
                      _vm.value1 = $$v
                    },
                    expression: "value1",
                  },
                }),
                _c(
                  "en-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.value1 === 1,
                        expression: "value1 === 1",
                      },
                    ],
                    attrs: { icon: "tianjia-anniutoubu", type: "primary" },
                    on: { click: _vm.addLoginAutho },
                  },
                  [_vm._v(" 添加 ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "grid-area" },
              [
                _vm.value1 === 1
                  ? _c("myAuthList", { attrs: { list: _vm.tableData1 } })
                  : _c("auth-for-me-list", { attrs: { list: _vm.tableData2 } }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "grid-bottom" },
              [
                _c("en-pagination", {
                  attrs: {
                    "page-model":
                      _vm.value1 === 1 ? _vm.pageModel1 : _vm.pageModel2,
                  },
                  on: { change: _vm.handlePageChanged },
                }),
              ],
              1
            ),
          ])
        : _vm.activeTab === "second"
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "title-area" },
              [
                _c(
                  "el-checkbox",
                  {
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  },
                  [_vm._v(" 审批授权期间，仍显示待办任务提醒 ")]
                ),
                _c(
                  "en-button",
                  {
                    attrs: { icon: "tianjia-anniutoubu", type: "primary" },
                    on: { click: _vm.addApproveAutho },
                  },
                  [_vm._v(" 添加 ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "grid-area" },
              [_c("approvalAuthList", { attrs: { list: _vm.tableData3 } })],
              1
            ),
            _c(
              "div",
              { staticClass: "grid-bottom" },
              [
                _c("en-pagination", {
                  attrs: { "page-model": _vm.pageModel3 },
                  on: { change: _vm.handlePageChanged3 },
                }),
              ],
              1
            ),
          ])
        : _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "title-area" },
              [
                _c(
                  "el-checkbox",
                  {
                    on: { change: _vm.saveUserConfig },
                    model: {
                      value: _vm.checkedSameScreen,
                      callback: function ($$v) {
                        _vm.checkedSameScreen = $$v
                      },
                      expression: "checkedSameScreen",
                    },
                  },
                  [_vm._v(" 同意接受其他用户端的“同屏指令” ")]
                ),
              ],
              1
            ),
          ]),
      _c("popLog", { ref: "logAlert", attrs: { logData: _vm.logData } }),
      _c("loginAuthorize", { ref: "loginAlert" }),
      _c("approvalAuthorize", { ref: "approveAlert" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }