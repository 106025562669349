<!--
 * @Author: 周晴龙
 * @Date: 2020-07-10 10:30:08
 * @LastEditTime: 2024-02-29 19:27:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\setting\securitySetting\alert\updateTell.vue
-->
<template>
 <el-dialog title="修改手机号" :visible.sync="dialogVisible" width="700px" :append-to-body="true" :close-on-click-modal="false" class="oldphone-alert">
    <div class="step-wrap">
      <en-step-list :step-options="stepOptions" :current-step="step" />
    </div>
    <div class="step-one" v-if="step===1">
      <el-form ref="oldPhoneForm" :model="oldPhoneForm" :rules="oldRules" class="edit-form">
        <el-form-item label="" prop="">
          <el-input :value="desensValue" maxlength="20" placeholder="请输入新手机号" readonly="readonly" disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="" prop="validateCode" class="item-code">
          <el-input maxlength="20" placeholder="请输入验证码"  v-model="oldPhoneForm.validateCode"></el-input>
          <el-button size="small" :disabled="buttonDisabled" @click="getAuthCode(value)">
            {{ buttonStatus }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="step-two" v-if="step===2">
      <el-form ref="newPhoneForm" :model="newPhoneForm" :rules="rules" class="edit-form">
        <el-form-item label="" prop="phoneNum">
          <el-input  maxlength="11" placeholder="请输入新手机号" v-model="newPhoneForm.phoneNum" type="tel"></el-input>
        </el-form-item>
        <el-form-item label="" prop="validateCode" class="item-code">
          <el-input maxlength="20" placeholder="请输入验证码"  v-model="newPhoneForm.validateCode"></el-input>
          <el-button  size="small" :disabled="buttonDisabled" @click="newPhoneCode">
            {{ buttonStatus }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <en-button  @click="submitForm('oldPhoneForm')" v-if="step===1">下一步</en-button>
       <en-button  @click="submitNewForm('newPhoneForm')" v-if="step===2">确 定</en-button>
    </span>
 </el-dialog>
</template>

<script>
import { hideCardNo, isMobile } from "en-js";
import { settingService } from "@/api";

export default {
  name: "updatePhone",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dialogVisible: false,
      stepOptions: ["验证原手机号", "验证新手机号"],
      step: 1, // 当前第几步骤
      oldPhoneForm: { validateCode: "" },
      oldRules: {
        validateCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ]
      },
      newPhoneForm: { phoneNum: "", validateCode: "" },
      rules: {
        phoneNum: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur"
          }
        ],
        validateCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ]
      },
      buttonStatus: "获取验证码",
      buttonDisabled: false,
      desensValue: "" // 脱敏后的显示值
    };
  },
  watch: {
    dialogVisible(newval) {
      console.log(newval);
      if (newval) {
        this.buttonDisabled = false;
        this.buttonStatus = "获取验证码";
        this.desensValue = hideCardNo(this.value, 3);
      } else if (this.step === 1) {
        this.resetForm("oldPhoneForm");
      } else {
        this.resetForm("newPhoneForm");
      }
    },
    step() {
      if (this.time) {
        clearInterval(this.time);
        this.buttonStatus = "获取验证码";
      }
    }

  },
  methods: {
    // 获取验证码
    async getValidateCode(value) {
      try {
        this.loading = true;
        const res = await settingService.sendValidateCode({
          mobileNo: value
        });
        console.log(res);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 验证验证码
    async checkValidateCode(item) {
      try {
        this.loading = true;
        const res = await settingService.checkValidateCode({
          mobileNo: item.value,
          userName: item.value,
          validateCode: item.validateCode
        });
        console.log(res);
        this.$message("提交成功");
        this.dialogVisible = false;
      } catch (e) {
        this.dialogVisible = true;
        throw e;
      } finally {
        this.loading = false;
      }
    },
    // 绑定新手机号
    async bindMobile(item) {
      try {
        this.loading = true;
        const res = await settingService.forgetMobile({
          newMobileNo: item.phoneNum,
          oldMobileNo: this.value,
          userName: item.phoneNum,
          validateCode: item.validateCode
        });
        console.log(res);
        this.$message("提交成功");
        this.dialogVisible = false;
      } catch (e) {
        console.error(e);
        this.dialogVisible = true;
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description: 获取验证码
     */
    getAuthCode(value) {
      let tt = 60;
      const _this = this;
      _this.buttonDisabled = true;
      this.getValidateCode(value);
      const time = setInterval(() => {
        tt--;
        if (tt === 0) {
          _this.buttonStatus = "获取验证码";
          _this.buttonDisabled = false;
          clearInterval(time);
        } else {
          _this.buttonStatus = `重新获取(${tt})`;
        }
      }, 1000);
      _this.time = time;
    },
    /**
     * @description: 新手机号获取验证码
     */
    newPhoneCode() {
      // 手机号校验
      const phoneNum = this.newPhoneFrom.phoneNum;
      console.log(isMobile(phoneNum));
      if (isMobile(phoneNum) === true) {
        this.newPhoneFrom.phoneNum = phoneNum;
        this.getAuthCode(phoneNum);
      } else {
        this.$message("请输入正确的手机号");
      }
    },
    // 重置表单数据
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /**
     * @description: 提交旧手机号
     * @param {type}
     */
    submitForm() {
      this.validateCode = this.oldPhoneForm.validateCode;
      const item = { validateCode: this.validateCode, value: this.value };
      this.checkValidateCode(item).then(() => {
        this.step = 2;
      });
    },
    /**
     * @description: 提交新手机号
     * @param {type}
     */
    submitNewForm() {
      if (!this.newPhoneForm.phoneNum || !this.newPhoneForm.validateCode) {
        this.$message("手机号和验证码不能为空");
      } else {
        const item = { validateCode: this.newPhoneForm.validateCode, value: this.newPhoneForm.phoneNum };
        this.checkValidateCode(item).then(() => {
          this.bindMobile(item);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .oldphone-alert {
    .el-input /deep/ .el-input__inner {
      height: 40px;
      line-height: 40px;
    }
    .el-form-item__content .el-button {
      color: #fff;
      background: #3e90fe;
      padding: 12px 15px;
      border:none;
    }
    .el-form-item__content .el-button:hover {
      background: #5a9ffc;
    }
    .el-form-item__content .el-button:active {
      background: #2176e8;
    }
    // .is-disabled{
    //   background: rgba(62,144,254,0.5)!important;
    // }
    /deep/.en-button--blue.en-btn-normal{
      color: #fff;
      background: #3e90fe;
      padding: 12px 15px;
    }
    /deep/.en-button--blue.en-btn-normal:hover{
      background: #5a9ffc!important;
    }
    /deep/.en-button--blue.en-btn-normal:active {
      background: #2176e8!important;
    }
    .step-wrap {
      /deep/ .en-setp-list {
        margin: 30px auto;
      }
    }
    .edit-form{
      text-align: center;
      /deep/ .el-form-item {
        width: 462px;
        height: 40px;
        margin: 20px auto;
        &.item-code {
          .el-form-item__content {
            width: 462px;
            display: flex;
            justify-content: space-between;
            color: #fff;
            .el-input {
              width: 354px;
              float: left;
            }
            /deep/ .el-button {
              float: right;
            }
          }
          .el-form-item__content::after{
            content: none;
          }
          .el-form-item__content::before{
            content: none;
          }
        }
        .en-select {
          width: 100%;
        }
        /deep/ .el-input__inner {
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }
</style>
