var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.loadingData,
          expression: "loadingData",
        },
      ],
      staticClass: "basic",
    },
    [
      _c("en-title-card", { attrs: { name: "基本信息" } }),
      _c(
        "div",
        { staticClass: "container", class: { editCont: _vm.isEditBasic } },
        [
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "user-info" }, [
              !_vm.isEditBasic
                ? _c("div", { staticClass: "info-list" }, [
                    _c("div", { staticClass: "top" }, [
                      _c(
                        "div",
                        { staticClass: "header" },
                        [
                          _vm.param.userLogo
                            ? _c("img", {
                                attrs: {
                                  src: _vm.param.userLogo,
                                  alt: "",
                                  width: "100%",
                                  height: "100%",
                                },
                              })
                            : _c("en-user-logo", {
                                attrs: {
                                  "user-name": _vm.param.name,
                                  size: "90",
                                },
                              }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "name" }, [
                        _c(
                          "div",
                          { staticClass: "name-row" },
                          [
                            _c("span", { staticClass: "main-txts" }, [
                              _vm._v(_vm._s(_vm.param.name)),
                            ]),
                            _vm.param.gender
                              ? _c("en-icon", {
                                  class:
                                    _vm.param.gender === "M" ? "nan" : "nv",
                                  attrs: {
                                    name:
                                      _vm.param.gender === "M"
                                        ? "iconnansheng"
                                        : "iconnvsheng",
                                    size: "18",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "name-row" }, [
                          _c("span", { staticClass: "txts" }, [
                            _vm._v(_vm._s(_vm.param.tenantName)),
                          ]),
                          _c("span", { staticClass: "txts" }, [
                            _vm._v(_vm._s(_vm.param.post)),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "organization-txts",
                              on: { click: _vm.clickOrganization },
                            },
                            [_vm._v("组织架构")]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "edit" }, [
                        _c(
                          "div",
                          {
                            staticClass: "edit-btn",
                            on: { click: _vm.editBasic },
                          },
                          [
                            _c("en-icon", {
                              attrs: { name: "bianji1", size: "small" },
                            }),
                            _c("span", [_vm._v("编辑资料")]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "grid" }, [
                      _vm.param.no
                        ? _c("dl", { staticClass: "items" }, [
                            _c("dt", { staticClass: "tit" }, [_vm._v("工号")]),
                            _c("dd", { staticClass: "cents" }, [
                              _vm._v(_vm._s(_vm.param.no)),
                            ]),
                          ])
                        : _vm._e(),
                      _c("dl", { staticClass: "items" }, [
                        _c("dt", { staticClass: "tit" }, [_vm._v("部门")]),
                        _c("dd", { staticClass: "cents" }, [
                          _c("div", { staticClass: "dept-td" }, [
                            _c(
                              "div",
                              {
                                staticClass: "dept-top",
                                staticStyle: { "margin-top": "-1px" },
                              },
                              [
                                _c("span", { staticClass: "txt" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.defaulPost[0]
                                        ? _vm.defaulPost[0].deptText
                                        : ""
                                    )
                                  ),
                                ]),
                                _c("en-icon", {
                                  staticStyle: { "margin-top": "-3px" },
                                  attrs: {
                                    name: "jiangang-gongsiquan",
                                    size: "small",
                                    color: "#3e90fe",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.activePostData.length
                              ? _c(
                                  "div",
                                  { staticClass: "jiangang" },
                                  _vm._l(_vm.activePostData, function (i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i.id,
                                        staticClass: "jiangang-card",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "icons" },
                                          [
                                            _c("en-icon", {
                                              attrs: {
                                                name: "renyuan-gongsiquan",
                                                size: "18px",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("span", { staticClass: "txt1" }, [
                                          _vm._v(
                                            _vm._s(
                                              i.defaultDept === "001"
                                                ? "兼岗"
                                                : "借调"
                                            )
                                          ),
                                        ]),
                                        _c("span", { staticClass: "txt2" }, [
                                          _vm._v(_vm._s(i.postText)),
                                        ]),
                                        _c("span", { staticClass: "txt3" }, [
                                          _vm._v(_vm._s(i.deptText)),
                                        ]),
                                        _c("span", { staticClass: "txt4" }, [
                                          _vm._v(_vm._s(_vm.param.name)),
                                        ]),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _c("dl", { staticClass: "items" }, [
                        _c("dt", { staticClass: "tit" }, [_vm._v("手机")]),
                        _c("dd", { staticClass: "cents" }, [
                          _vm._v(_vm._s(_vm.hideCardNo(_vm.param.mobileNo, 3))),
                        ]),
                      ]),
                      _c("dl", { staticClass: "items" }, [
                        _c("dt", { staticClass: "tit" }, [_vm._v("邮箱")]),
                        _c("dd", { staticClass: "cents" }, [
                          _vm._v(_vm._s(_vm.hideCardNo(_vm.param.email, 4))),
                        ]),
                      ]),
                      _c("dl", { staticClass: "items" }, [
                        _c("dt", { staticClass: "tit" }, [_vm._v("证件")]),
                        _c("dd", { staticClass: "cents" }, [
                          _c("div", { staticClass: "zhengjian" }, [
                            !_vm.isEditBasic
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("getCardType")(
                                        _vm.param.idcardType
                                      )
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm.hideCardNo(
                                          _vm.param.idcardNo,
                                          _vm.param.idcardType
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _vm.param.birthDay
                        ? _c("dl", { staticClass: "items" }, [
                            _c("dt", { staticClass: "tit" }, [_vm._v("生日")]),
                            _c("dd", { staticClass: "cents" }, [
                              _c("div", { staticClass: "zhengjian" }, [
                                _vm._v(" " + _vm._s(_vm.param.birthDay) + " "),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _c("div", { staticClass: "edit-list" }, [
                    _c("div", { staticClass: "top" }, [
                      _c(
                        "div",
                        { staticClass: "header" },
                        [
                          _vm.param.userLogo
                            ? _c("img", {
                                attrs: {
                                  src: _vm.param.userLogo,
                                  alt: "",
                                  width: "100%",
                                  height: "100%",
                                },
                              })
                            : _c("en-user-logo", {
                                attrs: {
                                  "user-name": _vm.param.name,
                                  size: "90",
                                },
                              }),
                          _c(
                            "div",
                            {
                              staticClass: "logo-btn",
                              on: { click: _vm.editHeadLogo },
                            },
                            [_vm._v(" 修改头像 ")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(" " + _vm._s(_vm.param.name) + " "),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "grid" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "myForm",
                            attrs: {
                              model: _vm.param,
                              rules: _vm.formRules,
                              "label-width": "60px",
                              "label-position": "right",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "性别", prop: "gender" } },
                              [
                                _c("en-select", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择",
                                    data: _vm.genderOptions,
                                    "default-props": _vm.defaultProps,
                                  },
                                  model: {
                                    value: _vm.param.gender,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.param, "gender", $$v)
                                    },
                                    expression: "param.gender",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "工号", prop: "no" } },
                              [
                                _c("el-input", {
                                  attrs: { type: "text", disabled: true },
                                  model: {
                                    value: _vm.param.no,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.param, "no", $$v)
                                    },
                                    expression: "param.no",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "公司", prop: "no" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gongsi" },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "text", disabled: true },
                                      model: {
                                        value: _vm.param.tenantName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.param, "tenantName", $$v)
                                        },
                                        expression: "param.tenantName",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "txts",
                                        on: { click: _vm.clickOrganization },
                                      },
                                      [_vm._v("组织架构")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "部门", prop: "jiangang" } },
                              [
                                _c("div", { staticClass: "dept-td" }, [
                                  _c(
                                    "div",
                                    { staticClass: "dept-top" },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "text", disabled: true },
                                        model: {
                                          value: _vm.defaulPost[0].deptText,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.defaulPost[0],
                                              "deptText",
                                              $$v
                                            )
                                          },
                                          expression: "defaulPost[0].deptText",
                                        },
                                      }),
                                      _c("en-icon", {
                                        attrs: {
                                          name: "jiangang-gongsiquan",
                                          size: "small",
                                          color: "#3e90fe",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.showJiangang.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.activePostData.length &&
                                  _vm.jiangangAcive === 1
                                    ? _c(
                                        "div",
                                        { staticClass: "jiangang" },
                                        _vm._l(
                                          _vm.activePostData,
                                          function (i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i.id,
                                                staticClass: "jiangang-card",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "icons" },
                                                  [
                                                    _c("en-icon", {
                                                      attrs: {
                                                        name: "renyuan-gongsiquan",
                                                        size: "18px",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "txt1" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        i.defaultDept === "001"
                                                          ? "兼岗"
                                                          : "借调"
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "txt2" },
                                                  [_vm._v(_vm._s(i.postText))]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "txt3" },
                                                  [_vm._v(_vm._s(i.deptText))]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "txt4" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.param.name)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "岗位", prop: "post" } },
                              [
                                _c("el-input", {
                                  attrs: { type: "text", disabled: true },
                                  model: {
                                    value: _vm.param.post,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.param, "post", $$v)
                                    },
                                    expression: "param.post",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "手机", prop: "mobileNo" } },
                              [
                                _c("el-input", {
                                  attrs: { type: "text", disabled: true },
                                  model: {
                                    value: _vm.param.mobileNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.param, "mobileNo", $$v)
                                    },
                                    expression: "param.mobileNo",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "邮箱", prop: "mobileNo" } },
                              [
                                _c("el-input", {
                                  attrs: { type: "text", disabled: true },
                                  model: {
                                    value: _vm.param.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.param, "email", $$v)
                                    },
                                    expression: "param.email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "证件", prop: "zhengjian" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "zhengjian" },
                                  [
                                    _c("en-select", {
                                      attrs: {
                                        placeholder: "请选择",
                                        data: _vm.cardTypeOptions,
                                        "default-props": _vm.defaultProps,
                                      },
                                      model: {
                                        value: _vm.param.idcardType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.param, "idcardType", $$v)
                                        },
                                        expression: "param.idcardType",
                                      },
                                    }),
                                    _c("el-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.param.idcardNo,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.param, "idcardNo", $$v)
                                        },
                                        expression: "param.idcardNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "生日", prop: "birthDay" } },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                  },
                                  model: {
                                    value: _vm.param.birthDay,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.param, "birthDay", $$v)
                                    },
                                    expression: "param.birthDay",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
            ]),
            _c("div", { staticClass: "account-info" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("span", { staticClass: "txts" }, [_vm._v("账户信息")]),
                  _c("en-icon", {
                    attrs: {
                      name: "icontianjia-danchuang",
                      size: "16",
                      color: "#4793de",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.addAccount.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "account-list" },
                _vm._l(_vm.param.userBankData, function (item, index) {
                  return _c("accountCard", {
                    key: index,
                    attrs: {
                      index: index,
                      "bank-id": item.id,
                      "bank-addr": item.bankAddr,
                      "bank-default": item.isDefault,
                      "bank-account-no": item.bankAccountNo,
                      "bank-icon": item.bankIcon,
                    },
                    on: {
                      editCard: _vm.editCard,
                      deleteCard: function ($event) {
                        return _vm.deleteCard(item.id)
                      },
                    },
                  })
                }),
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEditBasic,
                  expression: "isEditBasic",
                },
              ],
              staticClass: "save-area",
            },
            [
              _c(
                "en-button",
                {
                  attrs: { type: "white" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelBasic()
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "en-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitBasic } },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
          _c("addCard", {
            ref: "bankForm",
            attrs: { "is-edit-bank": _vm.isEditBank },
          }),
          _c("avatarUpload", { ref: "headAlert" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }