<!--
 * @Author: pengyu
 * @Date: 2019-11-19 14:22:17
 * @LastEditors: zhulin
 * @LastEditTime: 2021-04-29 16:53:50
 * @Description: 审批授权列表
 -->
<template>
  <div class="container">
    <en-table :data="list" border :hover-row-config="{ dataMethod: buttonData, changeMethod: buttonClick }" height="100%">
      <en-table-column label="序号" type="index" width="80"> </en-table-column>
      <en-table-column prop="proxyList" label="代理人" :formatter="formatProxy">
        <template slot-scope="scope">
          <div class="user-mod">
            <span class="user-item" v-for="(item, index) in scope.row.proxyList" :key="index">
              {{ item.proxyName }}
            </span>
          </div>
        </template>
      </en-table-column>
      <en-table-column prop="businessTypeName" label="授权业务"> </en-table-column>
      <en-table-column prop="formList" width="240" label="授权内容">
        <template slot-scope="scope">
          <div class="user-mod">
            <span class="user-item" v-for="(item, index) in scope.row.formList" :key="index">
              {{ item.formName }}
            </span>
          </div>
        </template>
      </en-table-column>
      <en-table-column prop="createTime" label="创建日期"> </en-table-column>
      <en-table-column prop="startDate" label="生效日期"> </en-table-column>
      <en-table-column prop="endDate" label="失效日期"> </en-table-column>
      <en-table-column prop="enableDate" label="终止时间"> </en-table-column>
      <!-- 悬浮操作列 -->
      <!-- <template v-slot:hoverRowRight="scope">
        <en-expand-buttons :data="buttonData(scope.row)" @button-click="buttonClick(scope.row, scope.rowIndex, arguments[1], arguments[0])" />
      </template> -->
    </en-table>
  </div>
</template>

<script>
import { authorizeService } from "@/api";

export default {
  name: "ApprovalAuthList",
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    /**
     * @description
     * @param 表格操作列按钮
     */
    buttonData({ row }) {
      let btnList = [
        {
          icon: "zhongzhi-liebiao",
          id: "btn-zhongzhi",
          name: "终止",
          iconColor: "#f66a69"
        },
        {
          icon: "bianji-liebiao",
          id: "btn-edit",
          name: "编辑",
          iconColor: "#26c393"
        },
        {
          icon: "shanchu-liebiao",
          id: "btn-del",
          name: "删除",
          iconColor: "#56ca7f"
        }
      ];
      if (row.enable === "001") {
        btnList = [
          {
            icon: "bianji-liebiao",
            id: "btn-edit",
            name: "编辑",
            iconColor: "#26c393"
          },
          {
            icon: "shanchu-liebiao",
            id: "btn-del",
            name: "删除",
            iconColor: "#56ca7f"
          }
        ];
      }
      return btnList;
    },
    /**
     * @description
     * @param 表格操作列按钮事件
     */
    buttonClick({ row, index, btnData }) {
      switch (btnData.id) {
        case "btn-edit":
          // 编辑
          this.editRow(row, index);
          break;
        case "btn-zhongzhi":
          // 终止
          this.stopAuth(row, index);
          break;
        case "btn-del":
          // 删除
          this.deleteRow(row, index);
          break;
        default:
          break;
      }
    },
    /**
     * @description: 转化 代理人 字段
     */
    formatProxy(row) {
      let user = "";
      row.cellValue.forEach((a) => {
        user += a.proxyName;
      });
      return user;
    },
    /**
     * @description: 编辑审批授权
     * @param {type}
     */
    editRow(data) {
      this.$parent.editApproveAutho(data);
    },
    /**
     * @description: 终止确认
     */
    async enableProxyRecord(pa) {
      await authorizeService.enableProxyRecord(pa);
      this.$message({
        type: "success",
        message: "终止成功!"
      });
      this.$parent.queryProxyList();
    },
    /**
     * @description: 终止授权
     * @param {type}
     */
    stopAuth(row) {
      this.$confirm("确定终止授权?", "", {
        confirmButtonText: "确定",
        showCancelButton: false,
        type: "error"
      }).then(() => {
        this.enableProxyRecord({ id: row.id, enable: "001" });
      });
    },
    /**
     * @description: 删除确认
     */
    async deleteProxyRecord(pa) {
      await authorizeService.deleteProxyRecord(pa);
      this.$message("删除成功");
      this.$parent.queryProxyList();
    },
    /**
     * @description: 删除审批授权
     */
    deleteRow(row) {
      this.$confirm("确定删除授权记录?", "", {
        confirmButtonText: "确定",
        showCancelButton: false,
        type: "warning"
      }).then(() => {
        this.deleteProxyRecord({ id: row.id });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  .user-mod {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;

    .user-item {
      line-height: 24px;
      border: 1px solid #dbe6ec;
      padding: 3px 10px;
      border-radius: 3px;
      margin: 5px 5px 0 0;
    }
  }
  .en-icon {
    margin: 0 5px;
    cursor: pointer;
    &:last-child {
      margin-right: 20px;
    }
  }
  .vxe-body-row-hover {
    .en-icon {
      margin: 0 20px;
      cursor: pointer;
    }
  }
}
</style>
