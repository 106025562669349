<!--
 * @Author: pengyu
 * @Date: 2019-10-19 09:44:34
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-02-02 15:51:03
 * @Description: 个人设置-授权管理
 -->
<template>
  <div class="authorize" v-en-loading="authorLoading">
    <en-tabs v-model="activeTab" :list="tabList" type="white" @change="changeTabs"> </en-tabs>
    <!-- 登录授权 -->
    <div v-if="activeTab === 'first'" class="content">
      <div class="title-area">
        <en-select v-model="value1" placeholder="请选择" :data="options" @change="changeOptions"> </en-select>
        <en-button v-show="value1 === 1" icon="tianjia-anniutoubu" type="primary" @click="addLoginAutho">
          添加
        </en-button>
      </div>
      <div class="grid-area">
        <!-- 我的授权 -->
        <myAuthList v-if="value1 === 1" :list="tableData1" />
        <!-- 授权给我 -->
        <auth-for-me-list v-else :list="tableData2" />
      </div>
      <div class="grid-bottom">
        <en-pagination :page-model="value1 === 1 ? pageModel1 : pageModel2" @change="handlePageChanged"></en-pagination>
      </div>
    </div>
    <!-- 审批授权 -->
    <div v-else-if="activeTab === 'second'" class="content">
      <div class="title-area">
        <el-checkbox v-model="checked">
          审批授权期间，仍显示待办任务提醒
        </el-checkbox>
        <en-button icon="tianjia-anniutoubu" type="primary" @click="addApproveAutho">
          添加
        </en-button>
      </div>
      <div class="grid-area">
        <!-- 审批授权-->
        <approvalAuthList :list="tableData3" />
      </div>
      <div class="grid-bottom">
        <en-pagination :page-model="pageModel3" @change="handlePageChanged3"></en-pagination>
      </div>
    </div>
    <div v-else class="content">
      <div class="title-area">
        <el-checkbox v-model="checkedSameScreen" @change="saveUserConfig">
          同意接受其他用户端的“同屏指令”
        </el-checkbox>
      </div>
    </div>
    <!-- 登录日志 -->
    <popLog ref="logAlert" :logData="logData" />
    <!-- 新增登录授权 -->
    <loginAuthorize ref="loginAlert" />
    <!-- 新增审批授权 -->
    <approvalAuthorize ref="approveAlert" />
  </div>
</template>

<script>
import { authorizeService } from "@/api";
import { enService } from "@/api/en/index";
import popLog from "./components/popLog"; // 登录日志
import loginAuthorize from "./components/loginAuthorize"; // 登录授权
import approvalAuthorize from "./components/approvalAuthorize"; // 审批授权
import myAuthList from "./components/myAuth"; // 我的授权列表
import authForMeList from "./components/authForMe"; // 授权给我 列表
import approvalAuthList from "./components/approvalAuthList"; // 审批授权

export default {
  name: "AuthorizeSetting",
  components: {
    popLog,
    loginAuthorize,
    approvalAuthorize,
    myAuthList,
    authForMeList,
    approvalAuthList
  },
  data() {
    return {
      checked: true,
      activeTab: "first",
      tabList: [
        {
          id: "first",
          name: "登录授权"
        },
        {
          id: "second",
          name: "审批授权"
        },
        {
          id: "third",
          name: "同屏授权"
        }
      ],
      options: [
        { label: "我的授权", value: 1 },
        { label: "授权给我", value: 2 }
      ],
      value1: 1,
      logData: "",
      pageModel1: {},
      pageModel2: {},
      pageModel3: {},
      // 登录授权-我的授权
      tableData1: [],
      tableData2: [],
      tableData3: [],
      authorLoading: false,
      loadingMyAuth: false,
      loadingAuthorize: false,
      loadingProxyList: false,
      loadingSameScreen: false,
      checkedSameScreen: false
    };
  },
  mounted() {
    // 查询 我的授权列表数据
    this.queryMyAuthList();
  },
  methods: {
    /**
     * @description: 查询 我的授权列表数据
     */
    async queryMyAuthList() {
      try {
        this.authorLoading = true;
        const pa = {
          pageNo: this.pageModel1.pageNo,
          pageSize: this.pageModel1.pageSize
        };
        const rsp = await authorizeService.queryMyAuth(pa);
        this.pageModel1 = {
          pageNo: rsp.pageNo || "",
          pageSize: rsp.pageSize || "",
          total: rsp.total || "",
          totalPages: rsp.totalPages || ""
        };
        this.tableData1 = rsp.records;
      } catch (e) {
        console.error(e);
      } finally {
        this.authorLoading = false;
      }
    },
    /**
     * @description: 查询 授权给我列表数据
     */
    async queryAuthMeList() {
      try {
        this.authorLoading = true;
        const pa = {
          pageNo: this.pageModel2.pageNo || 1,
          pageSize: this.pageModel2.pageSize || 10
        };
        const rsp = await authorizeService.queryAuthMe(pa);
        this.pageModel2 = {
          pageNo: rsp.pageNo || "",
          pageSize: rsp.pageSize || "",
          total: rsp.total || "",
          totalPages: rsp.totalPages || ""
        };
        this.tableData2 = rsp.records;
      } catch (e) {
        console.error(e);
      } finally {
        this.authorLoading = false;
      }
    },
    /**
     * @description: 查询授权代理的待办任务提醒
     */
    async queryProxySwitch() {
      const rsp = await authorizeService.queryProxySwitch();
      if (rsp.wfProxySwitch === "0") {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    /**
     * @description: 分页器 事件
     */
    handlePageChanged({ pageSize, pageNo }) {
      // 重新查询
      if (this.value1 === 1) {
        this.pageModel1.pageNo = pageNo;
        this.pageModel1.pageSize = pageSize;
        this.queryMyAuthList();
      } else {
        this.pageModel2.pageNo = pageNo;
        this.pageModel2.pageSize = pageSize;
        this.queryAuthMeList();
      }
    },
    handlePageChanged3({ pageSize, pageNo }) {
      // 重新查询
      this.pageModel3.pageNo = pageNo;
      this.pageModel3.pageSize = pageSize;
      this.queryProxyList();
    },
    /**
     * @description: 查询 审批授权列表
     */
    async queryProxyList() {
      try {
        this.authorLoading = true;
        const pa = {
          pageNo: this.pageModel3.pageNo,
          pageSize: this.pageModel3.pageSize
        };
        const rsp = await authorizeService.queryProxyList(pa);
        this.pageModel3 = {
          pageNo: rsp.pageNo || "",
          pageSize: rsp.pageSize || "",
          total: rsp.total || "",
          totalPages: rsp.totalPages || ""
        };
        this.tableData3 = rsp.records;
      } catch (e) {
        console.error(e);
      } finally {
        this.authorLoading = false;
      }
    },
    /**
     * @description: 切换登录授权审批授权
     */
    changeTabs() {
      if (this.activeTab === "second") {
        this.queryProxySwitch();
        this.queryProxyList();
      } else if (this.activeTab === "third") {
        this.queryUserConfig();
      }
    },
    /**
     * @description: 新增登陆授权
     */
    addLoginAutho() {
      this.$refs.loginAlert.dialogVisible = true;
    },
    /**
     * @description: 新增审批授权
     */
    addApproveAutho() {
      this.$refs.approveAlert.dialogVisible = true;
      this.$refs.approveAlert.isEdit = false;
    },
    changeKey(arr, key) {
      const newArr = [];
      arr.forEach((item) => {
        const newObj = {};
        for (let i = 0; i < key.length; i++) {
          newObj[key[i]] = item[Object.keys(item)[i]];
        }
        newArr.push(newObj);
      });
      return newArr;
    },
    /**
     * @description: 编辑审批授权
     */
    editApproveAutho(data) {
      const brr = this.changeKey(data.proxyList, ["id", "name"]);
      const obj = {
        id: data.id,
        businessType: data.businessType,
        proxyUser: brr,
        wfProxyDetailList: [
          {
            proxyUser: "",
            proxyType: "1",
            mappingExpress: "",
            webExpress: ""
          }
        ],
        date: [data.startDate, data.endDate],
        startTime: data.startDate,
        endTime: data.endDate,
        domainId: data.domainId
      };
      if (data.formId) {
        obj.formId = data.formId.split(",");
      }
      this.$refs.approveAlert.myForm = obj;
      this.$refs.approveAlert.isEdit = true;
      this.$refs.approveAlert.dialogVisible = true;
    },
    /**
     * @description: 登录日志
     */
    popLogAlert() {
      this.$refs.logAlert.dialogVisible = true;
    },
    /**
     * @description: 我的授权|授权给我切换
     */
    changeOptions(id) {
      this[`tableData${id}`] = this[`tableData${id}`];
      this.queryAuthMeList();
    },
    // 查询同屏授权
    async queryUserConfig() {
      const p = { configKey: "sameScreenAuth" };
      const data = (await enService.queryUserConfig(p)) || {};
      // console.log(data);
      if (data.value === "0") {
        // 未读消息
        this.checkedSameScreen = true;
      } else if (data.value === "1") {
        this.checkedSameScreen = false;
      } else {
        // 默认
        this.checkedSameScreen = false;
      }
    },
    // 保存同屏授权
    async saveUserConfig() {
      const params = {
        configKey: "sameScreenAuth",
        configValue: this.checkedSameScreen ? "0" : "1"
      };
      enService.saveUserConfig(params);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.vxe-table .vxe-sort-wrapper .vxe-sort--asc-btn{
}
/deep/.vxe-sort-wrapper{
}
/deep/.vxe-header--row {
  background-color: #f2f5f8 !important;
}

/deep/.vxe-header--row .is--firstCol {
  background-color: #f2f5f8 !important;
}
/deep/.vxe-header--row .col--fixed {
  background-color: #f2f5f8 !important;
}
/deep/.vxe-table--header-wrapper {
  background-color: #f2f5f8 !important;
}
/deep/.vxe-table .vxe-head-end-wrapper {
  background-color: #f2f5f8 !important;
}
/deep/.button {
  background-color: transparent !important;
}
/deep/.en-expand-buttons {
  background-color: transparent !important;
}
/deep/ .en-tabs-item {
  width: 120px;
  padding: 0;
  > span {
    display: inline-block;
    width: 120px;
  }
}
.en-tabs /deep/ .active_bar {
  width: 120px !important;
}
.authorize {
  height: 100%;
  position: relative;
  .content {
    height: calc(100% - 49px);
  }
  .title-area {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    .el-checkbox {
      line-height: 32px;
    }
  }
  .grid-area {
    height: calc(100% - 110px);
    //overflow: auto;
  }
  .grid-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #fff;
    z-index: 10;
    .en-pagination {
      float: right;
    }
  }
}
</style>
