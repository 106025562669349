<!--
 * @Author: pengyu
 * @Date: 2019-10-22 11:59:22
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-19 15:48:49
 * @Description: 授权管理-审批授权
 -->
<template>
  <el-dialog
    :title="isEdit ? '编辑审批授权' : '新增审批授权'"
    :visible.sync="dialogVisible"
    width="900px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :lock-scroll="false"
    class="approve-alert"
  >
    <el-form ref="myForm" :model="myForm" :rules="formRules" label-width="80px" label-position="left">
      <el-form-item label="授权业务" prop="businessType">
        <en-select v-model="myForm.businessType" placeholder="请选择" :data="options"> </en-select>
      </el-form-item>
      <el-form-item v-if="myForm.businessType === 2" label="授权内容" prop="formId">
        <en-select
          mode="tree"
          v-model="myForm.formId"
          placeholder="请选择"
          :props="{ value: 'id', label: 'name', children: 'nodes' }"
          :data="formData"
          :check-limit="1"
          :expand-on-click-node="false"
          :check-on-click-node="true"
          check-mode="siblings"
          multiple
        >
        </en-select>
      </el-form-item>
      <el-form-item label="代理人员" prop="proxyUser">
        <en-select
          mode="tree"
          v-model="myForm.proxyUser"
          value-key="id"
          data-mode="id-name"
          placeholder="请选择"
          :props="{ value: 'id', label: 'name', isLeaf: isLeaf }"
          :data="treeData"
          :check-limit="1"
          :expand-on-click-node="false"
          :check-on-click-node="true"
          :load="loadNode"
          check-mode="siblings"
          @change="authUserChange"
          lazy
          multiple
        >
        </en-select>
      </el-form-item>
      <el-form-item label="生效时间" prop="date">
        <el-date-picker v-model="myForm.date" type="daterange" format="yyyy-MM-dd" range-separator="—" start-placeholder="" end-placeholder="" size="small"> </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click.native="submitForm">确定</en-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import dayjs from "dayjs";
import { settingService, authorizeService } from "@/api";

export default {
  name: "ApprovalAuthorize",
  components: {},
  data() {
    return {
      dialogVisible: false,
      options: [
        { label: "全部审批业务", value: 1 },
        { label: "业务建模", value: 2 },
        { label: "用车订单", value: 3 }
      ], // 授权业务
      isEdit: false,
      myForm: {
        businessType: 1,
        date: [new Date(), new Date()],
        proxyRange: 1,
        proxyUser: [],
        proxyUserName: "",
        wfProxyDetailList: [
          {
            proxyUser: "",
            proxyType: "1",
            mappingExpress: "",
            webExpress: ""
          }
        ],
        startTime: "",
        endTime: "",
        formId: "",
        domainId: ""
      },
      newTime: [],
      sendForm: {},
      formData: [],
      formRules: {
        businessType: [
          {
            required: true,
            message: "请选择授权业务",
            trigger: "blur"
          }
        ],
        formId: [
          {
            required: true,
            message: "请选择授权内容",
            trigger: "blur"
          }
        ],
        proxyUser: [
          {
            required: true,
            message: "请选择代理人员",
            trigger: "blur"
          }
        ],
        date: [
          {
            required: true,
            message: "请设置生效时间",
            trigger: "blur"
          }
        ]
      },
      modelData: [
        {
          value: "1",
          label: "基础数据"
        },
        {
          value: "2",
          label: "业务建模",
          children: [
            {
              value: "2-1",
              label: "模板1"
            }
          ]
        }
      ], // 业务模板数据
      treeData: []
    };
  },
  mounted() {
    this.queryUserTree(0);
    this.queryFormData();
  },
  watch: {
    dialogVisible(newval) {
      if (!newval) {
        // if (!newval || !this.isEdit) {
        // this.$refs.myForm.resetFields();
        this.myForm = {
          businessType: 1,
          date: [new Date(), new Date()],
          proxyRange: 1,
          proxyUser: [],
          proxyUserName: "",
          wfProxyDetailList: [
            {
              proxyUser: "",
              proxyType: "1",
              mappingExpress: "",
              webExpress: ""
            }
          ],
          startTime: "",
          endTime: "",
          formId: "",
          domainId: ""
        };
      } else {
        this.queryUserTree(0);
        this.queryFormData();
      }
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    /**
     * @description: 下拉框取值
     */
    authUserChange(data) {
      const obj = data.findIndex((item) => item.id === this.userInfo.userId);
      if (obj >= 0) {
        this.$message.warning("不能授权给自己");
        data.splice(obj, 1);
        return;
      }
      this.myForm.wfProxyDetailList[0].proxyUser = data.map((item) => (item.id ? item.id : item)).join(",");
    },
    /**
     * @description: 查询业务建模
     * @param id     节点id 0为最高层级
     */
    async queryFormData() {
      const rsp = await authorizeService.queryDataResource({ dataType: 3, queryType: 1 });
      this.formData = rsp;
    },
    /**
     * @description: 查询人员树
     * @param id     节点id 0为最高层级
     */
    async queryUserTree(id) {
      const rsp = await settingService.queryDeptUserTree({ id });
      this.treeData = rsp;
    },
    /**
     * @description: 指定节点是否为叶子节点
     * @param data 节点数据
     * @return:
     */
    isLeaf(data) {
      return data.hasChildren === "001";
    },
    /**
     * @description: 树 异步加载子节点
     * @param node
     * @param resolve
     */
    async loadNode(node, resolve) {
      const { data } = node;
      const { hasChildren, id } = data;
      if (hasChildren === "000") {
        // 有子项
        const rsp = await settingService.queryDeptUserTree({ id });
        resolve(rsp);
      } else {
        resolve([]);
      }
    },
    /**
     * @description: 提交确认
     */
    async addUpdateProxy(pa) {
      authorizeService.addUpdateProxy(pa).then(() => {
        this.sendForm = {};
        this.myForm.proxyUser = "";
        this.$parent.queryProxyList();
        this.dialogVisible = false;
      });
    },
    /**
     * @description: 提交确认
     */
    async submitForm() {
      this.sendForm = {
        businessType: this.myForm.businessType,
        startDate: dayjs(this.myForm.date[0]).format("YYYY-MM-DD"),
        endDate: dayjs(this.myForm.date[1]).format("YYYY-MM-DD"),
        domainId: this.myForm.domainId || "",
        wfProxyDetailList: JSON.stringify(this.myForm.wfProxyDetailList)
      };
      if (this.myForm.businessType === 2) {
        this.sendForm.formId = this.myForm.formId.join(",");
      }
      if (this.isEdit) {
        this.sendForm.id = this.myForm.id;
        this.myForm.wfProxyDetailList[0].proxyUser = this.myForm.proxyUser.map((item) => (item.id ? item.id : item)).join(",");
        this.sendForm.wfProxyDetailList = JSON.stringify(this.myForm.wfProxyDetailList);
      }
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          this.addUpdateProxy(this.sendForm);
        }
        return false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.approve-alert {
  .en-select {
    width: 100%;
  }
  /deep/ .el-date-editor {
    width: 100%;
    .el-range-separator {
      color: #aab6c4;
    }
  }
  /deep/ .el-range-input {
    text-align: left;
  }
}
</style>
