var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "organization-main" },
    [
      _c("en-title-card", [
        _c("div", { attrs: { slot: "left" }, slot: "left" }, [
          _c("span", [_vm._v("组织架构图")]),
        ]),
        _c(
          "div",
          { attrs: { slot: "right" }, slot: "right" },
          [
            _c("en-icon", {
              staticClass: "close-btn",
              attrs: {
                name: "guanbi-danchuang",
                size: "small",
                color: "#a9b5c6",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.close.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "en-loading",
              rawName: "v-en-loading",
              value: _vm.enLoading,
              expression: "enLoading",
            },
          ],
          staticClass: "org-view",
        },
        [
          _c("vue2-org-tree", {
            attrs: {
              data: _vm.data,
              horizontal: _vm.horizontal,
              collapsable: _vm.collapsable,
              "label-class-name": _vm.labelClassName,
              "render-content": _vm.renderContent,
              txt: _vm.data.txt,
            },
            on: { "on-expand": _vm.onExpand, "on-node-click": _vm.onNodeClick },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }