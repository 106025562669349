<!--
 * @Author: pengyu
 * @Date: 2019-10-22 09:51:17
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-02-02 15:45:03
 * @Description: 授权管理-登陆授权弹框
 -->
<template>
  <el-dialog v-if="dialogVisible" title="新增登录授权" :visible.sync="dialogVisible" width="900px" :append-to-body="true" :close-on-click-modal="false" :lock-scroll="false" class="login-alert">
    <el-form ref="myForm" :model="myForm" :rules="formRules" label-width="80px" label-position="left">
      <el-form-item label="授权对象" prop="authObj">
        <en-select v-model="myForm.authObj" placeholder="请选择" :data="options" @change="objChange"></en-select>
      </el-form-item>
      <el-form-item v-if="myForm.authObj === 2" label="授权人员" prop="authUser">
        <en-select
          mode="tree"
          v-model="myForm.authPerson"
          data-mode="data"
          placeholder="请选择"
          :props="{ value: 'id', label: 'name', isLeaf: isLeaf }"
          :data="treeData"
          :check-limit="1"
          :expand-on-click-node="false"
          :check-on-click-node="true"
          :load="loadNode"
          @change="authUserChange"
          lazy
          multiple
        >
        </en-select>
      </el-form-item>
      <el-form-item label="授权范围" prop="authRange">
        <en-select v-model="myForm.authRange" placeholder="请选择" :data="optionsRange"> </en-select>
      </el-form-item>
      <el-form-item label="生效时间" prop="time">
        <el-date-picker v-model="myForm.time" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" range-separator="—" start-placeholder="" end-placeholder="" align="right" size="small">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click="submitForm">确定</en-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import dayjs from "dayjs";
import { settingService, authorizeService } from "@/api";

export default {
  name: "LoginAuthorize",
  components: {},
  data() {
    return {
      dialogVisible: false,
      treeData: [],
      myForm: {
        authObj: 2,
        authPerson: [],
        authRange: "1",
        authUser: "",
        authUserName: "",
        time: [dayjs().format("YYYY-MM-DD HH:mm:ss"), `${dayjs(new Date().setDate(new Date().getDate() + 1)).format("YYYY-MM-DD")} 18:00`]
      },
      sendForm: {},
      options: [
        { label: "企业员工", value: 2 },
        { label: "外部人员", value: 1 }
      ],
      optionsRange: [{ label: "用户权限", value: "1" }],
      // 授权对象
      defaultProps: { value: "id", label: "name" },
      formRules: {
        authObj: [
          {
            required: true,
            message: "请选择授权对象",
            trigger: "blur"
          }
        ],
        authUser: [
          {
            required: true,
            message: "请选择授权人员",
            trigger: "blur"
          }
        ],
        authRange: [
          {
            required: true,
            message: "请选择授权范围",
            trigger: "blur"
          }
        ],
        time: [
          {
            required: true,
            message: "请设置生效时间",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  watch: {
    dialogVisible(newval) {
      if (!newval) {
        this.$refs.myForm.resetFields();
      } else {
        this.queryUserTree(0);
      }
    }
  },
  methods: {
    objChange(val) {
      if (val === 2) {
        this.queryUserTree(0);
      }
    },
    /**
     * @description: 下拉框取值
     */
    authUserChange(data) {
      const obj = data.findIndex((item) => item.id === this.userInfo.userId);
      if (obj >= 0) {
        this.$message.warning("不能授权给自己");
        data.splice(obj, 1);
        return;
      }
      const userID = [];
      const userName = [];
      data.map((item) => {
        userID.push(item.id);
        userName.push(item.name);
        return userID;
      });
      this.myForm.authUser = userID.join(",");
      this.myForm.authUserName = userName.join(",");
    },
    /**
     * @description: 查询人员树
     * @param id     节点id 0为最高层级
     */
    async queryUserTree(id) {
      const rsp = await settingService.queryDeptUserTree({ id });
      this.treeData = rsp;
    },
    /**
     * @description: 指定节点是否为叶子节点
     * @param data 节点数据
     * @return:
     */
    isLeaf(data) {
      return data.hasChildren === "001";
    },
    /**
     * @description: 树 异步加载子节点
     * @param node
     * @param resolve
     */
    async loadNode(node, resolve) {
      const { data } = node;
      const { hasChildren, id } = data;
      if (hasChildren === "000") {
        // 有子项
        const rsp = await settingService.queryDeptUserTree({ id });
        resolve(rsp);
      } else {
        resolve([]);
      }
    },
    /**
     * @description: 确认提交
     */
    submitForm() {
      const {
 authObj, authRange, time, authUser, authUserName
} = this.myForm;
      const startTime = dayjs(time[0]).format("YYYY-MM-DD HH:mm:ss");
      const endTime = dayjs(time[1]).format("YYYY-MM-DD HH:mm:ss");
      this.sendForm = {
        authObj,
        authRange,
        startTime,
        endTime
      };
      if (authObj === 2) {
        this.sendForm.authUser = authUser;
        this.sendForm.authUserName = authUserName;
      }
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          this.addAuth(this.sendForm);
        }
        return false;
      });
    },
    async addAuth(data) {
      authorizeService.addAuth(data).then(() => {
        this.myForm.authPerson = [];
        this.$parent.queryMyAuthList();
        this.dialogVisible = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.login-alert {
  .en-select {
    width: 100%;
  }
  /deep/ .el-date-editor {
    width: 100%;
    // position: relative;
    .el-range-separator {
      color: #aab6c4;
    }
    .el-range__close-icon{
      position: absolute;
      right: 4.5px;
    }
  }
  /deep/ .el-range-input {
    text-align: left;
  }
}
</style>
