var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "登录日志",
        visible: _vm.dialogVisible,
        width: "1000px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "lock-scroll": true,
        "custom-class": "log-alert",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "en-table",
        {
          directives: [
            {
              name: "en-loading",
              rawName: "v-en-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.logTable, border: "" },
        },
        [
          _c("en-table-column", { attrs: { type: "index", width: "80" } }),
          _c("en-table-column", {
            attrs: { prop: "loginTime", label: "登录时间" },
          }),
          _c("en-table-column", {
            attrs: { prop: "userName", label: "登录人" },
          }),
          _c("en-table-column", {
            attrs: { prop: "deviceType", label: "终端类型" },
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("en-pagination")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }