var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "notice-alert",
      attrs: {
        title: "设置提醒",
        visible: _vm.show,
        width: "900px",
        "append-to-body": true,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "nForm",
          attrs: {
            model: _vm.noticeForm,
            rules: _vm.formRules,
            "label-width": "70px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "提醒事项", prop: "taskType" } },
            [
              _c("en-select", {
                attrs: {
                  placeholder: "请选择",
                  props: _vm.defaultProps,
                  data: _vm.taskTypeData,
                },
                on: { change: _vm.onChangeType },
                model: {
                  value: _vm.noticeForm.taskType,
                  callback: function ($$v) {
                    _vm.$set(_vm.noticeForm, "taskType", $$v)
                  },
                  expression: "noticeForm.taskType",
                },
              }),
            ],
            1
          ),
          _vm.noticeForm.taskType === 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "提醒时间", prop: "remindTime" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.noticeForm.remindTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.noticeForm, "remindTime", $$v)
                            },
                            expression: "noticeForm.remindTime",
                          },
                        },
                        _vm._l(_vm.timeData, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "重复周期", prop: "" } },
                    [
                      _c("el-checkbox", {
                        attrs: { label: "星期一" },
                        model: {
                          value: _vm.noticeForm.mon,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "mon", $$v)
                          },
                          expression: "noticeForm.mon",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "星期二" },
                        model: {
                          value: _vm.noticeForm.tue,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "tue", $$v)
                          },
                          expression: "noticeForm.tue",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "星期三" },
                        model: {
                          value: _vm.noticeForm.wed,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "wed", $$v)
                          },
                          expression: "noticeForm.wed",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "星期四" },
                        model: {
                          value: _vm.noticeForm.thu,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "thu", $$v)
                          },
                          expression: "noticeForm.thu",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "星期五" },
                        model: {
                          value: _vm.noticeForm.fri,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "fri", $$v)
                          },
                          expression: "noticeForm.fri",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "星期六" },
                        model: {
                          value: _vm.noticeForm.sat,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "sat", $$v)
                          },
                          expression: "noticeForm.sat",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "星期日" },
                        model: {
                          value: _vm.noticeForm.sun,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "sun", $$v)
                          },
                          expression: "noticeForm.sun",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.noticeForm.taskType === 1
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "资源类型", prop: "travelType" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.noticeForm.travelType,
                            callback: function ($$v) {
                              _vm.$set(_vm.noticeForm, "travelType", $$v)
                            },
                            expression: "noticeForm.travelType",
                          },
                        },
                        _vm._l(_vm.travelTypeOption, function (t) {
                          return _c(
                            "el-checkbox",
                            { key: t.id, attrs: { label: t.id } },
                            [_vm._v(" " + _vm._s(t.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "触发时间", prop: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "remind-dingdan" },
                        [
                          _c("div", [_vm._v("订单行程执行前")]),
                          _c("el-input", {
                            directives: [
                              {
                                name: "popover",
                                rawName: "v-popover:popover",
                                arg: "popover",
                              },
                            ],
                            staticClass: "ipt-time",
                            attrs: { readonly: true },
                            model: {
                              value: _vm.noticeForm.remindTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.noticeForm, "remindTime", $$v)
                              },
                              expression: "noticeForm.remindTime",
                            },
                          }),
                          _c("div", [_vm._v("小时")]),
                          _c(
                            "el-popover",
                            {
                              ref: "popover",
                              attrs: {
                                placement: "bottom-start",
                                width: "395",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "hour-panel" },
                                _vm._l(_vm.hour, function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item,
                                      staticClass: "hour-item",
                                      class: {
                                        active:
                                          item === _vm.noticeForm.remindTime,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickHour(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item) + " ")]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm.noticeForm.taskType === 2
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "提醒时间", prop: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "remind-time" },
                        [
                          _c("div", [_vm._v("可报销的行程")]),
                          _c(
                            "el-select",
                            {
                              staticClass: "sel-num",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.noticeForm.reimbursementType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.noticeForm,
                                    "reimbursementType",
                                    $$v
                                  )
                                },
                                expression: "noticeForm.reimbursementType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "天数", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "金额", value: "2" },
                              }),
                            ],
                            1
                          ),
                          _c("div", [_vm._v("超过")]),
                          _c(
                            "el-input",
                            {
                              staticClass: "sel-unit",
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.noticeForm.remindTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.noticeForm, "remindTime", $$v)
                                },
                                expression: "noticeForm.remindTime",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "suffix" }, slot: "suffix" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.noticeForm.reimbursementType === "1"
                                        ? "天"
                                        : "元"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "重复周期", prop: "" } },
                    [
                      _c("el-checkbox", {
                        attrs: { label: "每周一" },
                        model: {
                          value: _vm.noticeForm.mon,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "mon", $$v)
                          },
                          expression: "noticeForm.mon",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "每周二" },
                        model: {
                          value: _vm.noticeForm.tue,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "tue", $$v)
                          },
                          expression: "noticeForm.tue",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "每周三" },
                        model: {
                          value: _vm.noticeForm.wed,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "wed", $$v)
                          },
                          expression: "noticeForm.wed",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "每周四" },
                        model: {
                          value: _vm.noticeForm.thu,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "thu", $$v)
                          },
                          expression: "noticeForm.thu",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "每周五" },
                        model: {
                          value: _vm.noticeForm.fri,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "fri", $$v)
                          },
                          expression: "noticeForm.fri",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "每周六" },
                        model: {
                          value: _vm.noticeForm.sat,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "sat", $$v)
                          },
                          expression: "noticeForm.sat",
                        },
                      }),
                      _c("el-checkbox", {
                        attrs: { label: "每周日" },
                        model: {
                          value: _vm.noticeForm.sun,
                          callback: function ($$v) {
                            _vm.$set(_vm.noticeForm, "sun", $$v)
                          },
                          expression: "noticeForm.sun",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.noticeForm.taskType === 3
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "提醒类型", prop: "businessType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.noticeForm.businessType,
                            callback: function ($$v) {
                              _vm.$set(_vm.noticeForm, "businessType", $$v)
                            },
                            expression: "noticeForm.businessType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "上班提醒", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "下班提醒", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提醒时间", prop: "remindTime" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.noticeForm.businessType === "1"
                              ? "上班前"
                              : "下班后"
                          ) +
                          " "
                      ),
                      _c(
                        "el-select",
                        {
                          staticClass: "ipt-time",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.noticeForm.remindTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.noticeForm, "remindTime", $$v)
                            },
                            expression: "noticeForm.remindTime",
                          },
                        },
                        _vm._l(_vm.minData, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                      _vm._v(" 分钟 "),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.noticeForm.taskType === 4
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "提醒时间", prop: "remindTime" } },
                    [
                      _c(
                        "div",
                        { staticClass: "remind-task" },
                        [
                          _c("div", [_vm._v("任务截止前")]),
                          _c(
                            "el-select",
                            {
                              staticClass: "sel-time",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.noticeForm.remindTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.noticeForm, "remindTime", $$v)
                                },
                                expression: "noticeForm.remindTime",
                              },
                            },
                            _vm._l(_vm.minData2, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticClass: "sel-unit2",
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.noticeForm.remindUnit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.noticeForm, "remindUnit", $$v)
                                },
                                expression: "noticeForm.remindUnit",
                              },
                            },
                            _vm._l(_vm.unitData, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.text, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "en-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("nform")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }