<!--
 * @Author: pengyu
 * @Date: 2019-10-11 17:50:57
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-14 14:33:41
 * @Description: 提醒设置块（代办任务 订单行程 行程报销 考勤提醒 任务提醒 ）
 -->
<template>
  <div class="remind">
    <div class="title">
      <span>{{ taskType | getTypeName }}</span>
    </div>
    <div class="cents">
      <en-card
        v-for="(i, index) in list"
        :key="index"
        :icon-name="typeObj[taskType].icon"
        class="items-child"
        :edit-able="true"
        :title="getLeftContent(i)"
        :sub-title="getMiddleContent(i)"
        icon-color="#fff"
        :icon-bg="typeObj[taskType].color"
        :menu-list="menuList(i)"
        :disabled="i.enable === 1 ? true : false"
        :dataInfo="i"
        :class="{ itemdisabled: i.enable === 1 ? true : false }"
        @change="changeItemData"
      />
    </div>
  </div>
</template>

<script>
import { preferenceService } from "@/api";

export default {
  name: "RemindSection",
  components: {},
  filters: {
    getTypeName(type) {
      const obj = ["待办任务", "订单行程", "行程报销", "考勤提醒", "任务提醒"];
      return obj[type];
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    taskType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 模块icon color
      typeObj: {
        0: {
          icon: "icondaibanshixiang1",
          color: "#ffad2d"
        },
        1: {
          icon: "icondingdanhangcheng1",
          color: "#9466ed"
        },
        2: {
          icon: "iconhangchengbaoxiao1",
          color: "#23c593"
        },
        3: {
          icon: "iconkaoqintixing1",
          color: "#4794dc"
        },
        4: {
          icon: "iconrenwutixing1",
          color: "#2ac293"
        }
      },
      week: {
        mon: "周一",
        tue: "周二",
        wed: "周三",
        thu: "周四",
        fri: "周五",
        sat: "周六",
        sun: "周日"
      },
      trip: {
        0: "机票",
        1: "酒店",
        2: "用车"
      }
    };
  },
  methods: {
    /**
     * @description
     * 设置en-card的下拉菜单
     */
    menuList(data) {
      let mlist = [];
      // 1停用0启用
      mlist = [
        { id: "001", name: data.enable === 1 ? "启用" : "停用" },
        { id: "002", name: "编辑" },
        { id: "003", name: "删除" }
      ];
      return mlist;
    },
    /**
     * @description 单个模版下拉菜单
     */
    changeItemData(menuInfo, dataInfo) {
      switch (menuInfo.id) {
        case "001":
          return this.stopMod(dataInfo);
        case "002":
          return this.editNotice(dataInfo);
        case "003":
          return this.delNotice(dataInfo.id);
        default:
          return "";
      }
    },
    /**
     * @description: 根据提醒类型 获取每行 左侧内容
     * @param {item} 块绑定数据
     * @return:
     */
    getLeftContent(item) {
      const tt = this.taskType;
      const _this = this;
      if (tt === 0) {
        // 待办
        return `${item.remindTime}:00`;
      }
      if (tt === 1) {
        // 订单
        const tit = [];
        const ary = item.travelType.split("_");
        ary.forEach((a) => {
          tit.push(_this.trip[a]);
        });
        return tit.join("、");
      }
      if (tt === 2) {
        // 行程
        if (item.reimbursementType.toString() === "1") {
          return `行程单报销超出${item.remindTime}天`;
        }
        if (item.reimbursementType.toString() === "2") {
          return `行程单报销总金额超出${item.remindTime}元`;
        }
        return "";
      }
      if (tt === 3) {
        // 考勤
        return item.businessType.toString() === "1" ? "上班" : "下班";
      }
      if (tt === 4) {
        // 任务
        return "任务截止前";
      }
      return "";
    },
    /**
     * @description: 根据提醒类型 获取每行 中间内容
     * @param {item} 块绑定数据
     * @return:
     */
    getMiddleContent(item) {
      const tt = this.taskType;
      if (tt === 0 || tt === 2) {
        // 待办
        const cycle = [];
        const _this = this;
        if (item.mon + item.tue + item.wed + item.thu + item.fri + item.sat + item.sun === 7) {
          cycle.push("每天");
        } else if (item.mon + item.tue + item.wed + item.thu + item.fri === 5 && item.sat + item.sun === 0) {
          cycle.push("每个工作日");
        } else if (item.mon + item.tue + item.wed + item.thu + item.fri === 0 && item.sat + item.sun === 2) {
          cycle.push("每周末");
        } else {
          ["mon", "tue", "wed", "thu", "fri", "sat", "sun"].forEach((a) => {
            if (item[a] === 1) {
              cycle.push(_this.week[a]);
            }
          });
        }

        return cycle.join("、");
      }
      if (tt === 1) {
        // 订单
        return `执行前${item.remindTime}小时`;
      }
      if (tt === 2) {
        // 行程
        // 同待办
      } else if (tt === 3) {
        // 考勤
        if (item.businessType.toString() === "1") {
          return `前${item.remindTime}分钟`;
        }
        return `后${item.remindTime}分钟`;
      } else if (tt === 4) {
        // 任务
        const unit = item.remindUnit;
        const time = item.remindTime;
        let unitstr = "";
        unitstr = unit.toString() === "1" ? "分钟" : unit.toString() === "2" ? "小时" : unit.toString() === "3" ? "天" : "";
        return `前${time}${unitstr}`;
      }
      return "";
    },
    /**
     * @description: 删除提醒
     * @param {id}  数据id
     * @return:
     */
    async delNotice(ids) {
      await preferenceService.deleteRemindConfig({ id: ids });
      this.$message({
        type: "success",
        message: "删除成功!"
      });
      this.$parent.queryList();
    },
    /**
     * @description: 停用提醒
     * @return:
     */
    async stopMod(d) {
      const msg = d.enable === 1 ? "启用" : "停用";
      this.$message({
        type: "success",
        message: `${msg}成功!`
      });
      const _data = { id: d.id, enable: d.enable === 1 ? 0 : 1 };
      await preferenceService.enableRemindConfig(_data);
      this.$parent.queryList();
    },
    /**
     * @description: 编辑提醒
     * @param {data}  数据
     * @return:
     */
    editNotice(data) {
      this.$emit("editNotice", data);
    },
    /**
     * @description: 保存提醒
     */
    async addRemindConfig(pa) {
      await preferenceService.addRemindConfig(pa);
      this.$parent.queryList();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.en-card-normal-main-sub-title{
  color: #91a1b7;
  font-size:12px;
}
.remind {
  font-size: 12px;
  margin-bottom: 30px;
  text-align: left;
  .cents {
    display: flex;
    flex-wrap: wrap;
    .en-card {
      margin-right: 10px;
    }
  }
  .title {
    font-weight: bold;
    margin-bottom: 10px;
    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border-radius: 50%;
      margin-right: 10px;
      .en-icon {
        margin-top: 2px;
      }
    }
  }
  .en-card {
    margin-top: 10px;
    /deep/ .en-card-normal-icon {
      position: relative;
      overflow: hidden;
      &:before {
        content: " ";
        width: 36px;
        height: 36px;
        display: block;
        background: #fff;
        opacity: 0.2;
        position: absolute;
        bottom: -17px;
        left: 0;
        border-radius: 50%;
      }
    }
  }
}
</style>
