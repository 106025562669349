/*
 * @Author: zhangjiaheng
 * @Date: 2020-09-24 14:34:55
 * @LastEditors: zhangjiaheng
 * @LastEditTime: 2020-09-25 15:29:35
 * @Description: 税务报表看板 接口
 * @FilePath: \user\src\api\tax\chart.js
 */

import BaseService from "../BaseService";

class TaxReport extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  /**
   * 查询户习惯
   * @param params 参数
   * @returns {*}
   */
  queryUserConfig(param) {
    const { http } = this;
    return http.post("queryUserConfig", param);
  }

  /**
   * 保存户习惯
   * @param params 参数
   * @returns {*}
   */
  saveUserConfig(param) {
    const { http } = this;
    return http.post("saveUserConfig", param);
  }

  /**
   * 查询全部纳税主体
   * @param params 参数
   * @returns {*}
   */
  queryTaxMainList(param) {
    const { http } = this;
    return http.post("queryTaxMainList", param);
  }

  /**
   * 查询全部看板
   * @param params 参数
   * @returns {*}
   */
  queryTaxPic(param) {
    const { http } = this;
    return http.post("queryTaxPic", param);
  }

  /**
   * 查询 列表数据
   * @param params 参数
   * @returns {*}
   */
  queryMainTaxSchemeClassify(param) {
    const { http } = this;
    return http.post("queryMainTaxSchemeClassify", param);
  }

  /**
   * 查询 看板数据
   * @param params 参数
   * @returns {*}
   */
  queryTaxPicDetail(param) {
    const { http } = this;
    return http.post("queryTaxPicDetail", param);
  }

  /**
   * 查询 体检数据
   * @param params 参数
   * @returns {*}
   */
  checkScheme(param) {
    const { http } = this;
    return http.post("checkScheme", param);
  }
}

export default TaxReport;
