<!--
 * @Author: pengyu
 * @Date: 2019-10-21 18:03:14
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-02-02 15:49:49
 * @Description: 授权管理-登录日志
 -->
<template>
  <el-dialog title="登录日志" :visible.sync="dialogVisible" width="1000px" :append-to-body="true" :close-on-click-modal="false" :lock-scroll="true" custom-class="log-alert">
    <en-table :data="logTable" border v-en-loading="loading">
      <en-table-column type="index" width="80"> </en-table-column>
      <en-table-column prop="loginTime" label="登录时间"> </en-table-column>
      <en-table-column prop="userName" label="登录人"> </en-table-column>
      <en-table-column prop="deviceType" label="终端类型"> </en-table-column>
    </en-table>
    <span slot="footer" class="dialog-footer">
      <en-pagination></en-pagination>
    </span>
  </el-dialog>
</template>

<script>
import { authorizeService } from "@/api";

export default {
  name: "PopLog",
  components: {},
  props: {
    logData: {
      type: String,
      default: ""
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        const pa = {
          authPassword: this.logData,
          pageSize: 10,
          pageNo: 1
        };
        this.queryAuthLog(pa);
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      logTable: []
    };
  },
  methods: {
    async queryAuthLog(pa) {
      try {
        this.loading = true;
        const rsp = await authorizeService.queryAuthLog(pa);
        this.logTable = rsp.records;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.vxe-table .vxe-sort-wrapper .vxe-sort--asc-btn{
}
/deep/.vxe-sort-wrapper{
}
/deep/.vxe-header--row {
  background-color: #f2f5f8 !important;
}

/deep/.vxe-header--row .is--firstCol {
  background-color: #f2f5f8 !important;
}
/deep/.vxe-header--row .col--fixed {
  background-color: #f2f5f8 !important;
}
/deep/.vxe-table--header-wrapper {
  background-color: #f2f5f8 !important;
}
/deep/.vxe-table .vxe-head-end-wrapper {
  background-color: #f2f5f8 !important;
}
/deep/.vxe-hover-row-right-wrapper {
  width: 50px;
}
/deep/.button {
  background-color: transparent !important;
}
/deep/.en-expand-buttons {
  background-color: transparent !important;
}
/deep/ .pagination .main-box{
  margin-right:0;
}
.log-alert {
  height: 640px;
}
</style>
