/**
 * project: user
 * fileName: index
 * Created by 徐俊 on 2020/09/16
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import TaxReport from "./report";
import TaxChart from "./chart";

export const taxReport = new TaxReport();
export const taxChart = new TaxChart();
