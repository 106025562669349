<!--
 * @Author: zhangjiaheng
 * @Date: 2020-09-25 11:05:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-21 13:52:02
 * @Description: 税务看板
 * @FilePath: \user\src\views\tax\chart\components\chart.vue
-->
<template>
  <div class="chart-box" v-loading="load">
    <div class="chart-title">
      <span class="txt">{{ data.name }}</span>
      <en-icon @click.native="checkScheme" name="xuanzefangan1" size="small" style="color: #3e90fe;margin-left: 10px;"></en-icon>
    </div>
    <div ref="chart" class="chart-cen"></div>
    <viewExamination v-if="flg" :show="flg" :content="content" @toShow="toget"></viewExamination>
  </div>
</template>

<script>
import { taxChart } from "@/api/tax/index";
import viewExamination from "@/views/home/myEn/components/readComm/viewExamination.vue";

export default {
  name: "taxChart",
  components: {
    viewExamination
  },
  props: {
    // 入参
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      load: false,
      flg: false,
      content: {}
    };
  },
  mounted() {
    this.queryTaxPicDetail();
  },
  methods: {
    async queryTaxPicDetail() {
      this.load = true;
      const p = {
        taxId: this.data.taxId,
        picId: this.data.id
      };
      let rspData = [];
      try {
        rspData = (await taxChart.queryTaxPicDetail(p)) || [];
      } catch (error) {
        rspData = [];
      }
      this.load = false;
      const _value = [];
      const _name = [];
      rspData.forEach((element) => {
        _value.push({
          value: Number(element.value).toFixed(2),
          name: element.classifyRefName,
          classifyRefId: element.classifyRefId,
          taxId: element.taxId,
          taxSchemeId: element.schemeId
        });
        _name.push(`${element.classifyRefName}-${element.fieldName}`);
      });
      this.initChart(_value, _name);
    },
    // 初始化图形
    initChart(_value, _name) {
      const dom = this.$refs.chart;
      const option = {
        tooltip: {
          trigger: "item",
          formatter(dd) {
            return `${dd.data.name}：${dd.value}`;
          },
          textStyle: {
            fontSize: 12
          }
        },
        legend: {
          type: "scroll",
          pageIconSize: 10,
          pageIconColor: "#179eda",
          pageIconInactiveColor: "#aeaeae",
          pageTextStyle: {
            color: "#aeaeae"
          },
          icon: "circle",
          itemWidth: 6,
          itemHeight: 6,
          borderRadius: 6,
          bottom: 0,
          itemGap: 10,
          textStyle: { color: "#999" },
          data: _name
        },
        grid: {
          left: "5%",
          right: "5%",
          top: "10%",
          bottom: "10%",
          containLabel: true
        },
        series: [
          {
            name: _name,
            type: "pie",
            label: {
              normal: {
                formatter(dd) {
                  return `${dd.data.name}：${dd.value}`;
                },
                textStyle: {
                  fontSize: 12
                }
              }
            },
            radius: [0, "58%"],
            center: ["50%", "46%"],
            data: _value,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      if (this.data.type === "004") {
        // 饼图
      } else if (this.data.type === "009") {
        // 环形
        option.series[0].radius = ["50%", "70%"];
      } else if (this.data.type === "008") {
        // 玫瑰 环性
        option.series[0].radius = ["30%", "84%"];
        option.series[0].roseType = "radius";
      }
      const myChart = this.$charts.init(dom);
      myChart.setOption(option);
      myChart.on("click", (param) => {
        const p = param.data;
        const urlObj = {
          path: "/taxReport",
          query: {
            classifyRefId: p.classifyRefId,
            taxId: p.taxId,
            taxSchemeId: p.taxSchemeId,
            version: "1"
          }
        };
        this.$router.push(urlObj);
      });
    },
    toget() {
      this.flg = false;
    },
    async checkScheme() {
      const p = {
        schemeId: this.data.schemeId,
        templateId: this.data.checkTemplateId
      };
      let rspData = {};
      try {
        rspData = (await taxChart.checkScheme(p)) || {};
        if (JSON.stringify(rspData) === "{}") {
          // 无体检数据
        } else {
          this.flg = true;
          this.content = rspData;
        }
      } catch (error) {
        //
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.chart-box {
  width: 100%;
  height: 100%;
  padding-top: 25px;
  .chart-title {
    width: 100%;
    height: 14px;
    padding: 0 20px 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .txt {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .en-icon {
      cursor: pointer;
    }
  }
  .chart-cen {
    height: calc(100% - 14px);
    width: 100%;
    padding: 10px;
  }
}
</style>
