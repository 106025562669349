<!--
 * @Author: pengyu
 * @Date: 2021-06-16 17:57:42
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-17 10:24:05
 * @Description: file content
-->
<template>
  <div class="top-nav-wrap">
    <div class="left-log">
      <img src="@/assets/images/enlogo.png" alt="" height="80%" />
      <span class="logoname">法律知识库</span>
    </div>
    <div class="right-operate">
      <en-icon name="sousuolansousuo" size="22" color="#ffffff" title="搜索" @click.native="handleScreen"></en-icon>
      <!-- <el-input :clearable="true" placeholder="请输入" v-model="txt" size="mini" @input="handleInputChange">
        <template slot="append">
          <en-icon name="sousuolansousuo" color="#a9b5c6" @click.native="handleIsSearch"></en-icon>
        </template>
      </el-input> -->
      <!-- <en-icon name="shaixuan" color="#9367eb" title="筛选" @click.native="handleScreen"></en-icon> -->
    </div>
  </div>
</template>

<script>
// import { debounce } from "lodash";

export default {
  name: "TopNav",
  data() {
    return {
      // txt: "",
      isSearch: false
    };
  },
  methods: {
    // handleIsSearch() {
    //   this.isSearch = !this.isSearch;
    //   if (!this.isSearch) {
    //     this.txt = "";
    //     this.$eventHub.$emit("changeIsSearching", false);
    //   }
    // },
    // 搜索事件
    // handleInputChange(value) {
    //   this.emitGlobalSearch(value, this);
    // },
    // emitGlobalSearch: debounce((value, _this) => {
    //   _this.$eventHub.$emit("globalInputSearch", value);
    // }, 250),
    // 全局搜索
    handleScreen() {
      // this.txt = "";
      // this.isSearch = false;
      this.$eventHub.$emit("handleGlobalScreen", true);
    }
  }
};
</script>

<style lang="scss" scoped>
.top-nav-wrap {
  width: 100%;
  height: 60px;
  background: #232c3d;
  display: flex;
  justify-content: space-between;
  .left-log {
    display: flex;
    align-items: center;
    padding: 0 20px;
    .logoname {
      color: #46abdb;
      font-size: 18px;
      font-weight: 600;
      margin-top: 4px;
    }
  }
  .right-operate {
    padding: 0 20px;
    display: flex;
    align-items: center;
    .el-input {
      & /deep/ .el-input-group__append {
        padding: 0 5px;
        background: #fff;
        .en-icon {
          margin: 0 auto;
        }
      }
    }
    .en-icon {
      font-size: 22px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>
