/**
 * project: user
 * fileName: report
 * Created by 徐俊 on 2020/09/16
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

// import { Base64 } from "js-base64";
import BaseService from "../BaseService";

class TaxReport extends BaseService {
  pk = ""

  constructor(http) {
    super("", http);
  }

  // 选中会计期间
  queryTaxReportDeclareTime(params) {
    return this.http.post("queryTaxReportDeclareTime", params);
  }

  // 税务报表 表头
  queryTaxReportList(params) {
    return this.http.post("queryTaxReportList", params);
  }

  // 税务报表 数据
  queryTaxReportControlList(params) {
    return this.http.post("queryTaxReportControlList", params);
  }

  // 申报 declareTaxReport
  declareTaxReport(params) {
    return this.http.post("declareTaxReport", params);
  }

  // 重置
  resetTaxReport(params) {
    return this.http.post("resetTaxReport", params);
  }

  // 导出
  exportTaxUserDataInfo(params) {
    // params.conditions = Base64.encode(params.conditions);
    return this.http.post("exportTaxUserDataInfo", params, { responseType: "arraybuffer" });
  }

  // 修改值
  modifyTaxReportControlValue(params) {
    return this.http.post("modifyTaxReportControlValue", params);
  }
}

export default TaxReport;
