<template>
  <!--头数据-->
  <div class="tax-report">
    <div :class="['main-box', { 'has-right': isAccActive && dateAccList }]" ref="header">
      <div class="top-box">
        <div class="left">
          <div class="title">{{ taxName }}</div>
          <div class="shenbao" v-if="isDeclareStr !== ''" :style="isDeclareStyle">{{ isDeclareStr }}</div>
        </div>
        <div class="right">
          <en-button v-if="isDeclareStr === '未申报' && isDeclareTime" icon="xuanzefangan1" icon-color="green" icon-size="18" type="text" @click="handleShenBaoBtn">申报</en-button>
          <en-button v-if="isDeclareStr === '未申报'" icon="gengxintiaojian" icon-color="blue" icon-size="18" type="text" @click="handleResetBtn">重置</en-button>
          <en-button icon="daochu-danchuang" icon-color="green" icon-size="18" type="text" @click="handleOutputBtn">导出</en-button>
          <en-button icon="dayin-kuaijiecaozuo" icon-color="blue" icon-size="18" type="text" @click="handlePrintBtn">打印</en-button>
        </div>
      </div>
      <!--      分割线-->
      <div class="line"></div>
      <!--      区域-->
      <div class="area-box">
        <en-tabs v-if="areaList && areaList.length > 0" :list="areaList" :value="area ? area.id : undefined" type="white" @change="handleTabChanged"> </en-tabs>
        <div v-else></div>
        <!--        需求不是去掉这个显示了吗? add by xu  -->
        <div class="right">金额单位：元至角分</div>
      </div>
      <div class="table-box">
        <table v-if="loading1" class="table" ref="table" cellspacing="0" cellpadding="0" border="0">
          <tbody>
            <tr v-for="(item,index) in area.rowList" :key="index">
              <td :ref="'hang'+index+'lie'+index1" :colSpan="(item1.colSpan||1)" :rowSpan="(item1.rowSpan||1)" v-for="(item1,index1) in item" :key="index1">
                <report-content @getStyle="getStyle(index,index1,$event)" :isDeclare="isDeclare" :data="item1" @change="handleChangeText"></report-content>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
    <!-- 时间控件-->
    <dateInterval
      v-if="flgloading"
      v-show="dateAccList"
      class="date-interval"
      :data="conditions[0].value"
      :timeformat="conditions[0].cycle"
      :declare="declareTime"
      @collapseBtn="handleShowDateInterval"
      @handleAccChange="handleAccChange"
    ></dateInterval>
    <!-- 打印 -->
    <print v-if="area" :name="area.name" :printConfig="printConfig"  :rowList="area.rowList" :taxName="taxName" ref="print"></print>
  </div>
</template>

<script>
import data from "./data/data";
import ReportContent from "./views/reportContent"; // 内容
import dateInterval from "./dateInterval"; // 会计期间控件
import print from "./print"; // 打印页面

export default {
  name: "TaxReport",
  components: { ReportContent, dateInterval, print },
  data() {
    return {
      loading: false,
      dateAccList: undefined, // 会计期间对象列表
      dateAccSelect: undefined, // 选中的
      isAccActive: true, // 会计期间展开
      // 入参部分
      classifyRefId: undefined, // 税种ID
      taxClassifyRefId: "", // 报表id
      taxId: undefined,
      taxName: "",
      taxSchemeId: undefined,
      version: undefined,
      // 出参部分
      area: undefined, // 选中区域
      declareTime: [], // 已申报的数据(数组)
      conditions: undefined, // 条件(数组)
      areaList: undefined, // 分组区域(数组)
      dataSource: {}, // key为areaId, value为数组
      totalsCols: 0, // 总列数
      totalsColsList: [], // 总列数 用来渲染
      flgloading: false, // 页面进来的时候 为 false 如果请求完成 前面2个接口后 变为true
      loading1: false,
      printConfig: {},
      isDeclare: 999999 // 当前报表申报状态 未查询出来的时候不显示 默认写个999999
    };
  },
  mixins: [data],
  created() {
    const {
      classifyRefId, taxId, taxName, taxSchemeId, version, taxClassifyRefId
    } = this.$route.query;

    this.classifyRefId = classifyRefId;
    this.taxId = taxId;
    this.taxName = taxName;
    this.taxSchemeId = taxSchemeId;
    this.version = version;
    this.taxClassifyRefId = taxClassifyRefId;

    // 前置页面没有 (默认写死参数  15512345678 税务报表)
    // this.classifyRefId = "SZ0001";
    // this.taxId = "287922213364957184";
    // this.taxName = "test";
    // this.taxSchemeId = "316836083543379968";
    // this.version = 1;
  },
  mounted() {
    this.requestData();
  },
  computed: {
    // 是否申报
    isDeclareStr() {
      let str = "";
      if (Number(this.isDeclare) === 1) {
        // 未申报过
        str = "未申报";
      } else if (Number(this.isDeclare) === 0) {
        // 已申报
        str = "已申报";
      } else if (Number(this.isDeclare) === 2) {
        // 申报中
        str = "申报中";
      } else {
        str = "";
      }
      return str;
    },
    // 是否申报的背景颜色
    isDeclareStyle() {
      let str = "";
      if (Number(this.isDeclare) === 1) {
        // 未申报过
        str = "background: #53d1c3;";
      } else if (Number(this.isDeclare) === 0) {
        // 已申报
        str = "background: #49bef2;";
      } else if (Number(this.isDeclare) === 2) {
        // 申报中
        str = "background: #49bef2;";
      } else {
        str = "";
      }
      return str;
    },
    // 是否显示申报按钮 上一个 月份 季度 年份 未申报不能进行申报
    // 年份 一直可以 每年 第一可以申报
    isDeclareTime() {
      if (this.conditions) {
        const time = this.conditions[0].value;
        const cycle = this.conditions[0].cycle;
        let per = "";
        if (String(cycle) === "3") {
          // 上一个月
          const y = Number(time.slice(0, 4));
          let m = Number(time.slice(5, 7));
          if (m === 1) {
            return true;
          }
          m -= 1;
          per = `${y}-${m < 10 ? `0${m}` : m}`;
        } else if (String(cycle) === "4") {
          // 上一个季度
          const y = Number(time.slice(0, 4));
          let m = Number(time.slice(4, 5));
          if (m === 1) {
            return true;
          }
          m -= 1;
          per = `${y}${m}`;
        } else {
          // 上一年
          return true;
        }
        if (this.declareTime.length) {
          if (this.declareTime.indexOf(per) > -1) {
            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    }
    // width() {
    //   debugger;
    //   return `${this.$refs.header.clientWidth / this.totalsColsList.length}px`;
    // }
  },
  methods: {
    // 合并单元格
    spanMethod({ row, columnIndex }) {
      const item = this.rowData(row, columnIndex);
      return {
        rowspan: item.rowSpan || 0,
        colspan: item.colSpan || 0
      };
    },
    // 行数据
    rowData(row, columnIndex) {
      return row[columnIndex] || {};
    },
    // 单元格样式
    cellClassName({ row, columnIndex }) {
      const item = this.rowData(row, columnIndex);
      if (item.isCanEdit === 1) {
        return "noEdit";
      }
      if (item.isEdited === 1) {
        return "hasEdit";
      }
      return "";
    },
    // 从子组件获取背景颜色赋值
    getStyle(index, index1, str) {
      try {
        this.$refs[`hang${index}lie${index1}`][0].style.background = str;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
$space: 20px;
.tax-report {
  height: 100%;
  .main-box {
    background-color: white;
    height: 100%;
  }

  .has-right {
    border-radius: 5px;
    width: calc(100% - 80px);
  }

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 $space;

    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 14px;
        font-weight: bold;
      }
      .shenbao {
        font-size: 12px;
        color: white;
        background-color: #53d1c3;
        border-radius: 10px;
        height: 20px;
        line-height: 20px;
        width: 60px;
        margin-left: 5px;
        text-align: center;
      }
    }
  }

  .line {
    height: 1px;
    background-color: #eeeeee;
  }

  .area-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    .right {
      font-size: 12px;
      color: #606060;
      min-width: 120px;
      text-align: right;
      margin-right: $space;
    }
  }
  .table-box{
    width: 100%;
    overflow: auto;
    height: calc(100% - 101px);
    padding: 10px;
  }
  .table {
    width: 100%;
    color: #606060;
    font-size: 12px;
    font-family: Microsoft YaHei,Arial,sans-serif;
    td{
      height: 40px;
      padding: 0;
      min-width: 140px;
      line-height: 20px;
      border: 1px solid #f3f3f3;
      word-break: break-all;
    }
  }
  .date-interval {
    position: absolute;
    right: 4px;
    top: 0;
  }
}
</style>
