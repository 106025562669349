var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.loadingData,
          expression: "loadingData",
        },
      ],
      staticClass: "preference",
    },
    [
      _c(
        "en-tabs",
        {
          attrs: { list: _vm.tabList, type: "white" },
          on: { change: _vm.changeTabs },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "en-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "first",
                      expression: "activeTab === 'first'",
                    },
                  ],
                  attrs: { icon: "tianjia-anniutoubu", type: "primary" },
                  on: { click: _vm.addNotice },
                },
                [_vm._v(" 添加 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.activeTab === "first"
        ? _c(
            "div",
            { staticClass: "panel panel1" },
            [
              _vm.taskList.length > 0
                ? _c("remindSection", {
                    attrs: { list: _vm.taskList, "task-type": 0 },
                    on: {
                      deleteNotice: _vm.deleteNotice,
                      editNotice: _vm.editNotice,
                    },
                  })
                : _vm._e(),
              _vm.travelList.length > 0
                ? _c("remindSection", {
                    attrs: { list: _vm.travelList, "task-type": 1 },
                    on: {
                      deleteNotice: _vm.deleteNotice,
                      editNotice: _vm.editNotice,
                    },
                  })
                : _vm._e(),
              _vm.reimbursementList.length > 0
                ? _c("remindSection", {
                    attrs: { list: _vm.reimbursementList, "task-type": 2 },
                    on: {
                      deleteNotice: _vm.deleteNotice,
                      editNotice: _vm.editNotice,
                    },
                  })
                : _vm._e(),
              _vm.attList.length > 0
                ? _c("remindSection", {
                    attrs: { list: _vm.attList, "task-type": 3 },
                    on: {
                      deleteNotice: _vm.deleteNotice,
                      editNotice: _vm.editNotice,
                    },
                  })
                : _vm._e(),
              _vm.mdTaskList.length > 0
                ? _c("remindSection", {
                    attrs: { list: _vm.mdTaskList, "task-type": 4 },
                    on: {
                      deleteNotice: _vm.deleteNotice,
                      editNotice: _vm.editNotice,
                    },
                  })
                : _vm._e(),
              _vm.taskList.length == 0 &&
              _vm.travelList.length == 0 &&
              _vm.reimbursementList.length == 0 &&
              _vm.attList.length == 0 &&
              _vm.mdTaskList.length == 0
                ? _c("en-result", { attrs: { type: "NoData" } })
                : _vm._e(),
            ],
            1
          )
        : _vm.activeTab === "second"
        ? _c(
            "div",
            { staticClass: "panel panel2", attrs: { name: "second" } },
            [
              _vm.ifMainBatchOpera
                ? _c(
                    "div",
                    { staticClass: "wrap" },
                    [
                      _c("div", [_vm._v("首页批量处理按钮")]),
                      _c("el-switch", {
                        attrs: { "active-color": "#4594e1" },
                        on: { change: _vm.homeSwitchChange },
                        model: {
                          value: _vm.homeSwitch,
                          callback: function ($$v) {
                            _vm.homeSwitch = $$v
                          },
                          expression: "homeSwitch",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ifSmsSet
                ? _c(
                    "div",
                    { staticClass: "wrap" },
                    [
                      _c("div", [_vm._v("接收审批通知短信")]),
                      _c("el-switch", {
                        attrs: { "active-color": "#4594e1" },
                        on: {
                          change: function ($event) {
                            return _vm.msgSwitchChange("接收审批通知短信设置")
                          },
                        },
                        model: {
                          value: _vm.msgSwitch,
                          callback: function ($$v) {
                            _vm.msgSwitch = $$v
                          },
                          expression: "msgSwitch",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ifShowBusBtn
                ? _c(
                    "div",
                    { staticClass: "wrap" },
                    [
                      _c("div", [_vm._v("固定显示业务建模列表按钮")]),
                      _c("el-switch", {
                        attrs: { "active-color": "#4594e1" },
                        on: { change: _vm.btnSwitchhChange },
                        model: {
                          value: _vm.btnSwitch,
                          callback: function ($$v) {
                            _vm.btnSwitch = $$v
                          },
                          expression: "btnSwitch",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ifSmsSet
                ? _c(
                    "div",
                    { staticClass: "wrap" },
                    [
                      _c("div", [_vm._v("其它业务通知短信")]),
                      _c("el-switch", {
                        attrs: { "active-color": "#4594e1" },
                        on: {
                          change: function ($event) {
                            return _vm.msgSwitchChange(
                              "接收其它业务通知短信设置"
                            )
                          },
                        },
                        model: {
                          value: _vm.ifNotifyModel,
                          callback: function ($$v) {
                            _vm.ifNotifyModel = $$v
                          },
                          expression: "ifNotifyModel",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("addNotice", {
        ref: "noticeForm",
        attrs: {
          "is-edit": _vm.isEditNotice,
          show: _vm.showNotice,
          "notice-data": _vm.noticeData,
        },
        on: { toShow: _vm.toShow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }