<template>
  <div class="oranization-main">
    <div class="equal-side">
      <en-title-card :need-back="needBack" :name="defaultName" @back="backUrl"></en-title-card>
      <div class="equal-sidecentbar">
        <div class="row-title">
          {{ defaultName }}
        </div>
        <div class="row-main">
          <en-tree current-node-key :data="treeData" :show-checkbox="false" :default-expand-all="true" node-key="label" highlight-current :props="defaultProps" @node-click="nodeClick"> </en-tree>
        </div>
      </div>
    </div>
    <div class="equal-cent">
      <en-title-card :name="checkData.name || defaultName">
        <div slot="left" class="count-num" v-if="checkData.count">({{ checkData.count }}人)</div>
      </en-title-card>
      <div class="table-main">
        <en-table :data="tableData" :height-config="{ bottomHeight: 15 }" :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }">
          <en-table-column type="index" width="60"></en-table-column>
          <en-table-column title="头像" field="userLogo" width="110px">
            <!--自定义单元格-->
            <template v-slot="{ row }">
              <i class="user-logotxt" v-if="row.userLogo === ''">{{ row.name }}</i>
              <img :src="row.userLogo" v-else class="user-logo" />
            </template>
          </en-table-column>
          <en-table-column title="姓名" field="name"> </en-table-column>
          <en-table-column title="工号" field="no"> </en-table-column>
          <en-table-column title="岗位" field="postName"> </en-table-column>
          <en-table-column title="是否兼岗" field=""> </en-table-column>
          <en-table-column title="手机号码" field="mobileNo"> </en-table-column>
        </en-table>
      </div>
    </div>
  </div>
</template>

<script>
import { settingService } from "@/api";

export default {
  data() {
    return {
      needBack: true,
      treeData: [],
      // 默认名称
      defaultName: "",
      defaultProps: {
        children: "nodes",
        label: "name",
        disabled: true
      },
      tableData: [],
      pageModel: {
        // 分页
        pageSize: 10,
        pageNo: 1,
        total: null,
        totalPages: null
      },
      // 选中数据
      checkData: { name: "", count: 0 }
    };
  },
  mounted() {
    this.getOrganization();
  },
  methods: {
    // 获取组织机构树
    async getOrganization() {
      try {
        this.loading = true;
        const res = await settingService.queryDeptAndOrgInfo({});
        this.treeData = res;
        this.defaultName = res[0].name;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 获取人员信息
    async getPersonInfo(data, pageNo = this.pageModel.pageNo, pageSize = this.pageModel.pageSize) {
      try {
        this.loading = true;
        const res = await settingService.queryPersonInfoByParams({
          pageNo,
          pageSize,
          deptId: data.id,
          objectType: "002",
          objectTypeId: ""
        });
        if (typeof res !== "undefined" && typeof res.records !== "undefined") {
          this.tableData = res.records;
        } else {
          this.tableData = "";
        }

        console.log("查询", res);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     *@description 左侧树级菜单单个点击事件
     * @param
     * @return:
     */
    nodeClick(data) {
      this.getPersonInfo(data);
      console.log("onCheck", data);
      this.checkData = data;
    },
    /**
     *@description 返回上级页面
     * @param
     * @return:
     */
    backUrl() {
      this.$router.go(-1);
    },
    /**
     *@description 翻页事件
     * @param {pageNo,pageSize}
     * @return:
     */
    handlePageChanged({ pageNo, pageSize }) {
      // 调用查询列表数据方法
      this.queryFolderList(pageNo, pageSize);
    }
  }
};
</script>

<style lang="scss" scoped>
#app .full-page {
  width: 100%;
  margin: 0;
  background: #1d2532;
}
.oranization-main {
  /deep/ .vxe-table.t--border .vxe-table--header-wrapper {
    border-top: 0;
  }
  .equal-side {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 320px;
    background: #fff;
    border-radius: 5px;
  }
  .equal-cent {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: calc(100% - 330px);
    margin-left: 10px;
  }
  /deep/ .en-title-card .en-title-card-back {
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    width: 20px;
  }
  .equal-sidecentbar {
    border: 1px solid #e8ecf2;
    border-radius: 5px;
    margin: 20px;
    height: calc(100% - 90px);
    .row-title {
      height: 36px;
      line-height: 36px;
      text-align: left;
      padding: 0 20px;
      background: #3e90fe;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
    }
    .row-main {
      height: calc(100% - 40px);
      overflow-y: hidden;
      &:hover{
        overflow-y: auto;
      }
    }
  }
  .user-logo {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-top: 5px;
  }
  .user-logotxt {
    display: block;
    text-align: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-top: 5px;
    background: #fea700;
    overflow: hidden;
    color: #fff;
    font-style: normal;
  }
  .count-num {
    color: #91a1b7;
    font-size: 14px;
  }
  .count-num {
    margin-left: -2px;
  }
}
</style>
