var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tddd is-edit reset-input",
      class: _vm.classStr,
      style: _vm.stylefun,
      on: { click: _vm.handleFocus },
    },
    [
      _c("div", { staticClass: "a", style: _vm.styleAfun }),
      _c("span", [_vm._v(" " + _vm._s(_vm.valuecurrency) + " ")]),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.flg,
              expression: "!flg",
            },
          ],
        },
        [_vm._v(" " + _vm._s(_vm.value) + " ")]
      ),
      _c("el-input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.flg,
            expression: "flg",
          },
        ],
        ref: "dom",
        on: {
          change: _vm.change,
          focus: _vm.handleFocus,
          blur: _vm.handleBlur,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }