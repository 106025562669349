<!--
 * @Author: zhouql
 * @Date: 2020-07-14 15:32:40
 * @LastEditTime: 2021-08-19 09:48:14
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\setting\organization\index.vue
-->
<template>
  <div class="organization-main">
      <en-title-card>
        <div slot="left">
          <span>组织架构图</span>
        </div>
        <div slot="right">
          <en-icon class="close-btn" name="guanbi-danchuang"  size="small" color="#a9b5c6" @click.native="close"></en-icon>
        </div>
      </en-title-card>
      <div class="org-view" v-en-loading="enLoading">
        <vue2-org-tree :data="data" :horizontal="horizontal"
          :collapsable="collapsable"
          :label-class-name="labelClassName"
          :render-content="renderContent"
          :txt="data.txt"
          @on-expand="onExpand"
          @on-node-click="onNodeClick"/>
      </div>
  </div>
</template>

<script>
import Vue from "vue";
import Vue2OrgTree from "vue2-org-tree";
import { request } from "en-js";
import { settingService } from "@/api";

Vue.use(Vue2OrgTree);
export default {
  data() {
    return {
      data: {},
      horizontal: false,
      collapsable: true,
      expandAll: true,
      enLoading: null,
      labelClassName: "bg-white"
    };
  },
  mounted() {
    this.getOrganization();
  },
  methods: {

     @request(true, "enLoading", {
       subTitle: "数据加载中"
     })
    // 获取组织机构数据
    async getOrganization() {
      try {
        this.loading = true;
        const res = await settingService.queryDeptAndOrgInfo({
        });
        this.data = res[0];
        let paramsJson = JSON.stringify(this.data);
        paramsJson = paramsJson.replace(/name/g, "label");
        paramsJson = paramsJson.replace(/nodes/g, "children");
        this.data = JSON.parse(paramsJson);
        this.toggleExpand(this.data, true);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    /* 组件的一些方法 */
    renderContent(h, data) {
      return data.label;
    },
    collapse(list) {
      const _this = this;
      list.forEach((child) => {
        if (child.expand) {
          child.expand = false;
        }
        child.children && _this.collapse(child.children);
      });
    },
    onExpand(e, data) {
      if ("expand" in data) {
        data.expand = !data.expand;
        if (!data.expand && data.children) {
          this.collapse(data.children);
        }
      } else {
        this.$set(data, "expand", true);
      }
    },
    onNodeClick(e, data) {
      console.log(data.label);
      this.$router.push({ path: "/setting/organization/organizationDetail" });
    },
    expandChange() {
      this.toggleExpand(this.data, this.expandAll);
    },
    toggleExpand(data, val) {
      const _this = this;
      if (Array.isArray(data)) {
        data.forEach((item) => {
          _this.$set(item, "expand", val);
          if (item.children) {
            _this.toggleExpand(item.children, val);
          }
        });
      } else {
        this.$set(data, "expand", val);
        if (data.children) {
          _this.toggleExpand(data.children, val);
        }
      }
    },
    /** --------------------------------------- 组织机构方法分界线 ---------------------------------------*/
    close() {
      this.$router.go(-1);
    }

  }
};
</script>
<style>
@import "../../../styles/orgstyle.css";
</style>
<style lang="scss" scoped>
#app {
  .full-page{
    width: 100%;
    margin: 0;
  }
}
.organization-main{
  /deep/ .en-title-card .en-title-card-left-component{
    margin-left: 0;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  /deep/ .en-title-card .en-title-card-right-container .close-btn{
    margin-top: 6px;
  }
  /deep/ .org-tree-node-label .org-tree-node-label-inner{
    cursor: pointer;
    min-width:110px !important;
    height: 60px;
    line-height: 60px;
    padding: 0 15px;
    font-size: 12px;
    color: #333;
    border:1px solid #e8ecf2;
    box-shadow:none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /deep/ .org-tree-node:not(:first-child):before, .org-tree-node:not(:last-child):after{
    border-top:1px solid #e8ecf2;
  }
  /deep/ .org-tree>.org-tree-node:after{
    border-left: 0;
  }
  /deep/ .org-tree-node:after{
    border-left:1px solid #e8ecf2;

  }

  /deep/ .org-tree-node:not(:first-child):before,
  /deep/ .org-tree-node:not(:last-child):after{
    border-top:1px solid #e8ecf2;
  }
  /deep/ .org-tree-node-btn{
    background-color: #3e90fe;
    border: none;
  }
  /deep/ .org-tree-node-btn:after {
    border-left: 1px solid #fff;
  }

  /deep/ .org-tree-node-btn.expanded{
    background-color:#a9b5c6;
    &::after{
      border:none
    }
  }
  /deep/ .org-tree-node-btn:before{
    border-top: 1px solid #fff;
  }
  .org-view{
    height: calc(100% - 50px);
    width: 100%;
    overflow: auto;
  }
}

.org-tree-node-label {
  white-space: nowrap;
}
</style>
