var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "remind" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm._f("getTypeName")(_vm.taskType)))]),
    ]),
    _c(
      "div",
      { staticClass: "cents" },
      _vm._l(_vm.list, function (i, index) {
        return _c("en-card", {
          key: index,
          staticClass: "items-child",
          class: { itemdisabled: i.enable === 1 ? true : false },
          attrs: {
            "icon-name": _vm.typeObj[_vm.taskType].icon,
            "edit-able": true,
            title: _vm.getLeftContent(i),
            "sub-title": _vm.getMiddleContent(i),
            "icon-color": "#fff",
            "icon-bg": _vm.typeObj[_vm.taskType].color,
            "menu-list": _vm.menuList(i),
            disabled: i.enable === 1 ? true : false,
            dataInfo: i,
          },
          on: { change: _vm.changeItemData },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }