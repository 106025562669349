<!--
 * @Author: pengyu
 * @Date: 2019-10-09 14:59:10
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-29 19:25:36
 * @Description: 银行卡卡片
 -->
 <template>
  <div class="card-item">
    <div class="logo">
      <en-icon :name="bankIcon" size="40px"></en-icon>
    </div>
    <div class="info">
      <p class="name">
        {{ bankAddr }}
      </p>
      <p class="no">
        {{ hideCardNo(bankAccountNo) }}
      </p>
    </div>
    <el-dropdown class="edit-btn">
      <en-icon name="bianji1" size="small" color="#4694DE"></en-icon>
      <el-dropdown-menu slot="dropdown" :append-to-body="false">
        <el-dropdown-item @click.native.stop="editCard(index)">
          编辑
        </el-dropdown-item>
        <el-dropdown-item v-if="bankDefault === '001'" @click.native.stop="deleteCard(bankId)">
          删除
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="default-tip" v-if="bankDefault === '000'">
      <span>默</span>
    </div>
  </div>
</template>

<script>
import { hideCardNo } from "en-js";

export default {
  name: "AccountCard",
  components: {},
  props: {
    // 数据索引
    index: {
      type: Number,
      default: 0
    },
    bankId: {
      type: String,
      default: ""
    },
    bankIcon: {
      type: String,
      default: ""
    },
    bankDefault: {
      type: String,
      default: ""
    },
    // 开户行
    bankAddr: {
      type: String,
      default: ""
    },
    // 账号
    bankAccountNo: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {
    /**
     * @description: 隐藏银行卡号
     * @param {type}
     */
    hideCardNo(value) {
      return hideCardNo(value, 5);
    },
    /**
     * @description: 编辑银行卡
     * @param {index} 索引
     * @return:
     */
    editCard() {
      this.$emit("editCard", this.index);
    },
    /**
     * @description: 删除银行卡
     * @param {index} 索引
     * @return:
     */
    deleteCard(id) {
      this.$emit("deleteCard", id);
    }
  }
};
</script>
<style lang="scss" scoped>
.card-item {
  width: 460px;
  height: 96px;
  border: 1px solid #e8ebf2;
  border-radius: 4px;
  margin: 0 20px 20px 0;
  padding: 20px;
  display: flex;
  font-size: 12px;
  position: relative;
  &:hover {
    background-color: #f5f8fd;
    .edit-btn {
      visibility: visible;
    }
  }
  .logo {
    width: 42px;
    overflow: hidden;
    position: relative;
    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
    .bgs{
      position: absolute;
    }
  }
  .info {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    p {
      width: 100%;
      margin: 0;
      text-align: left;
      padding-left: 20px;
    }
  }
  .edit-btn {
    visibility: hidden;
    height: 14px;
  }
  .default-tip {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 40px solid #f78429;
    border-right: 40px solid transparent;
    > span {
      color: #fff;
      position: absolute;
      top: -35px;
      left: 5px;
    }
  }
}
/deep/ .el-dropdown-menu__item:hover .edit-btn {
  visibility: visible;
}
</style>
